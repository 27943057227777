/* eslint-disable no-console */

export const log = ({ config, title, message }: { config: any; title: string; message?: any }) => {
    if (config.devMode) {
        console.groupCollapsed(title)

        if (Array.isArray(message)) {
            message.forEach((value) => {
                console.log(value)
            })
        } else {
            console.log(message)
        }

        console.trace()
        console.groupEnd()
    }
}

export const logError = ({ config, error }: { config: any; error: any }) => {
    if (config.devMode) {
        console.error(error)
    }
}

export default log
