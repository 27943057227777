/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import StepperButtonContainer from '../../ui/StepperButtonContainer'
import Button from '../../ui/Button'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { StylesContext } from '../../contexts/Styles'
import { QueryParamsContext } from '../../contexts/QueryParams'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import getConfigData from '../../helpers/config/getConfigData'

function Unsubscribe({ onNext, onPrev }: any) {
    // Contexts
    const [dataFunnel, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    // States
    const [image, setImage]: any = useState({})

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'FC_UnsubscribePage.png',
        }).then((data: { [key: string]: any }) => {
            setImage(data)
        })
    }, [])

    useEffect(() => {
        if (
            copy?.steps &&
            dataFunnel?.contexts?.steps?.currentStep &&
            dataFunnel?.contexts?.steps?.main &&
            dataFunnelDispatch
        ) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = dataFunnel?.contexts?.steps?.main[dataFunnel?.contexts?.steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [copy?.steps, dataFunnel?.contexts?.steps?.currentStep, dataFunnel?.contexts?.steps?.main, dataFunnelDispatch])

    useEffect(() => {
        const asyncWrapper = async () => {
            if (formData?.marketingEmailConsent === false) {
                await onNext()
            } else if (formData?.marketingEmailConsent === true) {
                await onPrev()
            }
        }
        asyncWrapper()
    }, [formData?.marketingEmailConsent])

    return (
        <Page>
            <Container
                className={styles?.twMerge(
                    styles?.steps?.unsubscribe?.Container?.mobile,
                    styles?.steps?.unsubscribe?.Container?.tablet,
                    styles?.steps?.unsubscribe?.Container?.desktop
                )}
            >
                <img
                    className={styles?.twMerge(
                        styles?.steps?.unsubscribe?.img?.mobile,
                        styles?.steps?.unsubscribe?.img?.tablet,
                        styles?.steps?.unsubscribe?.img?.desktop
                    )}
                    src={image.url}
                    alt={image.alt}
                />
                <Heading
                    className={styles?.twMerge(
                        styles?.steps?.unsubscribe?.Heading?.mobile,
                        styles?.steps?.unsubscribe?.Heading?.tablet,
                        styles?.steps?.unsubscribe?.Heading?.desktop
                    )}
                    name="unsubscribeHeader"
                >
                    <StyleCopy>
                        {parseCopy(copy?.steps?.unsubscribe?.title, {
                            config,
                            formData,
                            copy: copy?.steps?.unsubscribe,
                        })}
                    </StyleCopy>
                </Heading>
                <SubHeading
                    className={styles?.twMerge(
                        styles?.steps?.unsubscribe?.SubHeading?.mobile,
                        styles?.steps?.unsubscribe?.SubHeading?.tablet,
                        styles?.steps?.unsubscribe?.SubHeading?.desktop
                    )}
                    name="unsubscribeSubHeader"
                >
                    <StyleCopy>
                        {parseCopy(copy?.steps?.unsubscribe?.subHeader, {
                            config,
                            formData,
                            copy: copy?.steps?.unsubscribe,
                        })}
                    </StyleCopy>
                </SubHeading>
                <StepperButtonContainer
                    className={styles?.twMerge(
                        styles?.steps?.unsubscribe?.StepperButtonContainer?.mobile,
                        styles?.steps?.unsubscribe?.StepperButtonContainer?.tablet,
                        styles?.steps?.unsubscribe?.StepperButtonContainer?.desktop
                    )}
                >
                    <Button
                        className={styles?.twMerge(
                            styles?.steps?.unsubscribe?.Button?.mobile,
                            // styles?.steps?.unsubscribe?.Button?.nextButton,
                            styles?.steps?.unsubscribe?.Button?.tablet,
                            styles?.steps?.unsubscribe?.Button?.desktop
                        )}
                        type="redFill"
                        name="unsubscribeNext"
                        onClick={() => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'marketingEmailConsent', value: false },
                            })
                        }}
                    >
                        {copy?.steps?.unsubscribe?.nextButton}
                    </Button>
                    <Button
                        className={styles?.twMerge(
                            styles?.steps?.unsubscribe?.Button?.mobile,
                            // styles?.steps?.unsubscribe?.Button?.prevButton,
                            styles?.steps?.unsubscribe?.Button?.tablet,
                            styles?.steps?.unsubscribe?.Button?.desktop
                        )}
                        type="ghostWhite"
                        name="unsubscribePrev"
                        onClick={() => {
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: { name: 'marketingEmailConsent', value: true },
                            })
                        }}
                    >
                        {copy?.steps?.unsubscribe?.previousButton}
                    </Button>
                </StepperButtonContainer>
            </Container>
        </Page>
    )
}

export default Unsubscribe
