/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState, useRef } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { StylesContext } from '../../contexts/Styles'
import { QueryParamsContext } from '../../contexts/QueryParams'
import { StepsContext } from '../../contexts/Steps'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import getConfigData from '../../helpers/config/getConfigData'
import makeBackendRequest from '../../helpers/backend'

function SingleProvider() {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const useEffectBlocker = useRef(false)
    // States
    const [image, setImage]: any = useState({})

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'FcFreedaOnComputerWithGreenBackground.png',
        }).then((data: { [key: string]: any }) => {
            setImage(data)
        })
    }, [])

    // TO DO: Uncomment when Katelin is back
    useEffect(() => {
        if (useEffectBlocker.current) return

        makeBackendRequest(
            { stack: 'ses', endpoint: 'sendToSqs' },
            {
                config,
                formData,
                body: {
                    use_case: 'automated_check_app_received',
                    recipients_email: formData.email,
                    cnxId: formData.connexID,
                },
            }
        )
        useEffectBlocker.current = true

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page>
            <Container
                className={styles.twMerge(
                    styles?.steps?.endFallbackBasic?.SingleProvider?.Container?.mobile,
                    styles?.steps?.endFallbackBasic?.SingleProvider?.Container?.tablet,
                    styles?.steps?.endFallbackBasic?.SingleProvider?.Container?.desktop
                )}
            >
                <img
                    className={styles.twMerge(
                        styles?.steps?.endFallbackBasic?.SingleProvider?.img?.mobile,
                        styles?.steps?.endFallbackBasic?.SingleProvider?.img?.tablet,
                        styles?.steps?.endFallbackBasic?.SingleProvider?.img?.desktop
                    )}
                    src={image.url}
                    alt={image.alt}
                />
                <Heading
                    className={styles.twMerge(
                        styles?.steps?.orderCompleteBasic?.Heading?.mobile,
                        styles?.steps?.orderCompleteBasic?.Heading?.tablet,
                        styles?.steps?.orderCompleteBasic?.Heading?.desktop
                    )}
                    name="endFallbackBasicHeader"
                >
                    <StyleCopy>
                        {parseCopy(copy?.steps?.endFallbackBasic?.SingleProvider?.title, { config, formData })}
                    </StyleCopy>
                </Heading>
                <SubHeading
                    className={styles.twMerge(
                        styles?.steps?.orderCompleteBasic?.SubHeading?.mobile,
                        styles?.steps?.orderCompleteBasic?.SubHeading?.tablet,
                        styles?.steps?.orderCompleteBasic?.SubHeading?.desktop
                    )}
                    name="endFallbackBasicSubHeader"
                >
                    <StyleCopy>
                        {parseCopy(copy?.steps?.endFallbackBasic?.SingleProvider?.subHeader, {
                            config,
                            formData,
                        })}
                    </StyleCopy>
                </SubHeading>
            </Container>
        </Page>
    )
}

export default SingleProvider
