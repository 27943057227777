// Backend
import utilities from './utilities'
import fcServiceabilityCheck from './fcServiceabilityCheck'
import ibexServiceabilityCheck from './ibexServiceabilityCheck'
import smartyValidation from './smartyValidation'
import aceUserTables from './aceUserTables'
import connexOne from './connexOne'
import ses from './ses'
import metaConversion from './metaConversion'
import orderSubmission from './orderSubmitLogging'
import manychat from './manychat'
import pinpoint from './pinpoint'
import ZipIncomeChecker from './zipIncomeChecker'
import checkZipMain from './checkZip'

const stacks: { [key: string]: Function } = {
    utilities,
    fcServiceabilityCheck,
    smartyValidation,
    aceUserTables,
    connexOne,
    metaConversion,
    orderSubmission,
    manychat,
    ibexServiceabilityCheck,
    ses,
    pinpoint,
    ZipIncomeChecker,
    checkZipMain,
}

const makeBackendRequest = async (
    type: { stack: string; endpoint: string },
    request: {
        config: any
        formData: any
        body?: any
        queryParams?: any
        formDataDispatch?: any
        additionalCallStack?: any
        makeBackendRequest?: any
        cnxFifoQueue?: any
        ZipIncomeChecker?: any
    }
) => {
    return stacks[type.stack](type.endpoint, request)
}

export default makeBackendRequest
