// Packages
import React, { useState } from 'react'
// Stores
import StepsStore from '../../contexts/Steps'
import ValidationStore from '../../contexts/Validation'
import FormDataStore from '../../contexts/FormData'
import LoadingBlockersStore from '../../contexts/LoadingBlockers'
import CopyStore from '../../contexts/Copy'
import StyleStore from '../../contexts/Styles'
import PopupStore from '../../contexts/Popup'
// Components
import SetUpSteps from '../../components/SetUpSteps'
import SetUpConfig from '../../components/SetUpConfig'
import Stepper from '../../components/Stepper'
import DisplayLoadingBlocker from '../../components/DisplayLoadingBlocker'
import DisplayLoadingPageBlocker from '../../components/DisplayLoadingPageBlocker'
import SetUpCopy from '../../components/SetUpCopy'
import SetUpStyles from '../../components/SetUpStyles'
import SetUpFormData from '../../components/SetUpFormData'
import DbTracking from '../../components/DbTracking'
import CnxFifoQueuePoll from '../../components/CnxFifoQueuePoll'
import ComplianceTracking from '../../components/ComplianceTracking'
import DisplayPopup from '../../components/DisplayPopup'
import Conversion from '../../components/Conversion'
import ConditionalRender from '../../components/ConditionalRender'
import AddBlockers from '../../components/AddBlockers'
import SetUpDemoFormTestData from '../../components/SetUpDemoFormTestData'
import SetUpPrefill from '../../components/SetUpPrefill'

// UI
import Favicon from '../../ui/Favicon'

function BasicForm({ config }: any) {
    const [started, setStarted] = useState(false)

    return (
        <StepsStore>
            <ValidationStore>
                <FormDataStore>
                    <LoadingBlockersStore>
                        <CopyStore>
                            <StyleStore>
                                <PopupStore>
                                    <AddBlockers setStarted={setStarted} setUpStyles />
                                    <DisplayLoadingBlocker />
                                    <SetUpCopy formName={config} />
                                    <SetUpSteps formName={config} />
                                    <SetUpConfig formName={config} />
                                    <SetUpStyles formName={config} />
                                    <SetUpFormData formName={config} />
                                    <SetUpPrefill />
                                    <Conversion />
                                    <DbTracking />
                                    <CnxFifoQueuePoll />
                                    <ComplianceTracking />
                                    <SetUpDemoFormTestData />
                                    <DisplayLoadingPageBlocker>
                                        <Favicon />
                                        <DisplayPopup />
                                        <ConditionalRender condition={started}>
                                            <Stepper />
                                        </ConditionalRender>
                                    </DisplayLoadingPageBlocker>
                                </PopupStore>
                            </StyleStore>
                        </CopyStore>
                    </LoadingBlockersStore>
                </FormDataStore>
            </ValidationStore>
        </StepsStore>
    )
}

export default BasicForm
