const getUrl = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return 'https://ij2l6hfd82.execute-api.us-east-1.amazonaws.com/prod'
        case 'dev':
            return 'https://ij2l6hfd82.execute-api.us-east-1.amazonaws.com/prod'
        case 'test':
            return 'https://0i3zs7wy0m.execute-api.us-east-1.amazonaws.com/prod'
        default:
            return 'https://9q03dchcfk.execute-api.us-east-1.amazonaws.com/prod'
    }
}

const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        case 'dev':
            return process.env.REACT_APP_DEV_ORDER_SUBMISSION_API_KEY
                ? process.env.REACT_APP_DEV_ORDER_SUBMISSION_API_KEY
                : ''
        case 'test':
            return process.env.REACT_APP_TESTING_ORDER_SUBMISSION_API_KEY
                ? process.env.REACT_APP_TESTING_ORDER_SUBMISSION_API_KEY
                : ''
        default:
            return process.env.REACT_APP_PROD_ORDER_SUBMISSION_API_KEY
                ? process.env.REACT_APP_PROD_ORDER_SUBMISSION_API_KEY
                : ''
    }
}

// Set up environment
const main = async (request: { config: any; formData: any; body?: any }) => {
    if (request?.config?.devMode === true) return {}

    // set up copy objects
    const copyKeyName = Object.keys(request.body)[0]
    const copyObject = request?.body?.[copyKeyName]

    // set up initial start time tracking
    const initialStartTime = request?.body?.initialFormStartTime
    const formEndTime = request?.body?.formSubmitTime

    return fetch(`${getUrl(request.config.environment.orderSubmission)}`, {
        method: 'POST',
        headers: {
            'x-api-key': getApiKey(request.config.environment.orderSubmission),
            Authorization: 'combo',
        },
        body: JSON.stringify({
            email: request?.formData?.email,
            phone: request?.formData?.phoneNumber,
            ace_id: request?.config?.aceId,
            formVersion: request?.config?.tenantConfig?.formVersion,
            visitUrl: window.location.href,
            [copyKeyName]: copyObject,
            initialStartTime,
            formEndTime,
        }),
    })
}

const endpoints: { [key: string]: Function } = {
    main,
}

const orderSubmission = async (endpoint: string, request: { config: any; formData: any }) => {
    return endpoints[endpoint](request)
}

export default orderSubmission
