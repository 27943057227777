// Packages
import React from 'react'
// UI
import Favicon from '../../ui/Favicon'
import Loading from '../../ui/LoadingBlocker'

function BasicForm() {
    return (
        <>
            <Favicon />
            <Loading text="This may take up to 30 secs..." />
        </>
    )
}

export default BasicForm
