/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { QueryParamsContext } from '../../contexts/QueryParams'
import { ConfigContext } from '../../contexts/Config'
// Helpers
import getConfigData from '../../helpers/config/getConfigData'

function SetDeploymentData({ setIsLoaded }: { setIsLoaded: Function }) {
    // Contexts
    const [queryParams]: any = useContext(QueryParamsContext)
    const [config, configDispatch]: any = useContext(ConfigContext)

    useEffect(() => {
        if (config?.loaded?.deployment && config?.loaded?.account) {
            setIsLoaded(true)
        }
    }, [config?.loaded?.account, config?.loaded?.deployment, config?.loaded.tenant])

    useEffect(() => {
        const subDomainRegex = /([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+/
        const domainsMatch = window.location.href.match(subDomainRegex)
        const domains = domainsMatch?.[0].split('.')
        const isLocalhost = window.location.href.match(/localhost:9000/)
        let localhostOffset = 0

        if (!isLocalhost) {
            localhostOffset = 1
        }

        if (domains?.length === 3 + localhostOffset) {
            if (domains?.[1] === 'dev' || domains?.[1] === 'test') {
                configDispatch({ type: 'SET_ACCOUNT', payload: domains[1] })
                getConfigData('versions.json', config.tenant, {
                    isMock: config?.isMock,
                    configId: 'latest',
                    configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : domains[1],
                }).then((data: { [key: string]: any }) => {
                    configDispatch({ type: 'SET_DEPLOYMENT', payload: data.default })
                })
            } else {
                configDispatch({ type: 'SET_ACCOUNT', payload: 'prod' })
                configDispatch({ type: 'SET_DEPLOYMENT', payload: domains?.[0] })
            }
        } else if (domains?.length === 4 + localhostOffset) {
            configDispatch({ type: 'SET_ACCOUNT', payload: domains?.[2] })
            configDispatch({ type: 'SET_DEPLOYMENT', payload: domains?.[0] })
        } else {
            configDispatch({ type: 'SET_ACCOUNT', payload: 'prod' })
            getConfigData('versions.json', config.tenant, {
                isMock: config?.isMock,
                configId: 'latest',
                configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : 'prod',
            }).then((data: { [key: string]: any }) => {
                configDispatch({ type: 'SET_DEPLOYMENT', payload: data.default })
            })
        }
    }, [
        config.account,
        config.deployment,
        config?.isMock,
        config.tenant,
        queryParams.configEnvironment,
        queryParams.configId,
        queryParams.isMock,
    ])

    return null
}

export default SetDeploymentData
