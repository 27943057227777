// Helpers
import initialState from './initialState'

const CnxFifoQueueReducer = (
    state: { cnxFifoQueue: any[] },
    action: { type: String; payload: any },
    // eslint-disable-next-line no-unused-vars
    dataFunnelContext: { state: { [key: string]: any }; dispatch: Function }
) => {
    switch (action.type) {
        case 'SET_DEFAULT':
            dataFunnelContext.dispatch({
                type: 'SET_CNX_FIFO_QUEUE',
                payload: initialState,
            })
            return initialState
        case 'SET_CALL_IN_QUEUE': {
            // make a temp queue
            const CnxFifoQueue = [...state.cnxFifoQueue]
            // check to see if the payload is already in the queue
            if (!CnxFifoQueue.includes(action.payload)) {
                // not in the queue yet, add it
                CnxFifoQueue.push(action.payload)
                dataFunnelContext.dispatch({
                    type: 'SET_CNX_FIFO_QUEUE',
                    payload: CnxFifoQueue,
                })
                return { ...state, cnxFifoQueue: CnxFifoQueue }
            }
            // return the original state
            return { ...state }
        }
        case 'REMOVE_CALL_FROM_QUEUE': {
            const CnxFifoQueue = [...state.cnxFifoQueue]
            CnxFifoQueue.shift()
            return { ...state, cnxFifoQueue: CnxFifoQueue }
        }
        default:
            return state
    }
}

export default CnxFifoQueueReducer
