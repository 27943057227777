/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { createContext, useReducer, useMemo, useContext, useEffect } from 'react'
// Contexts
import ValidationReducer from './reducers'
import initialState from './initialState'
import { DataFunnelContext } from '../DataFunnel'

export const ValidationContext = createContext({})

function Store({ children }: { children: React.ReactNode }) {
    // Contexts
    const [dataFunnel, dataFunnelDispatch]: any = useContext(DataFunnelContext)

    useEffect(() => {
        dataFunnelDispatch({ type: 'INIT_DATA_OBJECT', payload: { name: 'validation', object: initialState } })
    }, [])

    const [state, dispatch] = useReducer(
        (reducerState: any, action: { type: String; payload: any }) =>
            ValidationReducer(reducerState, action, { state: dataFunnel, dispatch: dataFunnelDispatch }),
        initialState
    )

    const stateVariables = useMemo(() => [state, dispatch], [state, dispatch])

    return <ValidationContext.Provider value={stateVariables}>{children}</ValidationContext.Provider>
}

export default Store
