/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import TitleText2 from '../../ui/TitleText2'
import Button from '../../ui/Button'
import OfferCard from '../../ui/OfferCard'
import Footer from '../../ui/Footer'
import Header from '../../ui/Header'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { PopupContext } from '../../contexts/Popup'
import { StylesContext } from '../../contexts/Styles'
import { StepsContext } from '../../contexts/Steps'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'

function AcpTransferable({ onNext, progressBar }: any) {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [, popupDispatch]: any = useContext(PopupContext)
    const [styles]: any = useContext(StylesContext)

    useEffect(() => {
        if (formData?.selectedPlan) {
            onNext()
        }
    }, [formData?.selectedPlan])

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    return (
        <Page>
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="offersBasicHeader">
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.offersBasic?.acpTransferable?.[formData?.topServiceableCompany]?.title,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.offersBasic?.acpTransferable?.[
                                            formData?.topServiceableCompany
                                        ],
                                    }
                                )}
                            </StyleCopy>
                        </Heading>
                        <SubHeading name="offersBasicSubHeader">
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.offersBasic?.acpTransferable?.[formData?.topServiceableCompany]
                                        ?.subHeader,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.acpTransferable?.offersBasic,
                                    }
                                )}
                            </StyleCopy>
                        </SubHeading>
                        <TitleText2
                            name="offersBasicNeedHelper"
                            className={styles.twMerge(
                                styles?.steps?.offersBasic?.needHelp?.mobile,
                                styles?.steps?.offersBasic?.needHelp?.tablet,
                                styles?.steps?.offersBasic?.needHelp?.desktop
                            )}
                        >
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.offersBasic?.acpTransferable?.[formData?.topServiceableCompany]
                                        ?.needHelp,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.acpTransferable?.offersBasic,
                                    }
                                )}
                            </StyleCopy>
                        </TitleText2>
                        {copy?.steps?.offersBasic?.acpTransferable?.[formData?.topServiceableCompany]?.offers?.map(
                            (offer: any) => {
                                return (
                                    // eslint-disable-next-line react/jsx-key
                                    <OfferCard
                                        priceText={offer?.priceText}
                                        timeUnit={offer?.timeUnit}
                                        buttonCopy={offer?.buttonCopy}
                                        shortDescription={offer?.shortDescription}
                                        sections={offer?.sections}
                                        showMoreCopy={offer?.showMoreCopy}
                                        showLessCopy={offer?.showLessCopy}
                                        isAcp={formData.isAcpTransferable}
                                        strikedPriceText={offer?.strikedPrice}
                                        giftCard={offer?.giftCard}
                                        mobileGift={offer?.mobileGift}
                                        imageString={offer?.imageString}
                                        badge={offer?.badge}
                                        onClick={() => {
                                            formDataDispatch({
                                                type: 'SET_FIELD',
                                                payload: { name: 'selectedPlan', value: offer?.value },
                                            })
                                        }}
                                    />
                                )
                            }
                        )}
                        <Button
                            type="link"
                            className={styles.twMerge(
                                styles?.steps?.offersBasic?.disclaimer?.mobile,
                                styles?.steps?.offersBasic?.disclaimer?.tablet,
                                styles?.steps?.offersBasic?.disclaimer?.desktop
                            )}
                            name={`${formData?.topServiceableCompany}OffersBasicDisclaimer`}
                            onClick={() => {
                                popupDispatch({
                                    type: 'ADD_POPUP',
                                    payload: {
                                        name: `${formData?.topServiceableCompany}OffersBasicDisclaimer`,
                                        componentToRender: `${formData?.topServiceableCompany}OffersBasicDisclaimer`,
                                    },
                                })
                            }}
                        >
                            <TitleText2>
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.offersBasic?.acpTransferable?.[formData?.topServiceableCompany]
                                            ?.disclaimerText,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.acpTransferable?.offersBasic,
                                        }
                                    )}
                                </StyleCopy>
                            </TitleText2>
                        </Button>
                    </Main>
                </div>
                <Footer />
            </Container>
        </Page>
    )
}

export default AcpTransferable
