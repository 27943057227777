// Helpers
import getStepActions from './stepActionFunctions/stepActions'
import stepNameMap from './supportData/stepNameMap'
// Types
import { Step, StepFromJson } from './types/steps'

const parseStepNames = (unmappedSteps: StepFromJson[]) => {
    const returnArray: Step[] = []

    unmappedSteps.forEach((step: StepFromJson) => {
        const stepObject = stepNameMap({
            key: step.step,
        })

        const mergedStepObjects: Step = {
            name: step.step,
            ...stepObject,
            ...step,
        }

        if (step.onAction && typeof step.onAction === 'string') {
            mergedStepObjects.onAction = getStepActions({ key: step.onAction })
        }

        returnArray.push(mergedStepObjects)
    })

    return returnArray
}

export default parseStepNames
