/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { DataFunnelContext } from '../contexts/DataFunnel'
// Helpers
import makeBackendRequest from '../helpers/backend'
import { parseCopyObject } from '../helpers/parseCopy'

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        hj: any
    }
}

function ComplianceTracking() {
    // Contexts
    const [dataFunnel]: any = useContext(DataFunnelContext)

    // PII Submitted
    useEffect(() => {
        if (dataFunnel?.journey?.completedSteps?.piiBasic) {
            makeBackendRequest(
                { stack: 'orderSubmission', endpoint: 'main' },
                {
                    formData: dataFunnel?.contexts?.formData,
                    config: dataFunnel?.contexts?.config,
                    queryParams: dataFunnel?.contexts?.queryParams,
                    body: {
                        piiBasicCopy: parseCopyObject(dataFunnel?.journey?.copy?.piiBasic, {
                            config: dataFunnel?.contexts?.config,
                            formData: dataFunnel?.contexts?.formData,
                        }),
                        initialFormStartTime: dataFunnel?.journey?.initialStartTime,
                    },
                }
            )
        }
    }, [dataFunnel?.journey?.completedSteps?.piiBasic])

    // Order Submit Page Submitted
    useEffect(() => {
        if (dataFunnel?.journey?.completedSteps?.submitOrderBasic) {
            makeBackendRequest(
                { stack: 'orderSubmission', endpoint: 'main' },
                {
                    formData: dataFunnel?.contexts?.formData,
                    config: dataFunnel?.contexts?.config,
                    queryParams: dataFunnel?.contexts?.queryParams,
                    body: {
                        submitOrderBasicCopy: parseCopyObject(dataFunnel?.journey?.copy?.submitOrderBasic, {
                            config: dataFunnel?.contexts?.config,
                            formData: dataFunnel?.contexts?.formData,
                        }),
                        formSubmitTime: dataFunnel?.journey?.formEndTime,
                    },
                }
            )
        }
    }, [dataFunnel?.journey?.completedSteps?.submitOrderBasic])

    return null
}

export default ComplianceTracking
