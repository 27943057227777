/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'

// Contexts
import { PopupContext } from '../contexts/Popup'
import { StylesContext } from '../contexts/Styles'

function Popup({ className = '', children }: { [key: string]: any }) {
    // Contexts
    const [, popupDispatch]: any = useContext(PopupContext)
    const [styles]: any = useContext(StylesContext)

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <div
            className={styles.twMerge(
                styles?.ui?.Popup?.screen?.mobile,
                styles?.ui?.Popup?.screen?.tablet,
                styles?.ui?.Popup?.screen?.desktop
            )}
        >
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */}
            <div
                className={styles.twMerge(
                    styles?.ui?.Popup?.blocker?.mobile,
                    styles?.ui?.Popup?.blocker?.tablet,
                    styles?.ui?.Popup?.blocker?.desktop
                )}
                onClick={() => {
                    popupDispatch({ type: 'REMOVE_POPUP' })
                }}
            />
            <div
                className={styles.twMerge(
                    styles?.ui?.Popup?.container?.mobile,
                    styles?.ui?.Popup?.container?.tablet,
                    styles?.ui?.Popup?.container?.desktop,
                    className
                )}
            >
                {children}
            </div>
        </div>
    )
}

export default Popup
