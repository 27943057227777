/* eslint-disable no-unused-vars */
import React, { useContext } from 'react'
import ExistingProviderSelection from './ExistingProviderSelection'

// Context
import { StylesContext } from '../contexts/Styles'

function ExistingProviderContainer({ items }: any) {
    const [styles]: any = useContext(StylesContext)

    return (
        <div className="flex flex-col">
            {items.map((row: any) => (
                <div
                    key={row[0].name}
                    className={styles.twMerge(
                        styles?.steps?.currentCustomerOptions?.existingProviderGrid?.mobile,
                        styles?.steps?.currentCustomerOptions?.existingProviderGrid?.tablet,
                        styles?.steps?.currentCustomerOptions?.existingProviderGrid?.desktop
                    )}
                >
                    <ExistingProviderSelection name={row[0]?.name} imageString={row[0]?.imageString} />
                    <ExistingProviderSelection name={row[1]?.name} imageString={row[1]?.imageString} />
                </div>
            ))}
            <ExistingProviderSelection name="None of the above" />
        </div>
    )
}

export default ExistingProviderContainer
