const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000'
        case 'dev':
            return 'https://5nn8im961g.execute-api.us-east-1.amazonaws.com/prod/sendToSqs'
        case 'test':
            return 'https://5nn8im961g.execute-api.us-east-1.amazonaws.com/prod/sendToSqs'
        default:
            return 'https://5nn8im961g.execute-api.us-east-1.amazonaws.com/prod/sendToSqs'
    }
}

const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        default:
            return process.env.REACT_APP_PROD_SES_KEY ? process.env.REACT_APP_PROD_SES_KEY : ''
    }
}

const sendToSqs = async (request: { config: any; formData: any; body?: any }) => {
    if (request?.config?.devMode === true) return {}

    return fetch(`${getUrl(request.config.environment.ses)}`, {
        method: 'POST',
        body: JSON.stringify(request?.body),
        headers: {
            'x-api-key': getApiKey(request.config.environment.ses),
            Authorization: 'sesEmail',
        },
    })
}

const endpoints: { [key: string]: Function } = {
    sendToSqs,
}

const ses = async (endpoint: string, request: { config: any; formData: any }) => {
    return endpoints[endpoint](request)
}

export default ses
