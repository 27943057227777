/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect } from 'react'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import TextField from '../../ui/TextField'
import Checkbox from '../../ui/Checkbox'
import DatePicker from '../../ui/DatePicker'
import Header from '../../ui/Header'
import Footer from '../../ui/Footer'
import Link from '../../ui/Link'
import BodyText2 from '../../ui/BodyText2'
// Helpers
import {
    requireNonEmptyValue,
    requireOnlyValidNladCharacters,
    requireMoreThanXCharacters,
    requireNotToBeAllSpaces,
    requireValidEmail,
    requireOnlyNumbers,
    requireXCharacters,
    requireCompleteDate,
    requireAgeToBeGreaterThan18,
} from '../../helpers/validation/unitFunctions'
import { parseCopy } from '../../helpers/parseCopy'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { FormDataContext } from '../../contexts/FormData'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { StylesContext } from '../../contexts/Styles'
import { StepsContext } from '../../contexts/Steps'
import ConditionalRender from '../../components/ConditionalRender'

function PiiBasic({ step, buttons, progressBar }: any) {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [styles]: any = useContext(StylesContext)

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    return (
        <Page>
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="piiBasicHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.piiBasic?.title, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </Heading>
                        <ConditionalRender
                            condition={config?.formName === 'offersFirst'}
                            falseReturn={
                                <SubHeading name="piiBasicSubHeader">
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.piiBasic?.subHeader, {
                                            config,
                                            formData,
                                            copy: copy?.steps?.piiBasic,
                                        })}
                                    </StyleCopy>
                                </SubHeading>
                            }
                        >
                            <SubHeading name="piiBasicSubHeader">
                                <StyleCopy>
                                    {formData?.topServiceableCompany === 'frontier' ||
                                    formData?.topServiceableCompany === 'optimum' ||
                                    formData?.topServiceableCompany === 'spectrum'
                                        ? parseCopy(copy?.steps?.piiBasic?.custom?.subHeader, {
                                              config,
                                              formData,
                                              copy: copy?.steps?.piiBasic,
                                          })
                                        : parseCopy(copy?.steps?.piiBasic?.subHeader, {
                                              config,
                                              formData,
                                              copy: copy?.steps?.piiBasic,
                                          })}
                                </StyleCopy>
                            </SubHeading>
                        </ConditionalRender>

                        <TextField
                            step={step}
                            validationFunctions={[
                                (value: string) => {
                                    return requireMoreThanXCharacters(value, 2, 'letters')
                                },
                                requireNotToBeAllSpaces,
                                requireOnlyValidNladCharacters,
                                requireNonEmptyValue,
                            ]}
                            formDataKey="firstName"
                            name="firstName"
                            placeholder={copy?.steps?.piiBasic?.firstName?.placeholder}
                            subLabel={
                                copy?.steps?.piiBasic?.firstName?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.piiBasic?.firstName?.subLabel, {
                                            config,
                                            formData,
                                            copy: copy?.steps?.piiBasic,
                                        })}
                                    </StyleCopy>
                                )
                            }
                            initialValue={formData.firstName}
                            onChange={(value: any) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'firstName', value },
                                })
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.piiBasic?.firstName?.label, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </TextField>
                        <TextField
                            step={step}
                            // validationFunctions={[
                            //     (value: string) => {
                            //         return requireMoreThanXCharacters(value, 2, 'letters')
                            //     },
                            //     requireNotToBeAllSpaces,
                            //     requireOnlyValidNladCharacters,
                            //     requireNonEmptyValue,
                            // ]}
                            formDataKey="middleName"
                            name="middleName"
                            placeholder={copy?.steps?.piiBasic?.middleName?.placeholder}
                            subLabel={
                                copy?.steps?.piiBasic?.middleName?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.piiBasic?.middleName?.subLabel, {
                                            config,
                                            formData,
                                            copy: copy?.steps?.piiBasic,
                                        })}
                                    </StyleCopy>
                                )
                            }
                            initialValue={formData.middleName}
                            onChange={(value: any) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'middleName', value },
                                })
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.piiBasic?.middleName?.label, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </TextField>
                        <TextField
                            step={step}
                            placeholder={copy?.steps?.piiBasic?.lastName?.placeholder}
                            validationFunctions={[
                                (value: string) => {
                                    return requireMoreThanXCharacters(value, 2, 'letters')
                                },
                                requireNotToBeAllSpaces,
                                requireOnlyValidNladCharacters,
                                requireNonEmptyValue,
                            ]}
                            formDataKey="lastName"
                            name="lastName"
                            subLabel={
                                copy?.steps?.piiBasic?.lastName?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.piiBasic?.lastName?.subLabel, {
                                            config,
                                            formData,
                                            copy: copy?.steps?.piiBasic,
                                        })}
                                    </StyleCopy>
                                )
                            }
                            initialValue={formData.lastName}
                            onChange={(value: any) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'lastName', value },
                                })
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.piiBasic?.lastName?.label, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </TextField>
                        <TextField
                            step={step}
                            placeholder={copy?.steps?.piiBasic?.email?.placeholder}
                            validationFunctions={[requireValidEmail, requireNonEmptyValue]}
                            formDataKey="email"
                            name="email"
                            subLabel={
                                copy?.steps?.piiBasic?.email?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.piiBasic?.email?.subLabel, {
                                            config,
                                            formData,
                                            copy: copy?.steps?.piiBasic,
                                        })}
                                    </StyleCopy>
                                )
                            }
                            initialValue={formData.email}
                            onChange={(value: any) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'email', value },
                                })
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.piiBasic?.email?.label, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </TextField>
                        <TextField
                            step={step}
                            type="tel"
                            isNumberType
                            validationFunctions={[
                                (value: string) => requireXCharacters(value, 10),
                                requireOnlyNumbers,
                                requireNonEmptyValue,
                            ]}
                            placeholder={copy?.steps?.piiBasic?.phoneNumber?.placeholder}
                            formDataKey="phoneNumber"
                            name="phoneNumber"
                            phoneNumber
                            subLabel={
                                copy?.steps?.piiBasic?.phoneNumber?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.piiBasic?.phoneNumber?.subLabel, {
                                            config,
                                            formData,
                                            copy: copy?.steps?.piiBasic,
                                        })}
                                    </StyleCopy>
                                )
                            }
                            initialValue={formData.phoneNumber}
                            onChange={(value: any) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'phoneNumber', value },
                                })
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.piiBasic?.phoneNumber?.label, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </TextField>
                        <DatePicker
                            step={step}
                            validationFunctions={[requireAgeToBeGreaterThan18, requireCompleteDate]}
                            formDataKey="birthDate"
                            name="birthDate"
                            disableOnChangeValidation
                            subLabel={
                                copy?.steps?.piiBasic?.birthDay?.subLabel && (
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.piiBasic?.birthDay?.subLabel, {
                                            config,
                                            formData,
                                            copy: copy?.steps?.piiBasic,
                                        })}
                                    </StyleCopy>
                                )
                            }
                            initialValue={formData.birthDate}
                            onChange={(value: string) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'birthDate', value },
                                })
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.piiBasic?.birthDay?.label, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </DatePicker>
                        <Checkbox
                            step={step}
                            validationFunctions={[requireNonEmptyValue]}
                            formDataKey="consentToContact"
                            name="consentToContact"
                            labelClassName={styles.twMerge(
                                styles?.steps?.piiBasic?.consentCheckboxes?.mobile,
                                styles?.steps?.piiBasic?.consentCheckboxes?.tablet,
                                styles?.steps?.piiBasic?.consentCheckboxes?.desktop
                            )}
                            initialValue={formData.consentToContact}
                            onChange={(value: boolean) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'consentToContact', value },
                                })
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'marketingEmailConsent', value },
                                })
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.piiBasic?.contactConsent, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </Checkbox>
                        <Checkbox
                            step={step}
                            validationFunctions={[]}
                            formDataKey="smsToContact"
                            name="smsToContact"
                            labelClassName={styles.twMerge(
                                styles?.steps?.piiBasic?.consentCheckboxes?.mobile,
                                styles?.steps?.piiBasic?.consentCheckboxes?.tablet,
                                styles?.steps?.piiBasic?.consentCheckboxes?.desktop
                            )}
                            initialValue={formData.smsToContact}
                            onChange={(value: boolean) => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'smsToContact', value },
                                })
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.piiBasic?.smsConsent, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </Checkbox>
                        {buttons}
                        <BodyText2
                            className={styles.twMerge(
                                styles?.steps?.piiBasic?.privacyPolicy?.mobile,
                                styles?.steps?.piiBasic?.privacyPolicy?.tablet,
                                styles?.steps?.piiBasic?.privacyPolicy?.desktop
                            )}
                        >
                            <Link href={copy?.steps?.piiBasic?.privacyPolicy?.href}>
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.piiBasic?.privacyPolicy?.copy, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.piiBasic,
                                    })}
                                </StyleCopy>
                            </Link>
                        </BodyText2>
                    </Main>
                </div>
                <Footer />
            </Container>
        </Page>
    )
}

export default PiiBasic
