/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
import { LoadingBlockersContext } from '../contexts/LoadingBlockers'
import { FormDataContext } from '../contexts/FormData'

// Helpers
import makeBackendRequest from '../helpers/backend'

function SetUpFormData() {
    // Contexts
    const [config, configDispatch]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [, loadingBlockersDispatch]: any = useContext(LoadingBlockersContext)

    useEffect(() => {
        const asyncWrapper = async () => {
            loadingBlockersDispatch({ type: 'ADD_BLOCKER', payload: 'setUpPrefill' })

            if (config.manychatCompany) {
                const manychatResponse = await makeBackendRequest(
                    { stack: 'manychat', endpoint: 'get' },
                    { formData, config, queryParams }
                )
                const manychatResponseData = await manychatResponse.json()
                const tempFormData = { ...formData }
                const fieldMapping: any = {
                    mc_firstName: 'firstName',
                    mc_lastName: 'lastName',
                    mc_dob: 'birthDate',
                    mc_ssn: 'ssn',
                    tempAddress: 'temporaryAddress',
                    mc_phone: 'phoneNumber',
                    mc_email: 'email',
                    mc_address: 'addressStreet',
                    mc_addressLn2: 'addressSecondary',
                    mc_city: 'addressCity',
                    mc_middleName: 'middleName',
                    mc_secondLastName: 'secondLastName',
                    mc_shippingAddress: 'shippingAddressStreet',
                    mc_shippingAddressLn2: 'shippingAddressSecondary',
                    mc_shippingCity: 'shippingAddressCity',
                    mc_shippingState: 'shippingAddressState',
                    mc_shippingZipCode: 'shippingAddressZip',
                    mc_state: 'addressState',
                    mc_suffix: 'suffix',
                    bqp_first_name: 'bqpFirstName',
                    bqp_last_name: 'bqpLastName',
                    bqp_dob: 'bqpDob',
                    bqp_ssn: 'bqpSsn',
                    mc_tribal_bool: 'isTribal',
                    mc_zipCode: 'addressZip',
                    enrollment_type: 'enrollment_type',
                    serviceableCompany: 'serviceableCompany',
                    optimum_skip: 'optimum_skip',
                    mc_benefitCode: 'programCode',
                    quoteId: 'quoteId',
                    serviceable_companies: 'serviceableCompanies',
                    enrollment_id: 'enrollmentId',
                    ChatURL: 'chatURL',
                    AceId: 'aceId',
                }

                manychatResponseData?.message?.data?.custom_fields?.forEach((field: any) => {
                    const mappedField = fieldMapping[field.name]
                    if (mappedField) {
                        tempFormData[mappedField] = field.value
                    }
                })
                formDataDispatch({ type: 'MERGE', payload: { ...tempFormData } })
                loadingBlockersDispatch({ type: 'REMOVE_BLOCKER', payload: 'setUpPrefill' })
            } else if (queryParams.uuid) {
                const dbUuidResponse = await makeBackendRequest(
                    { stack: 'aceUserTables', endpoint: 'getUserPK' },
                    {
                        formData,
                        config,
                        queryParams,
                        body: {
                            pk: queryParams.uuid,
                            table: 'short_uuid',
                        },
                    }
                )
                const dbUuidResponseData = await dbUuidResponse.json()
                const aceUsersPk = dbUuidResponseData?.data?.short_uuid?.ace_pk
                const dbPkResponse = await makeBackendRequest(
                    { stack: 'aceUserTables', endpoint: 'getUserPK' },
                    {
                        formData,
                        config,
                        queryParams,
                        body: {
                            pk: aceUsersPk,
                            table: 'main_demo_4',
                        },
                    }
                )
                const dbPkResponseData = await dbPkResponse.json()
                const fieldMapping: any = {
                    firstName: dbPkResponseData?.data?.main_demo_4?.first_name,
                    lastName: dbPkResponseData?.data?.main_demo_4?.last_name,
                    birthDate: dbPkResponseData?.data?.main_demo_4?.birth_date,
                    phoneNumber: dbPkResponseData?.data?.main_demo_4?.phone_number,
                    email: dbPkResponseData?.data?.main_demo_4?.email,
                    addressStreet: dbPkResponseData?.data?.main_demo_4?.address_street,
                    addressSecondary: dbPkResponseData?.data?.main_demo_4?.address_secondary,
                    addressCity: dbPkResponseData?.data?.main_demo_4?.address_city,
                    addressState: dbPkResponseData?.data?.main_demo_4?.address_state,
                    addressZip: dbPkResponseData?.data?.main_demo_4?.address_zip,
                    aceId: aceUsersPk,
                    topServiceableCompany: dbPkResponseData?.data?.main_demo_4?.topServiceableCompany,
                }

                configDispatch({
                    type: 'SET_ACE_ID',
                    payload: aceUsersPk,
                })
                formDataDispatch({ type: 'MERGE', payload: { ...fieldMapping } })
            }
            loadingBlockersDispatch({ type: 'REMOVE_BLOCKER', payload: 'setUpPrefill' })
        }

        asyncWrapper()
    }, [config.environment, queryParams.userId])

    return null
}

export default SetUpFormData
