// Helpers
import initialState from './initialState'

const StepsReducer = (
    state: { [key: string]: any },
    action: { type: String; payload: any },
    // eslint-disable-next-line no-unused-vars
    dataFunnelContext: { state: { [key: string]: any }; dispatch: Function }
) => {
    switch (action.type) {
        case 'SET':
            dataFunnelContext.dispatch({ type: 'SET_STEPS', payload: action.payload })
            return action.payload
        case 'SET_STEPS':
            dataFunnelContext.dispatch({ type: 'SET_STEPS', payload: action.payload })
            dataFunnelContext.dispatch({
                type: 'START_MERGED_TIMER',
                payload: { name: `${action?.payload?.main?.[state?.currentStep]?.name}StepTimer` },
            })
            return { ...state, ...action.payload }
        case 'APPEND_STEP': {
            const newState = {
                main: [...state.main, { name: action.payload.name, component: action.payload.component }],
            }

            dataFunnelContext.dispatch({ type: 'SET_STEPS', payload: newState })

            return newState
        }
        case 'APPEND_STEPS': {
            const newState = {
                ...state,
                main: [...state.main, ...action.payload.steps],
            }

            dataFunnelContext.dispatch({ type: 'SET_STEPS', payload: newState })

            return newState
        }
        case 'INSERT_STEP': {
            const tempMain = [...state.main]
            tempMain.splice(action.payload.index, 0, { name: action.payload.name, component: action.payload.component })
            const newState = { ...state, main: tempMain }

            dataFunnelContext.dispatch({ type: 'SET_STEPS', payload: newState })

            return newState
        }
        case 'INCREMENT_CURRENT_STEP':
            dataFunnelContext.dispatch({
                type: 'END_MERGED_TIMER',
                payload: { name: `${state?.main?.[state?.currentStep]?.name}StepTimer` },
            })
            dataFunnelContext.dispatch({
                type: 'START_MERGED_TIMER',
                payload: {
                    name: `${state?.main?.[state.currentStep + (action.payload ? action.payload : 1)].name}StepTimer`,
                },
            })
            dataFunnelContext.dispatch({
                type: 'SET_STEPS_FIELD',
                payload: { key: 'currentStep', value: state.currentStep + (action.payload ? action.payload : 1) },
            })
            return { ...state, currentStep: state.currentStep + (action.payload ? action.payload : 1) }
        case 'DECREMENT_CURRENT_STEP':
            dataFunnelContext.dispatch({
                type: 'END_MERGED_TIMER',
                payload: { name: `${state?.main?.[state?.currentStep]?.name}StepTimer` },
            })
            dataFunnelContext.dispatch({
                type: 'START_MERGED_TIMER',
                payload: {
                    name: `${state?.main?.[state.currentStep - (action.payload ? action.payload : 1)].name}StepTimer`,
                },
            })
            dataFunnelContext.dispatch({
                type: 'SET_STEPS_FIELD',
                payload: { key: 'currentStep', value: state.currentStep - (action.payload ? action.payload : 1) },
            })
            return { ...state, currentStep: state.currentStep - (action.payload ? action.payload : 1) }
        case 'SET_DEFAULT':
            dataFunnelContext.dispatch({ type: 'SET_STEPS', payload: initialState })
            return initialState
        default:
            return state
    }
}

export default StepsReducer
