/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
// Packages
import React, { useContext, useEffect, useState } from 'react'

// UI
import BodyText2 from './BodyText2'
import HeadingText3 from './HeadingText3'
// Contexts
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
// Helpers
import getConfigData from '../helpers/config/getConfigData'

function DisplayGenericOffer({
    className = '',
    topServiceableCompany,
    titleCopy,
    price,
    timeUnit,
}: {
    [key: string]: any
}) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [config]: any = useContext(ConfigContext)
    // States
    const [logo, setLogo]: any = useState({})

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: `${topServiceableCompany}Logo.png`,
        }).then((data: { [key: string]: any }) => {
            setLogo(data)
        })
    }, [])

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <div
            className={styles.twMerge(
                styles?.ui?.DisplayGenericOffer?.container?.mobile,
                styles?.ui?.DisplayGenericOffer?.container?.tablet,
                styles?.ui?.DisplayGenericOffer?.container?.desktop,
                className
            )}
        >
            <div
                className={styles.twMerge(
                    styles?.ui?.DisplayGenericOffer?.imgContainer?.mobile,
                    styles?.ui?.DisplayGenericOffer?.imgContainer?.tablet,
                    styles?.ui?.DisplayGenericOffer?.imgContainer?.desktop
                )}
            >
                <img
                    className={styles.twMerge(
                        styles?.ui?.DisplayGenericOffer?.img?.mobile,
                        styles?.ui?.DisplayGenericOffer?.img?.tablet,
                        styles?.ui?.DisplayGenericOffer?.img?.desktop
                    )}
                    src={logo.url}
                    alt={logo.alt}
                />
            </div>
            <div
                className={styles.twMerge(
                    styles?.ui?.DisplayGenericOffer?.textContainer?.mobile,
                    styles?.ui?.DisplayGenericOffer?.textContainer?.tablet,
                    styles?.ui?.DisplayGenericOffer?.textContainer?.desktop
                )}
            >
                <BodyText2
                    className={styles.twMerge(
                        styles?.ui?.DisplayGenericOffer?.title?.mobile,
                        styles?.ui?.DisplayGenericOffer?.title?.tablet,
                        styles?.ui?.DisplayGenericOffer?.title?.desktop
                    )}
                >
                    {titleCopy}
                </BodyText2>
                <div
                    className={styles.twMerge(
                        styles?.ui?.DisplayGenericOffer?.priceContainer?.mobile,
                        styles?.ui?.DisplayGenericOffer?.priceContainer?.tablet,
                        styles?.ui?.DisplayGenericOffer?.priceContainer?.desktop
                    )}
                >
                    <HeadingText3
                        className={styles.twMerge(
                            styles?.ui?.DisplayGenericOffer?.price?.mobile,
                            styles?.ui?.DisplayGenericOffer?.price?.tablet,
                            styles?.ui?.DisplayGenericOffer?.price?.desktop
                        )}
                    >
                        {price}
                    </HeadingText3>
                    <div
                        className={styles.twMerge(
                            styles?.ui?.DisplayGenericOffer?.timeUnitContainer?.mobile,
                            styles?.ui?.DisplayGenericOffer?.timeUnitContainer?.tablet,
                            styles?.ui?.DisplayGenericOffer?.timeUnitContainer?.desktop
                        )}
                    >
                        <BodyText2
                            className={styles.twMerge(
                                styles?.ui?.DisplayGenericOffer?.timeUnit?.mobile,
                                styles?.ui?.DisplayGenericOffer?.timeUnit?.tablet,
                                styles?.ui?.DisplayGenericOffer?.timeUnit?.desktop
                            )}
                        >
                            {timeUnit}
                        </BodyText2>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisplayGenericOffer
