/* eslint-disable no-console */
/* eslint-disable no-else-return */
/* eslint-disable no-unused-vars */
import { useEffect, useContext, useState } from 'react'
// Context
import { CnxFifoQueueContext } from '../contexts/CnxFifoQueue'
import { ConfigContext } from '../contexts/Config'
import { FormDataContext } from '../contexts/FormData'

function CnxFifoQueuePoll() {
    const [isRunning, setIsRunning] = useState(false)
    const [checkPoll, setCheckPoll] = useState(false)
    const [hasCreatedOrUpdated, setHasCreatedOrUpdated] = useState(false)
    const [poll, pollDispatch]: any = useContext(CnxFifoQueueContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)

    const getUrl = (environment: string) => {
        switch (environment) {
            case 'localhost':
                return 'http://localhost:3000'
            case 'dev':
                return 'https://lboyueuzli.execute-api.us-east-1.amazonaws.com/prod'
            case 'test':
                return 'https://g4kb6ds806.execute-api.us-east-1.amazonaws.com/prod'
            default:
                return 'https://fpqo866loe.execute-api.us-east-1.amazonaws.com/prod'
        }
    }

    const getApiKey = (environment: string): string => {
        switch (environment) {
            case 'localhost':
                return ''
            case 'dev':
                return process.env.REACT_APP_DEV_CNX1_KEY ? process.env.REACT_APP_DEV_CNX1_KEY : ''
            case 'test':
                return process.env.REACT_APP_TEST_CNX1_KEY ? process.env.REACT_APP_TEST_CNX1_KEY : ''
            default:
                return process.env.REACT_APP_PROD_CNX1_KEY ? process.env.REACT_APP_PROD_CNX1_KEY : ''
        }
    }

    async function callCnx(bodyItem: any) {
        const response = await fetch(`${getUrl(config?.environment?.connexOne)}/process-demoform`, {
            method: 'POST',
            body: JSON.stringify(bodyItem),
            headers: {
                'x-api-key': getApiKey(config?.environment?.connexOne),
                company: formData.topServiceableCompany,
                Authorization: formData.topServiceableCompany || 'demoform',
            },
        })
        const data = await response.json()
        if (data?.success) {
            setHasCreatedOrUpdated(true)
        }
    }

    useEffect(() => {
        const runCheck = async () => {
            if (!isRunning && poll?.cnxFifoQueue?.length) {
                const bodyItem = poll?.cnxFifoQueue[0]
                setIsRunning(true)
                await callCnx(bodyItem)
                pollDispatch({ type: 'REMOVE_CALL_FROM_QUEUE' })
                setIsRunning(false)
            } else if (hasCreatedOrUpdated && poll?.cnxFifoQueue?.length) {
                const bodyItem = poll?.cnxFifoQueue[0]
                await callCnx(bodyItem)
                pollDispatch({ type: 'REMOVE_CALL_FROM_QUEUE' })
            }
        }
        runCheck()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [poll, checkPoll])

    useEffect(() => {
        setInterval(() => {
            setCheckPoll(!checkPoll)
        }, 1000)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return null
}

export default CnxFifoQueuePoll
