/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'
// Helpers
import { capitalizeFirstLetter } from '../helpers/string'

function Button({
    disabled = undefined,
    name,
    isSubmitTypeButton = false,
    className = '',
    textClassName = '',
    children,
    onClick = () => {},
    type,
    id,
}: {
    [key: string]: any
}) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [config]: any = useContext(ConfigContext)

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}Button`]) {
        return null
    }

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <button
            disabled={disabled}
            name={name}
            type={isSubmitTypeButton ? 'submit' : 'button'}
            className={styles.twMerge(
                `${styles?.ui?.Button?.[type]?.main?.mobile} ${styles?.ui?.Button?.[type]?.main?.tablet} ${styles?.ui?.Button?.[type]?.main?.desktop} ${styles?.ui?.Button?.[type]?.main?.hover} ${styles?.ui?.Button?.[type]?.main?.active}`,
                className
            )}
            onClick={(event) => {
                onClick(event)
            }}
            id={id}
        >
            <div
                className={styles.twMerge(
                    `${styles?.ui?.Button?.[type]?.text?.mobile} ${styles?.ui?.Button?.[type]?.text?.tablet} ${styles?.ui?.Button?.[type]?.text?.desktop}`,
                    textClassName
                )}
            >
                {children}
            </div>
        </button>
    )
}

export default Button
