/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { ConfigContext } from '../../contexts/Config'
import { QueryParamsContext } from '../../contexts/QueryParams'

function SetIsMock({ setIsLoaded }: { setIsLoaded: Function }) {
    // Contexts
    const [config, configDispatch]: any = useContext(ConfigContext)
    const [queryParams]: any = useContext(QueryParamsContext)

    useEffect(() => {
        if (config?.loaded?.isMock) {
            setIsLoaded(true)
        }
    }, [config?.loaded?.isMock])

    useEffect(() => {
        configDispatch({
            type: 'SET_IS_MOCK',
            payload: queryParams?.isMock === 'true' || queryParams?.isMock === '',
        })
    }, [queryParams?.isMock])

    return null
}

export default SetIsMock
