/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useEffect, useState, useContext } from 'react'
// SetCoreConfigs
import SetPaths from './SetPaths'
import SetTenant from './SetTenant'
import SetTenantConfig from './SetTenantConfig'
import SetDevMode from './SetDevMode'
import SetAceId from './SetAceId'
import SetInitialStartTime from './SetInitialStartTime'
// Contexts
import { InitialLoadBlockersContext } from '../../contexts/InitialLoadBlockers'
import { ConfigContext } from '../../contexts/Config'
import { InstanceContext } from '../../contexts/Instance'
// Components
import ConditionalRender from '../../components/ConditionalRender'
import SetDeploymentData from './SetDeploymentData'
import SetIsMock from './SetIsMock'

function SetCoreConfigs() {
    // Contexts
    const [, initialLoadBlockersDispatch]: any = useContext(InitialLoadBlockersContext)
    const [config, configDispatch]: any = useContext(ConfigContext)
    const [instance]: any = useContext(InstanceContext)
    // States
    const [isSetPathsLoaded, setIsSetPathsLoaded] = useState(false)
    const [isSetTenantLoaded, setIsSetTenantLoaded] = useState(false)
    const [isSetTenantConfig, setIsSetTenantConfig] = useState(false)
    const [isSetDevMode, setIsSetDevMode] = useState(false)
    const [isSetDeploymentData, setIsSetDeploymentData] = useState(false)
    const [isSetAceId, setIsSetAceId] = useState(false)
    const [isSetIsMockLoaded, setSetIsIsMockLoaded] = useState(false)
    const [isSetInitialStartTimeLoaded, setSetInitialStartTimeLoaded] = useState(false)

    useEffect(() => {
        if (config?.loaded?.merged) {
            initialLoadBlockersDispatch({ type: 'SET_IS_CONFIG_LOADED', payload: true })
        }
    }, [config?.loaded?.merged])

    useEffect(() => {
        if (
            isSetPathsLoaded &&
            isSetTenantLoaded &&
            isSetTenantConfig &&
            isSetDevMode &&
            isSetDeploymentData &&
            isSetAceId &&
            isSetIsMockLoaded &&
            isSetInitialStartTimeLoaded
        ) {
            configDispatch({ type: 'MERGE', payload: instance?.contexts?.config })
        }
    }, [
        isSetPathsLoaded,
        isSetTenantLoaded,
        isSetTenantConfig,
        isSetDevMode,
        isSetDeploymentData,
        isSetAceId,
        isSetIsMockLoaded,
        isSetInitialStartTimeLoaded,
        instance?.contexts?.config,
    ])

    return (
        <>
            <SetTenant setIsLoaded={setIsSetTenantLoaded} />
            <ConditionalRender condition={isSetTenantLoaded}>
                <SetPaths setIsLoaded={setIsSetPathsLoaded} />
                <SetTenantConfig setIsLoaded={setIsSetTenantConfig} />
                <SetDevMode setIsLoaded={setIsSetDevMode} />
                <SetDeploymentData setIsLoaded={setIsSetDeploymentData} />
                <SetAceId setIsLoaded={setIsSetAceId} />
                <SetIsMock setIsLoaded={setSetIsIsMockLoaded} />
                <SetInitialStartTime setIsLoaded={setSetInitialStartTimeLoaded} />
            </ConditionalRender>
        </>
    )
}

export default SetCoreConfigs
