/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { StepsContext } from '../contexts/Steps'
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
import { LoadingBlockersContext } from '../contexts/LoadingBlockers'
// Helpers
import getConfigData from '../helpers/config/getConfigData'
import parseStepNames from '../helpers/parseStepNames'
// Types
import { Step } from '../helpers/types/steps'

function SetUpSteps({ formName }: { formName: string }) {
    // Contexts
    const [steps, stepsDispatch]: any = useContext(StepsContext)
    const [config]: any = useContext(ConfigContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [, loadingBlockersDispatch]: any = useContext(LoadingBlockersContext)

    useEffect(() => {
        if (steps?.main?.length > 0) {
            loadingBlockersDispatch({ type: 'REMOVE_PAGE_BLOCKER', payload: 'setUpSteps' })
        }
    }, [config.environment, steps?.main?.length])

    useEffect(() => {
        const asyncWrapper = async () => {
            loadingBlockersDispatch({ type: 'ADD_PAGE_BLOCKER', payload: 'setUpSteps' })
            getConfigData('steps.json', config.tenant, {
                formName,
                isMock: config?.isMock,
                configId: queryParams?.configId ? queryParams.configId : config.deployment,
                configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            }).then((data: { [key: string]: any }) => {
                const arrayOfSteps = Object.keys(data)
                const payload: { [key: string]: Step[] } = {}

                for (let i = 0; i < arrayOfSteps.length; i += 1) {
                    payload[arrayOfSteps[i]] = parseStepNames(data[arrayOfSteps[i]])
                }

                stepsDispatch({
                    type: 'SET_STEPS',
                    payload,
                })
            })
        }

        asyncWrapper()
    }, [
        config.account,
        config.deployment,
        config?.isMock,
        config.tenant,
        formName,
        queryParams.configEnvironment,
        queryParams.configId,
        queryParams.isMock,
    ])

    return null
}

export default SetUpSteps
