// Packages
import React from 'react'
// Stores
import QueryParamsStore from '../contexts/QueryParams'
import ConfigStore from '../contexts/Config'
import InitialLoadBlockersStore from '../contexts/InitialLoadBlockers'
import CnxFifoQueueStore from '../contexts/CnxFifoQueue'

function RootContexts({ children }: { children: React.ReactElement | Array<React.ReactElement> }) {
    return (
        <CnxFifoQueueStore>
            <QueryParamsStore>
                <InitialLoadBlockersStore>
                    <ConfigStore>{children}</ConfigStore>
                </InitialLoadBlockersStore>
            </QueryParamsStore>
        </CnxFifoQueueStore>
    )
}

export default RootContexts
