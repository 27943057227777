/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { QueryParamsContext } from '../../contexts/QueryParams'
import { ConfigContext } from '../../contexts/Config'
import { InstanceContext } from '../../contexts/Instance'
// Helpers
import getConfigData from '../../helpers/config/getConfigData'

function SetPaths({ setIsLoaded }: { setIsLoaded: Function }) {
    // Contexts
    const [queryParams]: any = useContext(QueryParamsContext)
    const [instance]: any = useContext(InstanceContext)
    const [config, configDispatch]: any = useContext(ConfigContext)

    useEffect(() => {
        if (config?.loaded?.paths) {
            setIsLoaded(true)
        }
    }, [config?.loaded?.paths])

    useEffect(() => {
        const asyncWrapper = async () => {
            if (instance?.metaData?.splitTest?.a?.chance) {
                const splitPath = Math.floor(Math.random() * 100)
                configDispatch({ type: 'SET_SPLIT_PATH', payload: splitPath })

                if (splitPath >= 0 && splitPath <= instance.metaData.splitTest.a.chance) {
                    configDispatch({
                        type: 'SET_PATHS',
                        payload: {
                            paths: [
                                {
                                    path: '/*',
                                    form: {
                                        ...instance.metaData.splitTest.a,
                                    },
                                },
                            ],
                        },
                    })
                    configDispatch({
                        type: 'SET_SPLIT_PATH_A',
                    })
                } else {
                    configDispatch({
                        type: 'SET_PATHS',
                        payload: {
                            paths: [
                                {
                                    path: '/*',
                                    form: {
                                        ...instance.metaData.splitTest.b,
                                    },
                                },
                            ],
                        },
                    })
                    configDispatch({
                        type: 'SET_SPLIT_PATH_B',
                    })
                }
            } else if (instance?.metaData?.form?.component) {
                configDispatch({
                    type: 'SET_PATHS',
                    payload: {
                        paths: [
                            {
                                path: '/*',
                                form: {
                                    ...instance?.metaData?.form,
                                },
                            },
                        ],
                    },
                })
            } else {
                getConfigData('paths.json', config.tenant, {
                    isMock: config?.isMock,
                    configId: queryParams?.configId ? queryParams.configId : config.deployment,
                    configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
                }).then((data) => {
                    configDispatch({ type: 'SET_PATHS', payload: data })
                })
            }
        }

        if ((queryParams?.configId || config.deployment) && (queryParams?.configEnvironment || config.account)) {
            asyncWrapper()
        }
    }, [
        queryParams.isMock,
        config.tenant,
        queryParams.configEnvironment,
        queryParams.configId,
        config.deployment,
        config.account,
        config?.isMock,
        queryParams.instanceId,
        instance.metaData?.form,
        instance.metaData?.splitTest?.a,
        instance.metaData?.splitTest?.b,
    ])

    return null
}

export default SetPaths
