// Helpers
import initialState from './initialState'

const InitialLoadBlockersReducer = (
    state: Object,
    action: { type: String; payload: any },
    // eslint-disable-next-line no-unused-vars
    dataFunnelContext: { state: { [key: string]: any }; dispatch: Function }
) => {
    switch (action.type) {
        case 'SET':
            dataFunnelContext.dispatch({
                type: 'SET_INITIAL_LOAD_BLOCKERS',
                payload: action.payload,
            })
            return action.payload
        case 'SET_DEFAULT':
            dataFunnelContext.dispatch({
                type: 'SET_INITIAL_LOAD_BLOCKERS',
                payload: initialState,
            })
            return initialState
        case 'SET_IS_CONFIG_LOADED':
            dataFunnelContext.dispatch({
                type: 'SET_INITIAL_LOAD_BLOCKERS_FIELD',
                payload: { key: 'isConfigLoaded', value: action.payload },
            })
            return { ...state, isConfigLoaded: action.payload }
        case 'SET_IS_QUERY_PARAMS_LOADED':
            dataFunnelContext.dispatch({
                type: 'SET_INITIAL_LOAD_BLOCKERS_FIELD',
                payload: { key: 'isQueryParamsLoaded', value: action.payload },
            })
            return { ...state, isQueryParamsLoaded: action.payload }
        default:
            return state
    }
}

export default InitialLoadBlockersReducer
