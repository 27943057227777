/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'
// Helpers
import { capitalizeFirstLetter } from '../helpers/string'

function HeadingText4({ className = '', children, name }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [config]: any = useContext(ConfigContext)

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}HeadingText4`]) {
        return null
    }

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <h1
            className={styles.twMerge(
                styles?.ui?.HeadingText4?.h1?.mobile,
                styles?.ui?.HeadingText4?.h1?.tablet,
                styles?.ui?.HeadingText4?.h1?.desktop,
                className
            )}
        >
            {children}
        </h1>
    )
}

export default HeadingText4
