/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect } from 'react'
// Components
import Page from '../../ui/Page'
import Container from '../../ui/Container'
import Header from '../../ui/Header'
import Footer from '../../ui/Footer'
import Main from '../../ui/Main'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import StyleCopy from '../../components/StyleCopy'
import ExistingProviderContainer from '../../ui/ExistingProviderContainer'
import Button from '../../ui/Button'
// Contexts
import { CopyContext } from '../../contexts/Copy'
import { FormDataContext } from '../../contexts/FormData'
import { ConfigContext } from '../../contexts/Config'
import { StylesContext } from '../../contexts/Styles'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import ConditionalRender from '../../components/ConditionalRender'
import BodyText2 from '../../ui/BodyText2'

function CurrentCustomerOptions({ step, onNext, onPrev, buttons, progressBar }: any) {
    const [formData]: any = useContext(FormDataContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [styles]: any = useContext(StylesContext)
    const [displayError, setDisplayError] = React.useState(false)

    useEffect(() => {
        if (formData?.existingProvider) {
            setDisplayError(false)
        }
    }, [formData?.existingProvider])

    return (
        <Page>
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="piiBasicHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.currentCustomerOptions?.title, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </Heading>
                        <SubHeading name="piiBasicSubHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.currentCustomerOptions?.subHeader, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.piiBasic,
                                })}
                            </StyleCopy>
                        </SubHeading>
                        <ExistingProviderContainer items={copy?.steps?.currentCustomerOptions?.providers} />
                        <ConditionalRender condition={displayError}>
                            <div
                                className={styles.twMerge(
                                    styles?.steps?.currentCustomerOptions?.errorDisplay?.mobile,
                                    styles?.steps?.currentCustomerOptions?.errorDisplay?.tablet,
                                    styles?.steps?.currentCustomerOptions?.errorDisplay?.desktop
                                )}
                            >
                                <BodyText2
                                    className={styles.twMerge(
                                        styles?.steps?.currentCustomerOptions?.errorDisplayText?.mobile,
                                        styles?.steps?.currentCustomerOptions?.errorDisplayText?.tablet,
                                        styles?.steps?.currentCustomerOptions?.errorDisplayText?.desktop
                                    )}
                                >
                                    <StyleCopy>
                                        {parseCopy(copy?.steps?.currentCustomerOptions?.errorMessage, {
                                            config,
                                            formData,
                                            copy: copy?.steps?.piiBasic,
                                        })}
                                    </StyleCopy>
                                </BodyText2>
                            </div>
                        </ConditionalRender>
                        <Button
                            className="mt-4"
                            type="ghostWhite"
                            name="orderCompleteBasicCallButton"
                            onClick={() => {
                                if (!formData?.existingProvider) {
                                    setDisplayError(true)
                                } else {
                                    onNext()
                                }
                            }}
                        >
                            <StyleCopy>Next</StyleCopy>
                        </Button>
                    </Main>
                </div>
                <Footer />
            </Container>
        </Page>
    )
}
export default CurrentCustomerOptions
