/* eslint-disable react/prop-types */
// Packages
import React, { useContext, useState, useEffect } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
// Helpers
import { capitalizeFirstLetter } from '../helpers/string'
import getConfigData from '../helpers/config/getConfigData'

function ProgressBar({
    className = '',
    innerClassName,
    name = '',
    percent,
    imgClassName = '',
}: {
    [key: string]: any
}) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [config]: any = useContext(ConfigContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    // States
    const [image, setImage]: any = useState({})

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'PiggyBank.svg',
        }).then((data: { [key: string]: any }) => {
            setImage(data)
        })
    }, [
        config.account,
        config.deployment,
        config.fromName,
        config?.isMock,
        config.tenant,
        queryParams.configEnvironment,
        queryParams.configId,
        queryParams.environmentId,
    ])

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}`]) {
        return null
    }

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <div
            className={styles.twMerge(
                styles?.ui?.ProgressBar?.outer?.mobile,
                styles?.ui?.ProgressBar?.outer?.tablet,
                styles?.ui?.ProgressBar?.outer?.desktop,
                className
            )}
        >
            <div
                className={styles.twMerge(
                    styles?.ui?.ProgressBar?.inner?.mobile,
                    styles?.ui?.ProgressBar?.inner?.tablet,
                    styles?.ui?.ProgressBar?.inner?.desktop,
                    innerClassName,
                    `w-[${percent}%]`
                )}
            >
                <img
                    className={styles.twMerge(
                        styles?.ui?.ProgressBar?.img?.mobile,
                        styles?.ui?.ProgressBar?.img?.tablet,
                        styles?.ui?.ProgressBar?.img?.desktop,
                        imgClassName
                    )}
                    src={image.url}
                    alt={image.alt}
                />
            </div>
        </div>
    )
}

export default ProgressBar
