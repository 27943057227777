/* eslint-disable react/prop-types */
// Packages
import React, { useContext, useState, useRef, useEffect } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'

function Scroll({ className = '', children }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [scrollTop, setScrollTop] = useState(0)
    const [scrollHeight, setScrollHeight] = useState(0)
    const [clientHeight, setClientHeight] = useState(0)
    const wrapperRef = useRef<HTMLDivElement>(null)

    const getVisibleSides = (): { top: boolean; bottom: boolean } => {
        const isBottom = clientHeight === scrollHeight - scrollTop
        const isTop = scrollTop === 0
        const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop

        return {
            top: (isBottom || isBetween) && !(isTop && isBottom),
            bottom: (isTop || isBetween) && !(isTop && isBottom),
        }
    }

    useEffect(() => {
        const resetRefSizes = (ref: React.RefObject<HTMLDivElement>) => {
            if (!ref.current) return

            setScrollTop(ref.current.scrollTop)
            setScrollHeight(ref.current.scrollHeight)
            setClientHeight(ref.current.clientHeight)
        }

        resetRefSizes(wrapperRef)
    }, [wrapperRef?.current?.clientHeight])

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <div
            className={styles.twMerge(
                styles?.ui?.Scroll?.container?.mobile,
                styles?.ui?.Scroll?.container?.tablet,
                styles?.ui?.Scroll?.container?.desktop,
                className
            )}
        >
            <div
                ref={wrapperRef}
                className={styles.twMerge(
                    styles?.ui?.Scroll?.textContainer?.mobile,
                    styles?.ui?.Scroll?.textContainer?.tablet,
                    styles?.ui?.Scroll?.textContainer?.desktop
                )}
                onScroll={(event: React.WheelEvent<HTMLDivElement>) => {
                    setScrollTop(event.currentTarget.scrollTop)
                    setScrollHeight(event.currentTarget.scrollHeight)
                    setClientHeight(event.currentTarget.clientHeight)
                }}
            >
                <div
                    className={styles.twMerge(
                        styles?.ui?.Scroll?.topShadow?.mobile,
                        styles?.ui?.Scroll?.topShadow?.tablet,
                        styles?.ui?.Scroll?.topShadow?.desktop,
                        getVisibleSides().top ? 'block' : 'hidden'
                    )}
                />
                {children}
                <div
                    className={styles.twMerge(
                        styles?.ui?.Scroll?.bottomShadow?.mobile,
                        styles?.ui?.Scroll?.bottomShadow?.tablet,
                        styles?.ui?.Scroll?.bottomShadow?.desktop,
                        getVisibleSides().bottom ? 'block' : 'hidden'
                    )}
                />
            </div>
        </div>
    )
}

export default Scroll
