/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import Button from '../../ui/Button'
import Header from '../../ui/Header'
import Footer from '../../ui/Footer'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { FormDataContext } from '../../contexts/FormData'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { StylesContext } from '../../contexts/Styles'
import { StepsContext } from '../../contexts/Steps'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'

function AcpQuestionBasic({ onNext, progressBar }: any) {
    // States
    const [fieldSet, setFieldSet] = useState(false)
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [styles]: any = useContext(StylesContext)

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    useEffect(() => {
        if (fieldSet) {
            onNext()
        }
    }, [fieldSet])

    return (
        <Page>
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="acpQuestionBasicHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.acpQuestionBasic?.title, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.acpQuestionBasic,
                                })}
                            </StyleCopy>
                        </Heading>
                        <SubHeading name="acpQuestionBasicSubHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.acpQuestionBasic?.subHeader, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.acpQuestionBasic,
                                })}
                            </StyleCopy>
                        </SubHeading>
                        <div
                            className={styles.twMerge(
                                styles?.steps?.acpQuestionBasic?.buttonContainer?.mobile,
                                styles?.steps?.acpQuestionBasic?.buttonContainer?.tablet,
                                styles?.steps?.acpQuestionBasic?.buttonContainer?.desktop
                            )}
                        >
                            <Button
                                className={styles.twMerge(
                                    styles?.steps?.acpQuestionBasic?.button?.mobile,
                                    styles?.steps?.acpQuestionBasic?.button?.tablet,
                                    styles?.steps?.acpQuestionBasic?.button?.desktop
                                )}
                                type="ghost"
                                name="acpQuestionBasicNoButton"
                                onClick={() => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'isAcpTransferable', value: false },
                                    })
                                    setFieldSet(true)
                                }}
                            >
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.acpQuestionBasic?.noButton, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.acpQuestionBasic,
                                    })}
                                </StyleCopy>
                            </Button>
                            <Button
                                className={styles.twMerge(
                                    styles?.steps?.acpQuestionBasic?.button?.mobile,
                                    styles?.steps?.acpQuestionBasic?.button?.tablet,
                                    styles?.steps?.acpQuestionBasic?.button?.desktop
                                )}
                                type="ghost"
                                name="acpQuestionBasicYesButton"
                                onClick={() => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'isAcpTransferable', value: true },
                                    })
                                    setFieldSet(true)
                                }}
                            >
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.acpQuestionBasic?.yesButton, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.acpQuestionBasic,
                                    })}
                                </StyleCopy>
                            </Button>
                        </div>
                    </Main>
                </div>
                <Footer />
            </Container>
        </Page>
    )
}

export default AcpQuestionBasic
