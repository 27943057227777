/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { QueryParamsContext } from '../../contexts/QueryParams'
import { ConfigContext } from '../../contexts/Config'
import { InstanceContext } from '../../contexts/Instance'
// Helpers
import getConfigData from '../../helpers/config/getConfigData'

function SetTenantConfig({ setIsLoaded }: { setIsLoaded: Function }) {
    // Contexts
    const [queryParams]: any = useContext(QueryParamsContext)
    const [config, configDispatch]: any = useContext(ConfigContext)
    const [instance]: any = useContext(InstanceContext)

    useEffect(() => {
        if (config?.loaded?.tenantConfig) {
            setIsLoaded(true)
        }
    }, [config?.loaded?.tenantConfig])

    useEffect(() => {
        const asyncWrapper = async () => {
            if (instance?.contexts?.config?.tenantConfig) {
                configDispatch({
                    type: 'SET_TENANT_CONFIG',
                    payload: instance.contexts.config.tenantConfig,
                })
            } else {
                getConfigData('config.json', config.tenant, {
                    isMock: config?.isMock,
                    configId: queryParams?.configId ? queryParams.configId : config.deployment,
                    configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
                }).then((data) => {
                    configDispatch({
                        type: 'SET_TENANT_CONFIG',
                        payload: data,
                    })
                })
            }
        }

        if ((queryParams?.configId || config.deployment) && (queryParams?.configEnvironment || config.account)) {
            asyncWrapper()
        }
    }, [
        queryParams.isMock,
        config.tenant,
        queryParams.configEnvironment,
        queryParams.configId,
        config.deployment,
        config.account,
        config?.isMock,
        instance?.metaData?.form,
        instance?.contexts?.config,
    ])

    return null
}

export default SetTenantConfig
