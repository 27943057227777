// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
// Helpers
import getConfigData from '../helpers/config/getConfigData'

declare global {
    // eslint-disable-next-line no-unused-vars
    interface HTMLElement {
        href: any
    }
}

function Favicon() {
    // Contexts
    const [config]: any = useContext(ConfigContext)
    const [queryParams]: any = useContext(QueryParamsContext)

    useEffect(() => {
        const asyncWrapper = async () => {
            const faviconApple = document.getElementById('apple-favicon')
            const favicon32x32 = document.getElementById('32x32-favicon')
            const favicon16x16 = document.getElementById('16x16-favicon')

            if (faviconApple) {
                faviconApple.href = await getConfigData('favicon-apple', config.tenant, {
                    isMock: config?.isMock,
                    configId: queryParams?.configId ? queryParams.configId : config.deployment,
                    configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
                })
            }
            if (favicon32x32) {
                favicon32x32.href = await getConfigData('favicon-32px', config.tenant, {
                    isMock: config?.isMock,
                    configId: queryParams?.configId ? queryParams.configId : config.deployment,
                    configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
                })
            }
            if (favicon16x16) {
                favicon16x16.href = await getConfigData('favicon-16px', config.tenant, {
                    isMock: config?.isMock,
                    configId: queryParams?.configId ? queryParams.configId : config.deployment,
                    configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
                })
            }
        }

        asyncWrapper()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [config.tenant])

    return null
}

export default Favicon
