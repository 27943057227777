/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
// Packages
import React, { useEffect, useContext, useState } from 'react'

import { AiOutlineExclamationCircle } from 'react-icons/ai'
// UI
import BodyText2 from './BodyText2'
// Components
import ConditionalRender from '../components/ConditionalRender'
// Contexts
import { ValidationContext } from '../contexts/Validation'
import { CopyContext } from '../contexts/Copy'
import { PopupContext } from '../contexts/Popup'
import { ConfigContext } from '../contexts/Config'
import { StylesContext } from '../contexts/Styles'
// Helpers
import { validateSingleField } from '../helpers/validation/main'
import stringReplace from '../helpers/stringReplace'
import { capitalizeFirstLetter } from '../helpers/string'

// Interface
// {
//     autoComplete: string
//     children: React.ReactElement | string
//     className: string
//     inputClassName: string
//     disabled: boolean | undefined
//     name: string
//     onChange: Function
//     placeholder: string
//     isNumberType: boolean
// }

function Checkbox({
    step = null,
    children,
    disabled = false,
    className = '',
    errorClassName = '',
    labelClassName = '',
    name,
    onChange = () => {},
    validationFunctions = [],
    formDataKey = '',
    disableOnChangeValidation = false,
    initialValue = false,
    popup,
}: any) {
    // Contexts
    const [validation, validationDispatch]: any = useContext(ValidationContext)
    const [copy]: any = useContext(CopyContext)
    const [, popupDispatch]: any = useContext(PopupContext)
    const [config]: any = useContext(ConfigContext)
    const [styles]: any = useContext(StylesContext)
    // States
    const [createdValidation, setCreatedValidation] = useState(false)
    const [value, setValue] = useState(initialValue)
    const [waitToValidate, setWaitToValidate] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        if (!config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}CheckBox`]) {
            if ((step || step === 0) && name && !createdValidation) {
                validationDispatch({
                    type: 'ADD_FIELD',
                    payload: {
                        step,
                        name,
                        formDataKey,
                        functions: validationFunctions,
                    },
                })
                setCreatedValidation(true)
            }
        }
    }, [config?.tenantConfig?.featureFlags, createdValidation, formDataKey, name, step, validationFunctions])

    useEffect(() => {
        const asyncWrapper = async () => {
            const { message, metaData } = await validateSingleField(value, validationFunctions)
            setErrorMessage(
                stringReplace(copy?.validationErrors?.[message] ? copy?.validationErrors?.[message] : '', metaData)
            )
        }

        if (
            waitToValidate &&
            !disableOnChangeValidation &&
            !config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}CheckBox`]
        ) {
            asyncWrapper()
        }
    }, [
        value,
        validationFunctions,
        waitToValidate,
        disableOnChangeValidation,
        copy?.validationErrors,
        config?.tenantConfig?.featureFlags,
        name,
    ])

    useEffect(() => {
        if (validation?.errors?.errorMessages?.[name] || validation?.errors?.errorMessages?.[name] === '') {
            if (errorMessage !== validation.errors.errorMessages[name]) {
                let message = validation.errors.errorMessages[name]
                if (message !== '') {
                    message = stringReplace(
                        copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            ? copy?.validationErrors?.[validation.errors.errorMessages[name]]
                            : '',
                        validation.errors.metaDatas[name]
                    )
                }
                setErrorMessage(message)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [validation.errors])

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}CheckBox`]) {
        return null
    }

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <div
            className={styles.twMerge(
                styles?.ui?.Checkbox?.container?.mobile,
                styles?.ui?.Checkbox?.container?.tablet,
                styles?.ui?.Checkbox?.container?.desktop,
                className
            )}
        >
            <label
                className={styles.twMerge(
                    styles?.ui?.Checkbox?.label?.mobile,
                    styles?.ui?.Checkbox?.label?.tablet,
                    styles?.ui?.Checkbox?.label?.desktop,
                    labelClassName
                )}
            >
                <input
                    type="checkbox"
                    name={name}
                    checked={value}
                    onChange={() => {
                        if (!disabled) {
                            onChange(!value)
                            setValue(!value)
                            setWaitToValidate(true)
                        }
                    }}
                    className={styles.twMerge(
                        styles?.ui?.Checkbox?.input?.mobile,
                        styles?.ui?.Checkbox?.input?.tablet,
                        styles?.ui?.Checkbox?.input?.desktop
                    )}
                />
                <span
                    className={styles.twMerge(
                        styles?.ui?.Checkbox?.checkmark?.mobile,
                        styles?.ui?.Checkbox?.checkmark?.tablet,
                        styles?.ui?.Checkbox?.checkmark?.desktop
                    )}
                />
                <div
                    className={styles.twMerge(
                        styles?.ui?.Checkbox?.text?.mobile,
                        styles?.ui?.Checkbox?.text?.tablet,
                        styles?.ui?.Checkbox?.text?.desktop,
                        errorMessage ? styles?.ui?.Checkbox?.text?.error : ''
                    )}
                >
                    {children}
                </div>
                <ConditionalRender condition={errorMessage}>
                    <BodyText2
                        className={styles.twMerge(
                            styles?.ui?.Checkbox?.error?.mobile,
                            styles?.ui?.Checkbox?.error?.tablet,
                            styles?.ui?.Checkbox?.error?.desktop,
                            errorClassName
                        )}
                    >
                        <AiOutlineExclamationCircle
                            className={styles.twMerge(
                                styles?.ui?.Checkbox?.errorIcon?.mobile,
                                styles?.ui?.Checkbox?.errorIcon?.tablet,
                                styles?.ui?.Checkbox?.errorIcon?.desktop
                            )}
                        />
                        &nbsp;{errorMessage}
                    </BodyText2>
                </ConditionalRender>
            </label>
            <ConditionalRender condition={popup}>
                <button
                    type="button"
                    onClick={() => {
                        popupDispatch({
                            type: 'ADD_POPUP',
                            payload: {
                                name: popup,
                                componentToRender: popup,
                            },
                        })
                    }}
                    className={styles.twMerge(
                        styles?.ui?.Checkbox?.infoButton?.mobile,
                        styles?.ui?.Checkbox?.infoButton?.tablet,
                        styles?.ui?.Checkbox?.infoButton?.desktop
                    )}
                >
                    {' '}
                    ⓘ
                </button>
            </ConditionalRender>
        </div>
    )
}

export default Checkbox
