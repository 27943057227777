/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext, useMemo } from 'react'
import { useLocation } from 'react-router-dom'
// Contexts
import { QueryParamsContext } from '../contexts/QueryParams'
import { InstanceContext } from '../contexts/Instance'
import { InitialLoadBlockersContext } from '../contexts/InitialLoadBlockers'

function SetUpQueryParameters() {
    // Contexts
    const [, queryParamsDispatch]: any = useContext(QueryParamsContext)
    const [instance]: any = useContext(InstanceContext)
    const [, initialLoadBlockersDispatch]: any = useContext(InitialLoadBlockersContext)
    // Query Params
    const location = useLocation()
    const queryParameters = useMemo(() => new URLSearchParams(location.search), [location.search])

    useEffect(() => {
        let tempQueryParams: { [index: string]: any } = {}
        queryParameters.forEach((value, key) => {
            tempQueryParams[key] = value
        })

        if (instance?.contexts?.queryParams) {
            tempQueryParams = { ...tempQueryParams, ...instance.contexts.queryParams }
        }

        queryParamsDispatch({ type: 'SET', payload: tempQueryParams })
        initialLoadBlockersDispatch({ type: 'SET_IS_QUERY_PARAMS_LOADED', payload: true })
    }, [instance?.contexts?.queryParams, queryParameters])

    return null
}

export default SetUpQueryParameters
