export const addDashesToPhone = (phoneNumber: string) => {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`
}

export const removeDashesFromPhone = (phoneNumber: string) => {
    const strippedBeginningParentheses = phoneNumber.replace(/\(/g, '')
    const strippedEndingParentheses = strippedBeginningParentheses.replace(/\)/g, '')
    const strippedSpaces = strippedEndingParentheses.replace(/\s/g, '')
    const strippedDashes = strippedSpaces.replace(/-/g, '')

    return strippedDashes
}

export const phoneNumberAutoFormat = (phoneNumber: string) => {
    const number = phoneNumber.trim().replace(/[^0-9]/g, '')

    if (number.length < 4) return number
    if (number.length < 7) return number.replace(/(\d{3})(\d{1})/, '$1-$2')
    if (number.length < 11) return number.replace(/(\d{3})(\d{3})(\d{1})/, '$1-$2-$3')

    return number.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export default addDashesToPhone
