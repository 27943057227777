// Packages
// import React from 'react'

// Interface
// {
//     condition: Boolean | String
//     children: React.ReactElement | Array<React.ReactElement>
//     falseReturn?: React.ReactElement | undefined
// }

function ConditionalRender({ condition, children, falseReturn = undefined }: any) {
    if (condition) {
        return children
    }

    return falseReturn
}

export default ConditionalRender
