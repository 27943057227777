// Packages
import React, { useContext, useEffect, useRef } from 'react'
// UI
import Loading from '../../ui/LoadingBlocker'
// Contexts
import { FormDataContext } from '../../contexts/FormData'
import { ConfigContext } from '../../contexts/Config'
import { CopyContext } from '../../contexts/Copy'
// Helpers
import makeBackendRequest from '../../helpers/backend'
import { ZipCheckerResponse } from '../../helpers/backend/checkZip'

function ServiceabilityCheckBlockerBasic({ onNext }: any) {
    // Contexts
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [config]: any = useContext(ConfigContext)
    const [copy]: any = useContext(CopyContext)
    // Refs
    const useEffectBlocker = useRef(false)

    useEffect(() => {
        if (useEffectBlocker.current) return

        if (formData?.topServiceableCompany && formData?.existingProvider) {
            formDataDispatch({
                type: 'SET_FIELD',
                payload: {
                    name: 'existingCustomer',
                    value: formData?.topServiceableCompany.toLowerCase() === formData?.existingProvider.toLowerCase(),
                },
            })
        }

        if (formData?.topServiceableCompany) {
            if (copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]?.highSpeedOffers) {
                makeBackendRequest(
                    { stack: 'checkZipMain', endpoint: 'main' },
                    {
                        config,
                        formData,
                        formDataDispatch,
                        additionalCallStack: 'ibexServiceabilityCheck',
                        makeBackendRequest,
                    }
                )
                    .then((data: ZipCheckerResponse) => {
                        const { zipcodeFound } = data
                        formDataDispatch({
                            type: 'SET_FIELD',
                            payload: { name: 'highspeedElegible', value: zipcodeFound },
                        })
                    })
                    .then(() => {
                        onNext()
                        useEffectBlocker.current = true
                    })
            } else {
                onNext()
                useEffectBlocker.current = true
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formData?.topServiceableCompany])

    return <Loading text="This may take up to 30 secs..." useLoadingBar />
}

export default ServiceabilityCheckBlockerBasic
