/* eslint-disable no-unused-vars */
// Helpers
import { log } from '../logging'
import makeBackendRequest from '../backend'

export const onNext = async (params: any) => {
    log({
        config: params.config,
        title: 'P2 Existing Customer Question Step Action',
        message: 'Ran onNext',
    })
    if (params.formData?.phone || params.formData?.email) {
        const response = await makeBackendRequest(
            { stack: 'connexOne', endpoint: 'processDemoform' },
            {
                formData: params.formData,
                config: params.config,
                queryParams: params.queryParams,
                cnxFifoQueue: params.cnxFifoQueue,
            }
        )
    }

    const manychatResponse = await makeBackendRequest(
        { stack: 'manychat', endpoint: 'set' },
        { formData: params.formData, config: params.config, queryParams: params.queryParams }
    )
    if (!params?.formData?.existingCustomer) {
        if (
            params?.config?.tenantConfig?.offersProviders?.includes(
                params?.formData?.topServiceableCompany?.toLowerCase()
            ) &&
            params?.formData?.topServiceableCompany?.toLowerCase() === 'frontier' &&
            !params?.formData?.frontierFiber
        ) {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.successAllProvider } })
        } else if (
            params?.config?.tenantConfig?.offersProviders?.includes(
                params?.formData?.topServiceableCompany?.toLowerCase()
            )
        ) {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.optimumSuccess } })
        } else if (
            params?.config?.tenantConfig?.comingSoonProviders?.includes(
                params?.formData?.topServiceableCompany?.toLowerCase()
            ) ||
            params?.config?.tenantConfig?.expandingSoonProviders?.includes(
                params?.formData?.topServiceableCompany?.toLowerCase()
            )
        ) {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.unserviceable } })
        } else {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.successAllProvider } })
        }
    } else if (params.formData?.eligibleProviders?.length > 1) {
        params.formDataDispatch({
            type: 'SET_FIELD',
            payload: { name: 'topServiceableCompany', value: params?.formData?.eligibleProviders?.[1] },
        })
        const topServiceableCompany = params?.formData?.eligibleProviders?.[1]
        if (
            params?.config?.tenantConfig?.offersProviders?.includes(topServiceableCompany?.toLowerCase()) &&
            topServiceableCompany?.toLowerCase() === 'frontier' &&
            !params?.formData?.frontierFiber
        ) {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.successAllProvider } })
        } else if (params?.config?.tenantConfig?.offersProviders?.includes(topServiceableCompany?.toLowerCase())) {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.optimumSuccess } })
        } else if (params?.config?.tenantConfig?.successAllProviders?.includes(topServiceableCompany?.toLowerCase())) {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.successAllProvider } })
        } else if (
            params?.config?.tenantConfig?.comingSoonProviders?.includes(topServiceableCompany?.toLowerCase()) ||
            params?.config?.tenantConfig?.expandingSoonProviders?.includes(topServiceableCompany?.toLowerCase())
        ) {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.unserviceable } })
        } else {
            params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.ibex } })
        }
    } else {
        params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.unserviceable } })
    }
}
export const onPrev = async (params: any) => {
    log({
        config: params.config,
        title: 'P2 Existing Customer Question Step Action',
        message: 'Ran onPrev',
    })
}
export const onSubmit = async (params: any) => {
    log({
        config: params.config,
        title: 'P2 Existing Customer Question Step Action',
        message: 'Ran onSubmit',
    })
    await onNext(params)
    params.stepsDispatch({ type: 'INCREMENT_CURRENT_STEP' })
}

const onAction = async (action: string, params: any) => {
    if (action === 'next') {
        await onNext(params)
    } else if (action === 'prev') {
        await onPrev(params)
    } else if (action === 'submit') {
        await onSubmit(params)
    }
}

export default onAction
