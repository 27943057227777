/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
import moment from 'moment'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'

function SetInitialStartTime({ setIsLoaded }: { setIsLoaded: Function }) {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)

    useEffect(() => {
        setIsLoaded(true)
    }, [])

    useEffect(() => {
        dataFunnelDispatch({
            type: 'SET_JOURNEY_START',
            payload: moment(Date.now())
                .tz('America/Los_Angeles')
                .format()
                .replace('T', ' ')
                .replace('-08:00', '.000')
                .replace('-07:00', '.000'),
        })
    }, [])

    return null
}

export default SetInitialStartTime
