/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'

// Contexts
import { ConfigContext } from '../contexts/Config'
import { StylesContext } from '../contexts/Styles'
// Helpers
import { capitalizeFirstLetter } from '../helpers/string'

function BodyText5({ className = '', children, name }: { [key: string]: any }) {
    // Contexts
    const [config]: any = useContext(ConfigContext)
    const [styles]: any = useContext(StylesContext)

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}BodyText5`]) {
        return null
    }

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <p
            className={styles.twMerge(
                styles?.ui?.BodyText5?.p?.mobile,
                styles?.ui?.BodyText5?.p?.tablet,
                styles?.ui?.BodyText5?.p?.desktop,
                className
            )}
        >
            {children}
        </p>
    )
}

export default BodyText5
