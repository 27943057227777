// Packages
import React, { useContext, useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom'
// Helpers
import formNameMap from '../helpers/supportData/formNameMap'
// Contexts
import { ConfigContext } from '../contexts/Config'

function GenerateRoutes() {
    // Contexts
    const [config]: any = useContext(ConfigContext)
    // States
    const [paths, setPaths] = useState([])

    useEffect(() => {
        if (config?.paths?.paths?.length > 0) {
            setPaths(config.paths.paths)
        }
    }, [config.paths])

    return paths.map((pathObject: { path: string; form: { component: string; config: string } }) => {
        return (
            <Routes key={`${pathObject.path}-${pathObject.form}`}>
                <Route
                    path={pathObject.path}
                    element={formNameMap({ key: pathObject.form.component, config: pathObject.form.config })}
                />
            </Routes>
        )
    })
}

export default GenerateRoutes
