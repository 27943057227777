// Helpers
import initialState from './initialState'

const FormDataReducer = (
    state: Object,
    action: { type: String; payload: any },
    // eslint-disable-next-line no-unused-vars
    dataFunnelContext: { state: { [key: string]: any }; dispatch: Function }
) => {
    switch (action.type) {
        case 'SET':
            dataFunnelContext.dispatch({
                type: 'SET_FORM_DATA',
                payload: action.payload,
            })
            return action.payload
        case 'SET_DEFAULT':
            dataFunnelContext.dispatch({
                type: 'SET_FORM_DATA',
                payload: initialState,
            })
            return initialState
        case 'SET_FIELD':
            dataFunnelContext.dispatch({
                type: 'SET_FORM_DATA_FIELD',
                payload: { key: action.payload.name, value: action.payload.value },
            })
            return { ...state, [action.payload.name]: action.payload.value }
        case 'MERGE': {
            const newFormData = { ...state, ...action.payload }
            dataFunnelContext.dispatch({
                type: 'SET_FORM_DATA',
                payload: newFormData,
            })
            return newFormData
        }
        default:
            return state
    }
}

export default FormDataReducer
