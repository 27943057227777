import SmartySDK from 'smartystreets-javascript-sdk'

// Smarty
const SmartyCore = SmartySDK.core
const { Lookup } = SmartySDK.usAutocompletePro
const key: string = process.env.REACT_APP_DIRECT_SMARTY_API_KEY ? process.env.REACT_APP_DIRECT_SMARTY_API_KEY : ''
const credentials = new SmartyCore.SharedCredentials(key)
const clientBuilder = new SmartyCore.ClientBuilder(credentials).withLicenses(['us-autocomplete-pro-cloud'])
const client = clientBuilder.buildUsAutocompleteProClient()

export const requestToQuery = (request: any) => {
    const street = request.street ? `${request.street} ` : ''
    const secondary = request?.secondary ? `${request.secondary} ` : ''
    const entries = request?.entries > 0 ? `(${request.entries}) ` : ''
    const city = request?.city ? `${request.city} ` : ''
    const state = request?.state ? `${request.state}, ` : ''
    const zip = request?.zip ? `${request.zip}` : ''

    return street + secondary + entries + city + state + zip
}

export const querySmartyAutoComplete = async (smartyObject: any, preferZIPCodes = [], maxResults = 5) => {
    const query: string = typeof smartyObject === 'string' ? smartyObject : requestToQuery(smartyObject)
    const lookup = new Lookup(query)
    lookup.maxResults = maxResults

    if (preferZIPCodes?.length > 0) {
        lookup.preferZIPCodes = preferZIPCodes
    }

    if (smartyObject?.entries > 0) {
        lookup.selected = query
    }

    const results = await client.send(lookup)

    return results.result
}

export default querySmartyAutoComplete
