/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
import moment from 'moment-timezone'
// Contexts
import { DataFunnelContext } from '../contexts/DataFunnel'
import { FormDataContext } from '../contexts/FormData'
// Helpers
import makeBackendRequest from '../helpers/backend'
import { camelToSnakeCase } from '../helpers/string'

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        hj: any
    }
}

function DbTracking() {
    // Contexts
    const [dataFunnel]: any = useContext(DataFunnelContext)
    const [, formDataDispatch]: any = useContext(FormDataContext)

    // HotJar
    useEffect(() => {
        if (dataFunnel?.contexts?.config?.aceId) {
            window.hj('identify', dataFunnel.contexts.config.aceId, {
                userId: dataFunnel.contexts.config.aceId,
                referrer: document.referrer,
            })
        }
    }, [dataFunnel?.contexts?.config?.aceId])

    // UUID Store
    useEffect(() => {
        const asyncWrapper = async () => {
            if (
                dataFunnel?.contexts?.config?.aceId &&
                !dataFunnel?.contexts?.formData?.uuid &&
                dataFunnel?.contexts?.config?.environment?.aceUserTables &&
                !dataFunnel?.contexts?.queryParams?.uuid
            ) {
                const uuidResponse = await makeBackendRequest(
                    { stack: 'pinpoint', endpoint: 'storeUuid' },
                    {
                        config: dataFunnel?.contexts?.config,
                        formData: dataFunnel?.contexts?.formData,
                        body: {
                            pk: dataFunnel?.contexts?.config?.aceId,
                        },
                    }
                )
                const uuidResponseData = await uuidResponse.json()
                const uuid = uuidResponseData?.uuid
                makeBackendRequest(
                    { stack: 'aceUserTables', endpoint: 'putUser' },
                    {
                        config: dataFunnel?.contexts?.config,
                        formData: dataFunnel?.contexts?.formData,
                        body: {
                            PK: dataFunnel?.contexts?.config?.aceId,
                            main_demo_4: {
                                sms_uuid: uuid,
                            },
                        },
                    }
                )
                formDataDispatch({
                    type: 'SET_FIELD',
                    payload: {
                        name: 'uuid',
                        value: uuid,
                    },
                })
            }
        }
        asyncWrapper()
    }, [dataFunnel?.contexts?.config?.aceId, dataFunnel?.contexts?.config?.environment?.aceUserTables])

    // Referrer
    useEffect(() => {
        if (dataFunnel?.contexts?.config?.aceId && dataFunnel?.contexts?.config?.environment?.aceUserTables) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            referrer: document.referrer,
                        },
                    },
                }
            )
        }
    }, [dataFunnel?.contexts?.config?.aceId, dataFunnel?.contexts?.config?.environment?.aceUserTables])

    // Entry Name
    useEffect(() => {
        if (dataFunnel?.contexts?.config?.aceId && dataFunnel?.contexts?.config?.environment?.aceUserTables) {
            const demoStartTime = Date.now()
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            start_time: moment(demoStartTime)
                                .tz('America/Los_Angeles')
                                .format()
                                .replace('T', ' ')
                                .replace('-08:00', '.000')
                                .replace('-07:00', '.000'),
                        },
                    },
                }
            )
        }
    }, [dataFunnel?.contexts?.config?.aceId, dataFunnel?.contexts?.config?.environment?.aceUserTables])

    // Step Name
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.steps?.main?.length > 0
        ) {
            window.hj('identify', dataFunnel.contexts.config.aceId, {
                lastPage: camelToSnakeCase(
                    dataFunnel?.contexts?.steps?.main[dataFunnel?.contexts?.steps?.currentStep]?.name
                ),
            })
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            [camelToSnakeCase(
                                dataFunnel?.contexts?.steps?.main[dataFunnel?.contexts?.steps?.currentStep]?.name
                            )]: true,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.steps?.currentStep,
        dataFunnel?.contexts?.steps?.main?.length,
    ])

    // Broadband Label
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.isBroadbandLabelRequested !== undefined &&
            dataFunnel?.contexts?.formData?.isBroadbandLabelRequested !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            request_bb_label: dataFunnel?.contexts?.formData?.isBroadbandLabelRequested,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.isBroadbandLabelRequested,
    ])

    // Offer Selected
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.selectedPlan !== undefined &&
            dataFunnel?.contexts?.formData?.selectedPlan !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            offer_selected: dataFunnel?.contexts?.formData?.selectedPlan,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.selectedPlan,
    ])

    // Form Name
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.config?.formName !== undefined &&
            dataFunnel?.contexts?.config?.formName !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            form_name: dataFunnel?.contexts?.config?.formName,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.config?.formName,
    ])

    // Form Name
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.config?.deployment !== undefined &&
            dataFunnel?.contexts?.config?.deployment !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            form_version: dataFunnel?.contexts?.config?.deployment,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.config?.deployment,
    ])

    // Is ACP Order
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.isAcpTransferable !== undefined &&
            dataFunnel?.contexts?.formData?.isAcpTransferable !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            is_acp_order: dataFunnel?.contexts?.formData?.isAcpTransferable,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.isAcpTransferable,
    ])

    // Top Serviceable Provider
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.topServiceableCompany !== undefined &&
            dataFunnel?.contexts?.formData?.topServiceableCompany !== null
        ) {
            window.hj('identify', dataFunnel.contexts.config.aceId, {
                topServiceableCompany: dataFunnel?.contexts?.formData?.topServiceableCompany,
            })
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            top_serviceable_company: dataFunnel?.contexts?.formData?.topServiceableCompany,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.topServiceableCompany,
    ])

    // Top Serviceable Provider
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.connexID !== undefined &&
            dataFunnel?.contexts?.formData?.connexID !== null
        ) {
            window.hj('identify', dataFunnel.contexts.config.aceId, {
                connexID: dataFunnel?.contexts?.formData?.connexID,
            })
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            connexID: dataFunnel?.contexts?.formData?.connexID,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.connexID,
    ])

    // Instance Id
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.queryParams?.instanceId !== undefined &&
            dataFunnel?.contexts?.queryParams?.instanceId !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            instance_id: dataFunnel?.contexts?.queryParams?.instanceId,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.queryParams?.instanceId,
    ])

    // Data List Id
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.config?.dataListId !== undefined &&
            dataFunnel?.contexts?.config?.dataListId !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            data_list_id: dataFunnel?.contexts?.config?.dataListId,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.config?.dataListId,
    ])

    // Targeted Provider
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.config?.targetedProvider !== undefined &&
            dataFunnel?.contexts?.config?.targetedProvider !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            targeted_provider: dataFunnel?.contexts?.config?.targetedProvider,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.config?.targetedProvider,
    ])

    // Advertising Platform
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.config?.advertisingPlatform !== undefined &&
            dataFunnel?.contexts?.config?.advertisingPlatform !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            advertising_platform: dataFunnel?.contexts?.config?.advertisingPlatform,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.config?.advertisingPlatform,
    ])

    // Source Reference
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.config?.sourceReference !== undefined &&
            dataFunnel?.contexts?.config?.sourceReference !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            source_reference: dataFunnel?.contexts?.config?.sourceReference,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.config?.sourceReference,
    ])

    // Campaign Type
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.config?.campaignType !== undefined &&
            dataFunnel?.contexts?.config?.campaignType !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            campaign_type: dataFunnel?.contexts?.config?.campaignType,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.config?.campaignType,
    ])

    // Lead Reference
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.config?.leadReference !== undefined &&
            dataFunnel?.contexts?.config?.leadReference !== null &&
            dataFunnel?.contexts?.config?.internalLeadReference !== undefined &&
            dataFunnel?.contexts?.config?.internalLeadReference !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            lead_reference: dataFunnel?.contexts?.config?.leadReference,
                            internal_lead_reference: dataFunnel?.contexts?.config?.internalLeadReference,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.config?.leadReference,
        dataFunnel?.contexts?.config?.internalLeadReference,
    ])

    // Tenant
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.config?.tenant !== undefined &&
            dataFunnel?.contexts?.config?.tenant !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            tenant: dataFunnel?.contexts?.config?.tenant,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.config?.tenant,
    ])

    // Existing Customer
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.existingCustomer !== undefined &&
            dataFunnel?.contexts?.formData?.existingCustomer !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            existing_customer: dataFunnel?.contexts?.formData?.existingCustomer,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.existingCustomer,
    ])

    // Eligible Providers
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.eligibleProvidersString !== undefined &&
            dataFunnel?.contexts?.formData?.eligibleProvidersString !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            eligible_providers: dataFunnel?.contexts?.formData?.eligibleProvidersString,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.eligibleProvidersString,
    ])

    // Address Ln1
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.addressStreet !== undefined &&
            dataFunnel?.contexts?.formData?.addressStreet !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            address_street: dataFunnel?.contexts?.formData?.addressStreet,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.addressStreet,
    ])

    // Address Ln2
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.addressSecondary !== undefined &&
            dataFunnel?.contexts?.formData?.addressSecondary !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            address_secondary: dataFunnel?.contexts?.formData?.addressSecondary,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.addressSecondary,
    ])

    // Address City
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.addressCity !== undefined &&
            dataFunnel?.contexts?.formData?.addressCity !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            address_city: dataFunnel?.contexts?.formData?.addressCity,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.addressCity,
    ])

    // Address State
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.addressState !== undefined &&
            dataFunnel?.contexts?.formData?.addressState !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            address_state: dataFunnel?.contexts?.formData?.addressState,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.addressState,
    ])

    // Address Zip
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.addressZip !== undefined &&
            dataFunnel?.contexts?.formData?.addressZip !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            address_zip: dataFunnel?.contexts?.formData?.addressZip,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.addressZip,
    ])

    // First Name
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.firstName !== undefined &&
            dataFunnel?.contexts?.formData?.firstName !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            first_name: dataFunnel?.contexts?.formData?.firstName,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.firstName,
    ])

    // Last Name
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.lastName !== undefined &&
            dataFunnel?.contexts?.formData?.lastName !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            last_name: dataFunnel?.contexts?.formData?.lastName,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.lastName,
    ])

    // Email
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.email !== undefined &&
            dataFunnel?.contexts?.formData?.email !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            email: dataFunnel?.contexts?.formData?.email,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.email,
    ])

    // Phone Number
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.phoneNumber !== undefined &&
            dataFunnel?.contexts?.formData?.phoneNumber !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            phone_number: dataFunnel?.contexts?.formData?.phoneNumber,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.phoneNumber,
    ])

    // DOB
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.birthDate !== undefined &&
            dataFunnel?.contexts?.formData?.birthDate !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            birth_date: dataFunnel?.contexts?.formData?.birthDate,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.birthDate,
    ])

    // Email Consent
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.contexts?.formData?.marketingEmailConsent !== undefined &&
            dataFunnel?.contexts?.formData?.marketingEmailConsent !== null
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            is_marketing_email_consent: dataFunnel?.contexts?.formData?.marketingEmailConsent,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.birthDate,
    ])

    // fcServiceability Timer
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            dataFunnel?.timers?.fcServiceabilityTimer?.count &&
            dataFunnel?.timers?.fcServiceabilityTimer?.count !== 0
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            fc_serviceability_timer: `${dataFunnel?.timers?.fcServiceabilityTimer?.count}`,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.timers?.fcServiceabilityTimer?.count,
    ])

    // Selected Lifeline Discount
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            (dataFunnel?.contexts?.formData?.isLifelineDiscount || !dataFunnel?.contexts?.formData?.isLifelineDiscount)
        ) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: {
                            lifeline_discount_selected: `${dataFunnel?.contexts?.formData?.isLifelineDiscount}`,
                        },
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.isLifelineDiscount,
    ])

    // Selected Fundle Bundle
    // Works on the toggle click. So if users click toggle on-off, all those will be requests.
    useEffect(() => {
        if (
            dataFunnel?.contexts?.config?.aceId &&
            dataFunnel?.contexts?.config?.environment?.aceUserTables &&
            (dataFunnel?.contexts?.formData?.isLifelineDiscount || !dataFunnel?.contexts?.formData?.isLifelineDiscount)
        ) {
            const body: any = {}
            if (dataFunnel?.contexts?.formData?.fundleBundleDirectTv) {
                body.fundle_bundle_direct_tv = true
            }
            if (dataFunnel?.contexts?.formData?.fundleBundleVivint) {
                body.fundle_bundle_vivint = true
            }
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                    body: {
                        PK: dataFunnel?.contexts?.config?.aceId,
                        main_demo_4: body,
                    },
                }
            )
        }
    }, [
        dataFunnel?.contexts?.config?.aceId,
        dataFunnel?.contexts?.config?.environment?.aceUserTables,
        dataFunnel?.contexts?.formData?.fundleBundleDirectTv,
        dataFunnel?.contexts?.formData?.fundleBundleVivint,
    ])

    return null
}

export default DbTracking
