// Helpers
import initialState from './initialState'

const ValidationReducer = (
    state: { [key: string]: any },
    action: { type: String; payload: any },
    // eslint-disable-next-line no-unused-vars
    dataFunnelContext: { state: { [key: string]: any }; dispatch: Function }
) => {
    switch (action.type) {
        case 'SET':
            dataFunnelContext.dispatch({
                type: 'SET_VALIDATION',
                payload: action.payload,
            })
            return action.payload
        case 'ADD_FIELD': {
            const newSteps = {
                ...state.steps,
                [action.payload.step]: {
                    ...(state[action.payload.step] ? state?.[action.payload.step] : {}),
                    fields: {
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...(state?.steps?.[action.payload.step]?.fields
                            ? state?.steps?.[action.payload.step]?.fields
                            : {}),
                        [action.payload.name]: {
                            name: action.payload.name,
                            formDataKey: action.payload.formDataKey,
                            formDataKeys: action.payload.formDataKeys,
                            functions: action.payload.functions,
                        },
                    },
                },
            }
            dataFunnelContext.dispatch({
                type: 'SET_VALIDATION_FIELD',
                payload: { key: 'steps', value: newSteps },
            })
            return {
                ...state,
                steps: newSteps,
            }
        }
        case 'REMOVE_FIELDS': {
            const newSteps = {
                ...state.steps,
                [action.payload.step]: {
                    ...(state[action.payload.step] ? state?.[action.payload.step] : {}),
                    fields: {
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        ...(state?.steps?.[action.payload.step]?.fields
                            ? state?.steps?.[action.payload.step]?.fields
                            : {}),
                    },
                },
            }
            for (let i = 0; i < action.payload.names.length; i += 1) {
                if (newSteps?.[action.payload.step]?.fields?.[action.payload.names[i]]) {
                    delete newSteps[action.payload.step].fields[action.payload.names[i]]
                }
            }

            dataFunnelContext.dispatch({
                type: 'SET_VALIDATION_FIELD',
                payload: { key: 'steps', value: newSteps },
            })
            return {
                ...state,
                steps: newSteps,
            }
        }
        case 'ADD_ERROR':
            dataFunnelContext.dispatch({
                type: 'SET_VALIDATION_FIELD',
                payload: { key: 'errors', value: { ...action.payload } },
            })
            return { ...state, errors: { ...action.payload } }
        case 'SET_DEFAULT':
            dataFunnelContext.dispatch({
                type: 'SET_VALIDATION',
                payload: initialState,
            })
            return initialState
        default:
            return state
    }
}

export default ValidationReducer
