/* eslint-disable no-unreachable */
/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
import ConditionalRender from '../../components/ConditionalRender'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import TitleText2 from '../../ui/TitleText2'
import Button from '../../ui/Button'
import OfferCard from '../../ui/OfferCard'
import Footer from '../../ui/Footer'
import Header from '../../ui/Header'
import Card from '../../ui/Card'
import BodyText2 from '../../ui/BodyText2'
import BodyText4 from '../../ui/BodyText4'
import ToggleCard from '../../ui/ToggleCard'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { PopupContext } from '../../contexts/Popup'
import { StylesContext } from '../../contexts/Styles'
import { StepsContext } from '../../contexts/Steps'
import { QueryParamsContext } from '../../contexts/QueryParams'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import getConfigData from '../../helpers/config/getConfigData'

function AcpTransferable({ onNext, progressBar }: any) {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [, popupDispatch]: any = useContext(PopupContext)
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    // States
    const [logo, setLogo]: any = useState({})
    const [govAssistance, setGovAssistance]: any = useState(false)

    useEffect(() => {
        if (formData?.selectedPlan) {
            onNext()
        }
    }, [formData?.selectedPlan])

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: `${formData.topServiceableCompany}Logo.png`,
        }).then((data: { [key: string]: any }) => {
            setLogo(data)
        })
    }, [])

    return (
        <Page>
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="offersBasicHeader">
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                        ?.title,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.offersBasic?.notAcpTransferable?.[
                                            formData?.topServiceableCompany
                                        ],
                                    }
                                )}
                            </StyleCopy>
                        </Heading>
                        <SubHeading name="offersBasicSubHeader">
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                        ?.subHeader,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.notAcpTransferable?.offersBasic,
                                    }
                                )}
                            </StyleCopy>
                        </SubHeading>
                        <img
                            className={styles.twMerge(
                                styles?.steps?.offersBasic?.img?.mobile,
                                styles?.steps?.offersBasic?.img?.tablet,
                                styles?.steps?.offersBasic?.img?.desktop
                            )}
                            src={logo.url}
                            alt={logo.alt}
                        />
                        <ConditionalRender
                            condition={
                                copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]?.note
                            }
                        >
                            <TitleText2
                                name="offersBasicNeedHelper"
                                className={styles.twMerge(
                                    styles?.steps?.offersBasicNote?.needHelp?.mobile,
                                    styles?.steps?.offersBasicNote?.needHelp?.tablet,
                                    styles?.steps?.offersBasicNote?.needHelp?.desktop
                                )}
                            >
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                            ?.needHelp,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.notAcpTransferable?.offersBasic,
                                        }
                                    )}
                                </StyleCopy>
                            </TitleText2>
                            <BodyText4
                                name="offersBasicNote"
                                className={styles.twMerge(
                                    styles?.steps?.offersBasicNote?.note?.mobile,
                                    styles?.steps?.offersBasicNote?.note?.tablet,
                                    styles?.steps?.offersBasicNote?.note?.desktop
                                )}
                            >
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                            ?.note,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.notAcpTransferable?.offersBasic,
                                        }
                                    )}
                                </StyleCopy>
                            </BodyText4>
                        </ConditionalRender>
                        <ConditionalRender
                            condition={
                                !copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]?.note
                            }
                        >
                            <TitleText2
                                name="offersBasicNeedHelper"
                                className={styles.twMerge(
                                    styles?.steps?.offersBasic?.needHelp?.mobile,
                                    styles?.steps?.offersBasic?.needHelp?.tablet,
                                    styles?.steps?.offersBasic?.needHelp?.desktop
                                )}
                            >
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                            ?.needHelp,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.notAcpTransferable?.offersBasic,
                                        }
                                    )}
                                </StyleCopy>
                            </TitleText2>
                        </ConditionalRender>
                        <ConditionalRender
                            condition={
                                (formData?.topServiceableCompany === 'spectrum' ||
                                    formData?.topServiceableCompany === 'frontier') &&
                                formData?.addressState !== 'NY' &&
                                (formData?.estimatedZipIncomeLevel === 'low' ||
                                    formData?.estimatedZipIncomeLevel === 'medium')
                            }
                        >
                            <ToggleCard
                                title="On government assistance?"
                                description="Get the Lifeline discount on your bill!"
                                infoPopup="lifelineDiscountDisclaimer"
                                onCheck={() => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'isLifelineDiscount', value: !formData.isLifelineDiscount },
                                    })
                                    setGovAssistance(!govAssistance)
                                }}
                            />
                        </ConditionalRender>
                        <ConditionalRender condition={formData.topServiceableCompany === 'frontier' && !govAssistance}>
                            <>
                                {/* TODO: Create a split test here to render a different order of cards */}
                                {copy?.steps?.offersBasic?.notAcpTransferable?.[
                                    formData?.topServiceableCompany
                                ]?.offers?.map((offer: any) => {
                                    if (
                                        formData?.frontierOffersNames.includes('Fiber 500 Internet') &&
                                        !formData?.frontierOffersNames.includes('Fiber 1 Gig Internet') &&
                                        offer?.dynamicOfferName === 'Fiber 500 Internet'
                                    ) {
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <OfferCard
                                                priceText={offer?.priceText}
                                                timeUnit={offer?.timeUnit}
                                                buttonCopy={offer?.buttonCopy}
                                                shortDescription={offer?.shortDescription}
                                                sections={offer?.sections}
                                                showMoreCopy={offer?.showMoreCopy}
                                                showLessCopy={offer?.showLessCopy}
                                                isAcp={formData.isAcpTransferable}
                                                strikedPriceText={offer?.strikedPrice}
                                                giftCard={offer?.giftCard}
                                                offerValue={offer?.value}
                                                offerTitleUnit={offer?.offerTitleUnit}
                                                mobileGift={offer?.mobileGift}
                                                imageString={offer?.imageString}
                                                badge="Most Popular"
                                                hboMaxOfferLength={offer?.hboMaxOfferLength}
                                                onClick={() => {
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: { name: 'selectedPlan', value: offer?.value },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanStrikePrice',
                                                            value: offer?.strikedPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanEquipmentCost',
                                                            value: offer?.equipmentPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanOriginalPrice',
                                                            value: offer?.priceText,
                                                        },
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                    if (formData.frontierOffersNames.includes(offer?.dynamicOfferName)) {
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <OfferCard
                                                priceText={offer?.priceText}
                                                timeUnit={offer?.timeUnit}
                                                buttonCopy={offer?.buttonCopy}
                                                shortDescription={offer?.shortDescription}
                                                sections={offer?.sections}
                                                showMoreCopy={offer?.showMoreCopy}
                                                showLessCopy={offer?.showLessCopy}
                                                isAcp={formData.isAcpTransferable}
                                                strikedPriceText={offer?.strikedPrice}
                                                giftCard={offer?.giftCard}
                                                mobileGift={offer?.mobileGift}
                                                imageString={offer?.imageString}
                                                badge={offer?.badge}
                                                hboMaxOfferLength={offer?.hboMaxOfferLength}
                                                onClick={() => {
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: { name: 'selectedPlan', value: offer?.value },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanStrikePrice',
                                                            value: offer?.strikedPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanEquipmentCost',
                                                            value: offer?.equipmentPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanOriginalPrice',
                                                            value: offer?.priceText,
                                                        },
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                    return null
                                })}
                                <ConditionalRender condition={formData.highspeedElegible}>
                                    {copy?.steps?.offersBasic?.notAcpTransferable?.[
                                        formData?.topServiceableCompany
                                    ]?.highSpeedOffers?.map(
                                        (offer: { [key: string]: any }, index: React.Key | null | undefined) => (
                                            <OfferCard
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={index}
                                                priceText={offer?.priceText}
                                                timeUnit={offer?.timeUnit}
                                                buttonCopy={offer?.buttonCopy}
                                                shortDescription={offer?.shortDescription}
                                                priceLockDescription={offer?.priceLockDescription}
                                                equipmentDescription={offer?.equipmentDescription}
                                                sections={offer?.sections}
                                                showMoreCopy={offer?.showMoreCopy}
                                                showLessCopy={offer?.showLessCopy}
                                                isAcp={formData.isAcpTransferable}
                                                strikedPriceText={offer?.strikedPrice}
                                                giftCard={offer?.giftCard}
                                                mobileGift={offer?.mobileGift}
                                                imageString={offer?.imageString}
                                                badge={offer?.badge}
                                                offerValue={offer?.value}
                                                offerTitleUnit={offer?.offerTitleUnit}
                                                topServiceableCompany={formData.topServiceableCompany}
                                                hboMaxOfferLength={offer?.hboMaxOfferLength}
                                                onClick={() => {
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: { name: 'selectedPlan', value: offer?.value },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanStrikePrice',
                                                            value: offer?.strikedPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanEquipmentCost',
                                                            value: offer?.equipmentPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanOriginalPrice',
                                                            value: offer?.priceText,
                                                        },
                                                    })
                                                }}
                                            />
                                        )
                                    )}
                                </ConditionalRender>
                            </>
                        </ConditionalRender>
                        <ConditionalRender condition={formData.topServiceableCompany === 'frontier' && govAssistance}>
                            <>
                                {/* TODO: Create a split test here to render a different order of cards */}
                                {copy?.steps?.offersBasic?.notAcpTransferable?.lifelineDiscountOffers?.[
                                    formData?.topServiceableCompany
                                ]?.offers?.map((offer: any) => {
                                    if (
                                        formData?.frontierOffersNames.includes('Fiber 500 Internet') &&
                                        !formData?.frontierOffersNames.includes('Fiber 1 Gig Internet') &&
                                        offer?.dynamicOfferName === 'Fiber 500 Internet'
                                    ) {
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <OfferCard
                                                priceText={offer?.priceText}
                                                timeUnit={offer?.timeUnit}
                                                buttonCopy={offer?.buttonCopy}
                                                shortDescription={offer?.shortDescription}
                                                sections={offer?.sections}
                                                showMoreCopy={offer?.showMoreCopy}
                                                showLessCopy={offer?.showLessCopy}
                                                isAcp={formData.isAcpTransferable}
                                                // eslint-disable-next-line react/jsx-boolean-value
                                                isLifeline={true}
                                                strikedPriceText={offer?.strikedPrice}
                                                giftCard={offer?.giftCard}
                                                offerValue={offer?.value}
                                                offerTitleUnit={offer?.offerTitleUnit}
                                                mobileGift={offer?.mobileGift}
                                                imageString={offer?.imageString}
                                                badge="Most Popular"
                                                hboMaxOfferLength={offer?.hboMaxOfferLength}
                                                onClick={() => {
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: { name: 'selectedPlan', value: offer?.value },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanStrikePrice',
                                                            value: offer?.strikedPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanEquipmentCost',
                                                            value: offer?.equipmentPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanOriginalPrice',
                                                            value: offer?.priceText,
                                                        },
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                    if (formData.frontierOffersNames.includes(offer?.dynamicOfferName)) {
                                        return (
                                            // eslint-disable-next-line react/jsx-key
                                            <OfferCard
                                                priceText={offer?.priceText}
                                                timeUnit={offer?.timeUnit}
                                                buttonCopy={offer?.buttonCopy}
                                                shortDescription={offer?.shortDescription}
                                                sections={offer?.sections}
                                                showMoreCopy={offer?.showMoreCopy}
                                                showLessCopy={offer?.showLessCopy}
                                                isAcp={formData.isAcpTransferable}
                                                isLifeline
                                                strikedPriceText={offer?.strikedPrice}
                                                giftCard={offer?.giftCard}
                                                mobileGift={offer?.mobileGift}
                                                imageString={offer?.imageString}
                                                badge={offer?.badge}
                                                hboMaxOfferLength={offer?.hboMaxOfferLength}
                                                onClick={() => {
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: { name: 'selectedPlan', value: offer?.value },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanStrikePrice',
                                                            value: offer?.strikedPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanEquipmentCost',
                                                            value: offer?.equipmentPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanOriginalPrice',
                                                            value: offer?.priceText,
                                                        },
                                                    })
                                                }}
                                            />
                                        )
                                    }
                                    return null
                                })}

                                <ConditionalRender condition={formData.highspeedElegible}>
                                    {copy?.steps?.offersBasic?.notAcpTransferable?.[
                                        formData?.topServiceableCompany
                                    ]?.highSpeedOffers?.map(
                                        (offer: { [key: string]: any }, index: React.Key | null | undefined) => (
                                            <OfferCard
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={index}
                                                priceText={offer?.priceText}
                                                timeUnit={offer?.timeUnit}
                                                buttonCopy={offer?.buttonCopy}
                                                shortDescription={offer?.shortDescription}
                                                priceLockDescription={offer?.priceLockDescription}
                                                equipmentDescription={offer?.equipmentDescription}
                                                sections={offer?.sections}
                                                showMoreCopy={offer?.showMoreCopy}
                                                showLessCopy={offer?.showLessCopy}
                                                isAcp={formData.isAcpTransferable}
                                                strikedPriceText={offer?.strikedPrice}
                                                giftCard={offer?.giftCard}
                                                mobileGift={offer?.mobileGift}
                                                imageString={offer?.imageString}
                                                badge={offer?.badge}
                                                offerValue={offer?.value}
                                                offerTitleUnit={offer?.offerTitleUnit}
                                                topServiceableCompany={formData.topServiceableCompany}
                                                hboMaxOfferLength={offer?.hboMaxOfferLength}
                                                onClick={() => {
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: { name: 'selectedPlan', value: offer?.value },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanStrikePrice',
                                                            value: offer?.strikedPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanEquipmentCost',
                                                            value: offer?.equipmentPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanOriginalPrice',
                                                            value: offer?.priceText,
                                                        },
                                                    })
                                                }}
                                            />
                                        )
                                    )}
                                </ConditionalRender>
                            </>
                        </ConditionalRender>
                        <ConditionalRender condition={formData.topServiceableCompany !== 'frontier' && !govAssistance}>
                            <>
                                {/* TODO: Create a split test here to render a different order of cards */}
                                {copy?.steps?.offersBasic?.notAcpTransferable?.[
                                    formData?.topServiceableCompany
                                ]?.offers?.map((offer: any) => {
                                    if (
                                        formData.topServiceableCompany === 'optimum' &&
                                        (offer.value === '300Mbps' || offer.value === '100Mbps')
                                    ) {
                                        return null
                                    }
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <OfferCard
                                            priceText={offer?.priceText}
                                            timeUnit={offer?.timeUnit}
                                            buttonCopy={offer?.buttonCopy}
                                            shortDescription={offer?.shortDescription}
                                            priceLockDescription={offer?.priceLockDescription}
                                            equipmentDescription={offer?.equipmentDescription}
                                            sections={offer?.sections}
                                            showMoreCopy={offer?.showMoreCopy}
                                            showLessCopy={offer?.showLessCopy}
                                            isAcp={formData.isAcpTransferable}
                                            strikedPriceText={offer?.strikedPrice}
                                            giftCard={offer?.giftCard}
                                            mobileGift={offer?.mobileGift}
                                            imageString={offer?.imageString}
                                            badge={offer?.badge}
                                            offerValue={offer?.value}
                                            offerTitleUnit={offer?.offerTitleUnit}
                                            topServiceableCompany={formData.topServiceableCompany}
                                            hboMaxOfferLength={offer?.hboMaxOfferLength}
                                            onClick={() => {
                                                formDataDispatch({
                                                    type: 'SET_FIELD',
                                                    payload: { name: 'selectedPlan', value: offer?.value },
                                                })
                                                formDataDispatch({
                                                    type: 'SET_FIELD',
                                                    payload: {
                                                        name: 'selectedPlanStrikePrice',
                                                        value: offer?.strikedPrice,
                                                    },
                                                })
                                                formDataDispatch({
                                                    type: 'SET_FIELD',
                                                    payload: {
                                                        name: 'selectedPlanEquipmentCost',
                                                        value: offer?.equipmentPrice,
                                                    },
                                                })
                                                formDataDispatch({
                                                    type: 'SET_FIELD',
                                                    payload: {
                                                        name: 'selectedPlanOriginalPrice',
                                                        value: offer?.priceText,
                                                    },
                                                })
                                            }}
                                        />
                                    )
                                })}

                                <ConditionalRender condition={formData.highspeedElegible}>
                                    {copy?.steps?.offersBasic?.notAcpTransferable?.[
                                        formData?.topServiceableCompany
                                    ]?.highSpeedOffers?.map(
                                        (offer: { [key: string]: any }, index: React.Key | null | undefined) => (
                                            <OfferCard
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={index}
                                                priceText={offer?.priceText}
                                                timeUnit={offer?.timeUnit}
                                                buttonCopy={offer?.buttonCopy}
                                                shortDescription={offer?.shortDescription}
                                                priceLockDescription={offer?.priceLockDescription}
                                                equipmentDescription={offer?.equipmentDescription}
                                                sections={offer?.sections}
                                                showMoreCopy={offer?.showMoreCopy}
                                                showLessCopy={offer?.showLessCopy}
                                                isAcp={formData.isAcpTransferable}
                                                strikedPriceText={offer?.strikedPrice}
                                                giftCard={offer?.giftCard}
                                                mobileGift={offer?.mobileGift}
                                                imageString={offer?.imageString}
                                                badge={offer?.badge}
                                                offerValue={offer?.value}
                                                offerTitleUnit={offer?.offerTitleUnit}
                                                topServiceableCompany={formData.topServiceableCompany}
                                                hboMaxOfferLength={offer?.hboMaxOfferLength}
                                                onClick={() => {
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: { name: 'selectedPlan', value: offer?.value },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanStrikePrice',
                                                            value: offer?.strikedPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanEquipmentCost',
                                                            value: offer?.equipmentPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanOriginalPrice',
                                                            value: offer?.priceText,
                                                        },
                                                    })
                                                }}
                                            />
                                        )
                                    )}
                                </ConditionalRender>

                                {copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.footCard && (
                                    <Card
                                        className={styles.twMerge(
                                            styles?.steps?.offersBasicNote?.footCard?.mobile,
                                            styles?.steps?.offersBasicNote?.footCard?.tablet,
                                            styles?.steps?.offersBasicNote?.footCard?.desktop
                                        )}
                                    >
                                        <BodyText2 name="offersBasicNote" className="text-center">
                                            <StyleCopy>
                                                {parseCopy(
                                                    copy?.steps?.offersBasic?.notAcpTransferable?.[
                                                        formData?.topServiceableCompany
                                                    ]?.footCard,
                                                    {
                                                        config,
                                                        formData,
                                                        copy: copy?.steps?.notAcpTransferable?.offersBasic.footCard,
                                                    }
                                                )}
                                            </StyleCopy>
                                        </BodyText2>
                                    </Card>
                                )}
                            </>
                        </ConditionalRender>
                        <ConditionalRender condition={formData.topServiceableCompany !== 'frontier' && govAssistance}>
                            <>
                                {copy?.steps?.offersBasic?.notAcpTransferable?.lifelineDiscountOffers?.[
                                    formData?.topServiceableCompany
                                ]?.offers?.map((offer: any) => {
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <OfferCard
                                            priceText={offer?.priceText}
                                            timeUnit={offer?.timeUnit}
                                            buttonCopy={offer?.buttonCopy}
                                            shortDescription={offer?.shortDescription}
                                            priceLockDescription={offer?.priceLockDescription}
                                            equipmentDescription={offer?.equipmentDescription}
                                            sections={offer?.sections}
                                            showMoreCopy={offer?.showMoreCopy}
                                            showLessCopy={offer?.showLessCopy}
                                            isAcp={formData.isAcpTransferable}
                                            isLifeline
                                            strikedPriceText={offer?.strikedPrice}
                                            giftCard={offer?.giftCard}
                                            mobileGift={offer?.mobileGift}
                                            imageString={offer?.imageString}
                                            badge={offer?.badge}
                                            offerValue={offer?.value}
                                            offerTitleUnit={offer?.offerTitleUnit}
                                            topServiceableCompany={formData.topServiceableCompany}
                                            hboMaxOfferLength={offer?.hboMaxOfferLength}
                                            onClick={() => {
                                                formDataDispatch({
                                                    type: 'SET_FIELD',
                                                    payload: { name: 'selectedPlan', value: offer?.value },
                                                })
                                                formDataDispatch({
                                                    type: 'SET_FIELD',
                                                    payload: {
                                                        name: 'selectedPlanStrikePrice',
                                                        value: offer?.strikedPrice,
                                                    },
                                                })
                                                formDataDispatch({
                                                    type: 'SET_FIELD',
                                                    payload: {
                                                        name: 'selectedPlanEquipmentCost',
                                                        value: offer?.equipmentPrice,
                                                    },
                                                })
                                                formDataDispatch({
                                                    type: 'SET_FIELD',
                                                    payload: {
                                                        name: 'selectedPlanOriginalPrice',
                                                        value: offer?.priceText,
                                                    },
                                                })
                                            }}
                                        />
                                    )
                                })}

                                <ConditionalRender condition={formData.highspeedElegible}>
                                    {copy?.steps?.offersBasic?.notAcpTransferable?.[
                                        formData?.topServiceableCompany
                                    ]?.highSpeedOffers?.map(
                                        (offer: { [key: string]: any }, index: React.Key | null | undefined) => (
                                            <OfferCard
                                                // eslint-disable-next-line react/no-array-index-key
                                                key={index}
                                                priceText={offer?.priceText}
                                                timeUnit={offer?.timeUnit}
                                                buttonCopy={offer?.buttonCopy}
                                                shortDescription={offer?.shortDescription}
                                                priceLockDescription={offer?.priceLockDescription}
                                                equipmentDescription={offer?.equipmentDescription}
                                                sections={offer?.sections}
                                                showMoreCopy={offer?.showMoreCopy}
                                                showLessCopy={offer?.showLessCopy}
                                                isAcp={formData.isAcpTransferable}
                                                strikedPriceText={offer?.strikedPrice}
                                                giftCard={offer?.giftCard}
                                                mobileGift={offer?.mobileGift}
                                                imageString={offer?.imageString}
                                                badge={offer?.badge}
                                                offerValue={offer?.value}
                                                offerTitleUnit={offer?.offerTitleUnit}
                                                topServiceableCompany={formData.topServiceableCompany}
                                                hboMaxOfferLength={offer?.hboMaxOfferLength}
                                                onClick={() => {
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: { name: 'selectedPlan', value: offer?.value },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanStrikePrice',
                                                            value: offer?.strikedPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanEquipmentCost',
                                                            value: offer?.equipmentPrice,
                                                        },
                                                    })
                                                    formDataDispatch({
                                                        type: 'SET_FIELD',
                                                        payload: {
                                                            name: 'selectedPlanOriginalPrice',
                                                            value: offer?.priceText,
                                                        },
                                                    })
                                                }}
                                            />
                                        )
                                    )}
                                </ConditionalRender>

                                {copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.footCard && (
                                    <Card
                                        className={styles.twMerge(
                                            styles?.steps?.offersBasicNote?.footCard?.mobile,
                                            styles?.steps?.offersBasicNote?.footCard?.tablet,
                                            styles?.steps?.offersBasicNote?.footCard?.desktop
                                        )}
                                    >
                                        <BodyText2 name="offersBasicNote" className="text-center">
                                            <StyleCopy>
                                                {parseCopy(
                                                    copy?.steps?.offersBasic?.notAcpTransferable?.[
                                                        formData?.topServiceableCompany
                                                    ]?.footCard,
                                                    {
                                                        config,
                                                        formData,
                                                        copy: copy?.steps?.notAcpTransferable?.offersBasic.footCard,
                                                    }
                                                )}
                                            </StyleCopy>
                                        </BodyText2>
                                    </Card>
                                )}
                            </>
                        </ConditionalRender>
                        <Button
                            type="link"
                            className={styles.twMerge(
                                styles?.steps?.offersBasic?.disclaimer?.mobile,
                                styles?.steps?.offersBasic?.disclaimer?.tablet,
                                styles?.steps?.offersBasic?.disclaimer?.desktop
                            )}
                            name={`${formData?.topServiceableCompany}OffersBasicDisclaimer`}
                            onClick={() => {
                                popupDispatch({
                                    type: 'ADD_POPUP',
                                    payload: {
                                        name: `${formData?.topServiceableCompany}OffersBasicDisclaimer`,
                                        componentToRender: `${formData?.topServiceableCompany}OffersBasicDisclaimer`,
                                    },
                                })
                            }}
                        >
                            <TitleText2>
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                            ?.disclaimerText,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.notAcpTransferable?.offersBasic,
                                        }
                                    )}
                                </StyleCopy>
                            </TitleText2>
                        </Button>
                    </Main>
                </div>
                <Footer />
            </Container>
        </Page>
    )
}

export default AcpTransferable
