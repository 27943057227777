/* eslint-disable no-unused-vars */
// Helpers
import makeBackendRequest from '../backend'
import { log } from '../logging'

export const onNext = async (params: any) => {
    log({
        config: params.config,
        title: 'Default Step Action',
        message: 'Ran onNext',
    })
    if (params.formData?.email) {
        const response = await makeBackendRequest(
            { stack: 'connexOne', endpoint: 'processDemoform' },
            {
                formData: params.formData,
                config: params.config,
                queryParams: params.queryParams,
                cnxFifoQueue: params.cnxFifoQueue,
            }
        )
    }

    if (!params.formData?.fullAddress && params.formData?.addressStreet) {
        params.formDataDispatch({
            type: 'SET_FIELD',
            payload: {
                name: 'fullAddress',
                value: `${params.formData?.addressStreet}${params.formData?.addressSecondary ? `, ${params.formData?.addressSecondary}` : ''}${params.formData?.addressCity ? `, ${params.formData?.addressCity}` : ''}${params.formData?.addressState ? `, ${params.formData?.addressState}` : ''}${params.formData?.addressZip ? `, ${params.formData?.addressZip}` : ''}`,
            },
        })
    }

    const manychatResponse = await makeBackendRequest(
        { stack: 'manychat', endpoint: 'set' },
        { formData: params.formData, config: params.config, queryParams: params.queryParams }
    )
    if (
        (params.formData.isDemoformComplete && params.formData.topServiceableCompany !== 'optimum') ||
        params.formData.isOrderSubmitted
    ) {
        await makeBackendRequest(
            { stack: 'manychat', endpoint: 'submit' },
            { formData: params.formData, config: params.config, queryParams: params.queryParams }
        )
    }
}
export const onPrev = async (params: any) => {
    log({
        config: params.config,
        title: 'Default Step Action',
        message: 'Ran onPrev',
    })
}
export const onSubmit = async (params: any) => {
    log({
        config: params.config,
        title: 'Default Step Action',
        message: 'Ran onSubmit',
    })
    await onNext(params)
}

const onAction = async (action: string, params: any) => {
    if (action === 'next') {
        await onNext(params)
    } else if (action === 'prev') {
        await onPrev(params)
    } else if (action === 'submit') {
        await onSubmit(params)
    }
}

export default onAction
