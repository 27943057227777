// Packages
import React, { useContext } from 'react'
// Components
import ConditionalRender from './ConditionalRender'
// UI
import LoadingBlocker from '../ui/LoadingBlocker'
// Contexts
import { LoadingBlockersContext } from '../contexts/LoadingBlockers'

function DisplayLoadingBlocker() {
    const [loadingBlockers]: any = useContext(LoadingBlockersContext)

    return (
        <ConditionalRender
            falseReturn={<LoadingBlocker />}
            condition={
                !Object.keys(loadingBlockers.blockers).some((blocker) => loadingBlockers.blockers[blocker] === true)
            }
        />
    )
}

export default DisplayLoadingBlocker
