/* eslint-disable react/prop-types */
// Packages
import React, { useContext, useState } from 'react'

import { GrDown, GrUp } from 'react-icons/gr'
// UI
import Button from './Button'
// Contexts
import { StylesContext } from '../contexts/Styles'

function ShowMore({ className = '', textClassName = '', onClick, moreCopy, lessCopy }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)

    const moreText = (
        <div
            className={styles.twMerge(
                styles?.ui?.ShowMore?.show?.mobile,
                styles?.ui?.ShowMore?.show?.tablet,
                styles?.ui?.ShowMore?.show?.desktop
            )}
        >
            {moreCopy} <GrDown />
        </div>
    )
    const lessText = (
        <div
            className={styles.twMerge(
                styles?.ui?.ShowMore?.show?.mobile,
                styles?.ui?.ShowMore?.show?.tablet,
                styles?.ui?.ShowMore?.show?.desktop
            )}
        >
            {lessCopy} <GrUp />
        </div>
    )

    // States
    const [buttonText, setButtonText] = useState(moreText)
    const [buttonState, setButtonState] = useState(false)

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <Button
            type="link"
            onClick={() => {
                onClick(!buttonState)
                setButtonText(!buttonState ? lessText : moreText)
                setButtonState((prev: Boolean) => !prev)
            }}
            className={styles.twMerge(
                styles?.ui?.ShowMore?.Button?.mobile,
                styles?.ui?.ShowMore?.Button?.tablet,
                styles?.ui?.ShowMore?.Button?.desktop,
                className
            )}
            textClassName={styles.twMerge(
                styles?.ui?.ShowMore?.text?.mobile,
                styles?.ui?.ShowMore?.text?.tablet,
                styles?.ui?.ShowMore?.text?.desktop,
                textClassName
            )}
        >
            {buttonText}
        </Button>
    )
}

export default ShowMore
