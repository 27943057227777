// Packages
import React, { createContext, useReducer, useMemo } from 'react'
// Contexts
import DataFunnelReducer from './reducers'
import initialState from './initialState'

export const DataFunnelContext = createContext({})

function Store({ children }: { children: React.ReactNode }) {
    const [state, dispatch] = useReducer(DataFunnelReducer, initialState)

    const stateVariables = useMemo(() => [state, dispatch], [state, dispatch])

    return <DataFunnelContext.Provider value={stateVariables}>{children}</DataFunnelContext.Provider>
}

export default Store
