/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useState, useEffect } from 'react'

import Unsubscribe from '../../steps/Unsubscribe'
import ConditionalRender from '../../components/ConditionalRender'
import SuccessfulUnsubscribe from '../../steps/SuccessfulUnsubscribe'
import Resubscribe from '../../steps/Resubscribe'
import onAction from '../../helpers/stepActionFunctions/updateConnex'
import makeBackendRequest from '../../helpers/backend'
// Contexts
import { FormDataContext } from '../../contexts/FormData'

import { ConfigContext } from '../../contexts/Config'
import { QueryParamsContext } from '../../contexts/QueryParams'

function Stepper() {
    const [step, setStep] = useState(0)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [config]: any = useContext(ConfigContext)

    useEffect(() => {
        if (formData?.aceId && formData?.marketingEmailConsent !== null) {
            makeBackendRequest(
                { stack: 'aceUserTables', endpoint: 'putUser' },
                {
                    config,
                    formData,
                    body: {
                        PK: formData?.aceId,
                        main_demo_4: {
                            is_marketing_email_consent: formData?.marketingEmailConsent,
                        },
                    },
                }
            )
        }
    }, [formData?.aceId, formData?.marketingEmailConsent])

    return (
        <>
            <ConditionalRender condition={step === 0}>
                <Unsubscribe
                    onNext={async () => {
                        setStep(1)
                        await onAction('next', {
                            formData,
                            queryParams,
                            config,
                            formDataDispatch,
                        })
                    }}
                    onPrev={async () => {
                        setStep(2)
                        await onAction('next', {
                            formData,
                            queryParams,
                            config,
                            formDataDispatch,
                        })
                    }}
                />
            </ConditionalRender>
            <ConditionalRender condition={step === 1}>
                <SuccessfulUnsubscribe
                    onNext={async () => {
                        setStep(2)
                        await onAction('next', {
                            formData,
                            queryParams,
                            config,
                            formDataDispatch,
                        })
                    }}
                    onPrev={() => {}}
                />
            </ConditionalRender>
            <ConditionalRender condition={step === 2}>
                <Resubscribe />
            </ConditionalRender>
        </>
    )
}

export default Stepper
