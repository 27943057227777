/* eslint-disable react/require-default-props */
/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext, useState } from 'react'
// Contexts
import { LoadingBlockersContext } from '../contexts/LoadingBlockers'

function AddBlockers({ setUpStyles = false, setStarted }: { setUpStyles?: boolean; setStarted: Function }) {
    const [loadingBlockers, loadingBlockersDispatch]: any = useContext(LoadingBlockersContext)
    const [isLoading, setIsLoading]: any = useState({ setUpStyles: !setUpStyles })

    useEffect(() => {
        if (setUpStyles) {
            loadingBlockersDispatch({ type: 'ADD_PAGE_BLOCKER', payload: 'setUpStyles' })
        }
    }, [])
    useEffect(() => {
        if (!Object.keys(isLoading).some((blocker) => isLoading[blocker] === true)) {
            setStarted(true)
        }
    }, [isLoading])
    useEffect(() => {
        if (setUpStyles && loadingBlockers?.pageBlockers?.setUpStyles) {
            setIsLoading((prev: any) => ({ ...prev, setUpStyles: true }))
        }
    }, [loadingBlockers?.pageBlockers])
    return null
}

export default AddBlockers
