/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
// contexts
import { FormDataContext } from '../contexts/FormData'
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
import { StylesContext } from '../contexts/Styles'
// helpers
import getConfigData from '../helpers/config/getConfigData'
// components
import ConditionalRender from '../components/ConditionalRender'
import BodyText2 from './BodyText2'

function ExistingProviderSelection({ name, imageString }: { name: string; imageString?: string }) {
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [config, configDispatch]: any = useContext(ConfigContext)
    const [queryParams, queryParamsDispatch]: any = useContext(QueryParamsContext)
    const [styles]: any = useContext(StylesContext)

    const [image, setImage]: any = useState({})

    useEffect(() => {
        if (!imageString) return
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: imageString,
        }).then((data: { [key: string]: any }) => {
            setImage(data)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            onClick={() => {
                formDataDispatch({
                    type: 'SET_FIELD',
                    payload: {
                        name: 'existingProvider',
                        value: formData?.existingProvider === name ? null : name,
                    },
                })
            }}
            className={
                styles.twMerge(
                    styles?.steps?.currentCustomerOptions?.selectionContainer?.mobile,
                    styles?.steps?.currentCustomerOptions?.selectionContainer?.tablet,
                    styles?.steps?.currentCustomerOptions?.selectionContainer?.desktop
                ) +
                (formData?.existingProvider === name
                    ? styles.twMerge(
                          styles?.steps?.currentCustomerOptions?.existingProviderContainer?.mobile,
                          styles?.steps?.currentCustomerOptions?.existingProviderContainer?.tablet,
                          styles?.steps?.currentCustomerOptions?.existingProviderContainer?.desktop
                      )
                    : '')
            }
        >
            <div
                className={
                    styles.twMerge(
                        styles?.steps?.currentCustomerOptions?.existingProviderBoundary?.mobile,
                        styles?.steps?.currentCustomerOptions?.existingProviderBoundary?.tablet,
                        styles?.steps?.currentCustomerOptions?.existingProviderBoundary?.desktop
                    ) + (name === 'None of the above' ? ' my-[12px]' : '')
                }
            >
                <BodyText2>{name}</BodyText2>
                <input
                    type="radio"
                    name="existingProvider"
                    checked={formData?.existingProvider === name}
                    className={styles.twMerge(
                        styles?.steps?.currentCustomerOptions?.existingProviderRadio?.mobile,
                        styles?.steps?.currentCustomerOptions?.existingProviderRadio?.tablet,
                        styles?.steps?.currentCustomerOptions?.existingProviderRadio?.desktop
                    )}
                />
            </div>
            <ConditionalRender condition={image.url}>
                <div
                    className={styles.twMerge(
                        styles?.steps?.currentCustomerOptions?.imageContainer?.mobile,
                        styles?.steps?.currentCustomerOptions?.imageContainer?.tablet,
                        styles?.steps?.currentCustomerOptions?.imageContainer?.desktop
                    )}
                />
                <div
                    className={
                        styles.twMerge(
                            styles?.steps?.currentCustomerOptions?.innerImageContainer?.mobile,
                            styles?.steps?.currentCustomerOptions?.innerImageContainer?.tablet,
                            styles?.steps?.currentCustomerOptions?.innerImageContainer?.desktop
                        ) +
                            name ===
                        'optimum'
                            ? 'h-[40%]'
                            : 'h-full'
                    }
                >
                    <img className="max-w-[127.5px]" src={image.url} alt={image.alt} />
                </div>
            </ConditionalRender>
        </div>
    )
}

ExistingProviderSelection.defaultProps = {
    imageString: '',
}

export default ExistingProviderSelection
