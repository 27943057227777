// Packages
import React, { useEffect, useContext, useState } from 'react'
// Components
import ConditionalRender from '../components/ConditionalRender'
// Contexts
import { InstanceContext } from '../contexts/Instance'
// Helpers
import getConfigData from '../helpers/config/getConfigData'

function SetUpInstance({ children }: any) {
    // States
    const [loaded, setLoaded] = useState(false)
    // Contexts
    const [instance, instanceDispatch]: any = useContext(InstanceContext)
    // Query Parameters
    const searchParams = new URLSearchParams(window.location.search)

    useEffect(() => {
        if (instance.loaded) {
            setLoaded(true)
        }
    }, [instance.loaded])

    useEffect(() => {
        if (searchParams.get('instanceId')) {
            try {
                getConfigData('instance.json', '', {
                    isMock: searchParams.get('isMock') === 'true' || searchParams.get('isMock') === '',
                    configId: 'latest',
                    configEnvironment: searchParams.get('configEnvironment')
                        ? searchParams.get('configEnvironment')
                        : 'prod',
                    instanceId: searchParams.get('instanceId'),
                }).then((data) => {
                    instanceDispatch({ type: 'SET', payload: data })
                })
            } catch {
                instanceDispatch({ type: 'SET', payload: {} })
            }
        } else {
            instanceDispatch({ type: 'SET', payload: {} })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <ConditionalRender condition={loaded}>{children}</ConditionalRender>
}

export default SetUpInstance
