/* eslint-disable no-unused-vars */
// Helpers
import { log } from '../logging'

export const onNext = async (params: any) => {
    log({
        config: params.config,
        title: 'Interim Form Branching Step Action',
        message: 'Ran onNext',
    })
    if (
        params?.config?.tenantConfig?.successAllProviders?.includes(
            params?.formData?.topServiceableCompany?.toLowerCase()
        ) ||
        (params?.config?.tenantConfig?.offersProviders?.includes(
            params?.formData?.topServiceableCompany?.toLowerCase()
        ) &&
            params?.formData?.existingProvider)
    ) {
        params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.currentCustomer } })
    } else if (
        params?.config?.tenantConfig?.successAllProviders?.includes(
            params?.formData?.topServiceableCompany?.toLowerCase()
        ) ||
        params?.config?.tenantConfig?.offersProviders?.includes(params?.formData?.topServiceableCompany?.toLowerCase())
    ) {
        params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.currentCustomer } })
    } else if (params.formData?.eligibleProviders?.length >= 1) {
        params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.ibex } })
    } else {
        params.stepsDispatch({ type: 'APPEND_STEPS', payload: { steps: params.steps.unserviceable } })
    }
}
export const onPrev = async (params: any) => {
    log({
        config: params.config,
        title: 'Interim Form Branching Step Action',
        message: 'Ran onPrev',
    })
}
export const onSubmit = async (params: any) => {
    log({
        config: params.config,
        title: 'Interim Form Branching Step Action',
        message: 'Ran onSubmit',
    })
    onNext(params)
    params.stepsDispatch({ type: 'INCREMENT_CURRENT_STEP' })
}

const onAction = async (action: string, params: any) => {
    if (action === 'next') {
        await onNext(params)
    } else if (action === 'prev') {
        await onPrev(params)
    } else if (action === 'submit') {
        await onSubmit(params)
    }
}

export default onAction
