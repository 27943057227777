// Helpers
import initialState from './initialState'

const QueryParamsReducer = (
    state: Object,
    action: { type: String; payload: any },
    // eslint-disable-next-line no-unused-vars
    dataFunnelContext: { state: { [key: string]: any }; dispatch: Function }
) => {
    switch (action.type) {
        case 'SET':
            dataFunnelContext.dispatch({ type: 'SET_QUERY_PARAMS', payload: action.payload })
            return action.payload
        case 'SET_DEFAULT':
            dataFunnelContext.dispatch({ type: 'SET_QUERY_PARAMS', payload: initialState })
            return initialState
        default:
            return state
    }
}

export default QueryParamsReducer
