const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000'
        case 'dev':
            return 'https://vl383jm4i0.execute-api.us-east-1.amazonaws.com/prod'
        case 'test':
            return 'https://vl383jm4i0.execute-api.us-east-1.amazonaws.com/prod'
        default:
            return 'https://vl383jm4i0.execute-api.us-east-1.amazonaws.com/prod'
    }
}

const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        default:
            return process.env.REACT_APP_PROD_PINPOINT_KEY ? process.env.REACT_APP_PROD_PINPOINT_KEY : ''
    }
}

const storeUuid = async (request: { config: any; formData: any; body?: any }) => {
    if (request?.config?.devMode === true) return {}

    return fetch(`${getUrl(request?.config?.environment?.pinpoint)}/storeUuid`, {
        method: 'POST',
        body: JSON.stringify(request?.body),
        headers: {
            'x-api-key': getApiKey(request?.config?.environment?.pinpoint),
            Authorization: 'pinpoint',
        },
    })
}

const endpoints: { [key: string]: Function } = {
    storeUuid,
}

const pinpoint = async (endpoint: string, request: { config: any; formData: any }) => {
    return endpoints[endpoint](request)
}

export default pinpoint
