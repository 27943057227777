// Packages
import React, { useContext } from 'react'
// Contexts
import { FormDataContext } from '../../contexts/FormData'
// Steps
import AcpTransferable from './AcpTransferable'
import NotAcpTransferable from './NotAcpTransferable'

function SuccessOptimumAsset({ progressBar }: any) {
    // Contexts
    const [formData]: any = useContext(FormDataContext)

    if (!formData?.isAcpTransferable) {
        return <NotAcpTransferable progressBar={progressBar} />
    }

    return <AcpTransferable progressBar={progressBar} />
}

export default SuccessOptimumAsset
