/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'
// Helpers
import { capitalizeFirstLetter } from '../helpers/string'
// UI
import BodyText2 from './BodyText2'

function SubHeading({ className = '', children, name }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [config]: any = useContext(ConfigContext)

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}SubHeading`]) {
        return null
    }

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <BodyText2
            className={styles.twMerge(
                styles?.ui?.SubHeading?.mobile,
                styles?.ui?.SubHeading?.tablet,
                styles?.ui?.SubHeading?.desktop,
                className
            )}
        >
            {children}
        </BodyText2>
    )
}

export default SubHeading
