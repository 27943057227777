// Packages
import React, { useContext, useEffect, useRef } from 'react'
// UI
import Loading from '../../ui/LoadingBlocker'
// Contexts
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
// Helpers
import makeBackendRequest from '../../helpers/backend'
import { DataFunnelContext } from '../../contexts/DataFunnel'

function ServiceabilityCheckBasic({ onNext }: any) {
    // Contexts
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    // Refs
    const dataFetch = useRef(false)

    useEffect(() => {
        if (dataFetch.current) return
        dataFetch.current = true
        dataFunnelDispatch({
            type: 'START_MERGED_TIMER',
            payload: { name: `fcServiceabilityTimer` },
        })
        makeBackendRequest(
            { stack: 'ZipIncomeChecker', endpoint: 'main' },
            { config, formData, formDataDispatch, additionalCallStack: 'ibexServiceabilityCheck', makeBackendRequest }
        )
            .then((response) => {
                return response.json()
            })
            .then((data) => {
                formDataDispatch({
                    type: 'SET_FIELD',
                    payload: { name: 'estimatedZipIncomeLevel', value: data?.estimatedIncomeLevel },
                })
            })
        makeBackendRequest(
            { stack: 'fcServiceabilityCheck', endpoint: 'main' },
            { config, formData, formDataDispatch, additionalCallStack: 'ibexServiceabilityCheck', makeBackendRequest }
        )
            .then((response) => {
                try {
                    return response.json()
                } catch (error) {
                    return response
                }
            })
            .then((data) => {
                if (data.top_serviceable_company) {
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'topServiceableCompany', value: data?.top_serviceable_company },
                    })
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'eligibleProvidersString', value: data?.serviceability_array.join('|') },
                    })
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'eligibleProviders', value: data?.serviceability_array },
                    })
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'primaryProviderCount', value: data?.primary_provider_count },
                    })
                    formDataDispatch({
                        type: 'SET_FIELD',
                        payload: { name: 'satelliteProviderCount', value: data?.satellite_provider_count },
                    })
                    if (data?.serviceability_array.includes('frontier')) {
                        formDataDispatch({
                            type: 'SET_FIELD',
                            payload: { name: 'frontierOffers', value: data?.ftrOffers },
                        })
                        formDataDispatch({
                            type: 'SET_FIELD',
                            payload: { name: 'frontierFiber', value: data?.fiber },
                        })
                        // NOTE: If statement was not here before
                        if (data?.ftrOffers) {
                            const newOffers = data?.ftrOffers.map((offer: any) => offer?.name).filter(Boolean)
                            formDataDispatch({
                                type: 'SET_FIELD',
                                payload: {
                                    name: 'frontierOffersNames',
                                    value: newOffers,
                                },
                            })
                        }
                    }
                }
                dataFunnelDispatch({
                    type: 'END_MERGED_TIMER',
                    payload: { name: `fcServiceabilityTimer` },
                })
            })

        onNext()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return <Loading />
}

export default ServiceabilityCheckBasic
