/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext } from 'react'
// Contexts
import { FormDataContext } from '../../contexts/FormData'
import { ConfigContext } from '../../contexts/Config'
// Steps
import MultipleProviders from './MultipleProviders'
import SingleProvider from './SingleProvider'
import ProviderComingSoon from './ProviderComingSoon'

function EndFallbackBasic(props: any) {
    // Contexts
    const [formData]: any = useContext(FormDataContext)
    const [config]: any = useContext(ConfigContext)

    if (
        config?.tenantConfig?.comingSoonProviders?.includes(formData?.eligibleProviders?.[0]) ||
        (formData?.eligibleProviders?.length > 1 &&
            !formData?.eligibleProviders?.every((element: string) => ['hughesnet', 'viasat'].includes(element)) &&
            config?.tenantConfig?.comingSoonProviders?.includes(formData?.eligibleProviders?.[1]))
    ) {
        return React.cloneElement(<ProviderComingSoon />, {
            ...props,
        })
    }

    if (
        formData?.eligibleProviders?.length > 1 &&
        !formData?.eligibleProviders?.every((element: string) => ['hughesnet', 'viasat'].includes(element))
    ) {
        return React.cloneElement(<MultipleProviders />, {
            ...props,
        })
    }

    if (
        config?.tenantConfig?.expandingSoonProviders?.includes(formData?.eligibleProviders?.[0]) ||
        (formData?.eligibleProviders?.length > 1 &&
            !formData?.eligibleProviders?.every((element: string) => ['hughesnet', 'viasat'].includes(element)) &&
            config?.tenantConfig?.expandingSoonProviders?.includes(formData?.eligibleProviders?.[1]))
    ) {
        return React.cloneElement(<SingleProvider />, {
            ...props,
        })
    }

    return React.cloneElement(<SingleProvider />, {
        ...props,
    })
}

export default EndFallbackBasic
