/* eslint-disable no-unused-vars */
// Helpers
import { log } from '../logging'

export const onNext = async (params: any) => {
    log({
        config: params.config,
        title: 'Default Step Action',
        message: 'Ran onNext',
    })
}
export const onPrev = async (params: any) => {
    log({
        config: params.config,
        title: 'Default Step Action',
        message: 'Ran onPrev',
    })
}
export const onSubmit = async (params: any) => {
    log({
        config: params.config,
        title: 'Default Step Action',
        message: 'Ran onSubmit',
    })
}

const onAction = async (action: string, params: any) => {
    if (action === 'next') {
        await onNext(params)
    } else if (action === 'prev') {
        await onPrev(params)
    } else if (action === 'submit') {
        await onSubmit(params)
    }
}

export default onAction
