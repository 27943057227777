/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
// Packages
import React, { useEffect, useState, useContext } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'
import { QueryParamsContext } from '../contexts/QueryParams'
import { ConfigContext } from '../contexts/Config'
// Components
import ConditionalRender from '../components/ConditionalRender'
// UI
import Button from './Button'
import Card from './Card'
import TitleText2 from './TitleText2'
import TitleText1 from './TitleText1'
import TitleText3 from './TitleText3'
// Helpers
import getConfigData from '../helpers/config/getConfigData'

function BundleOfferCard({
    title,
    badge,
    rows,
    totalBundlePrice,
    numberYears,
    purpose = 'default',
    onClick,
    internetPlan,
}: {
    [key: string]: any
}) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [config]: any = useContext(ConfigContext)
    // States
    const [imageMap, setImageMap]: any = useState({})

    useEffect(() => {
        const cardImages: any = {}
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < rows.length; i++) {
            getConfigData('images.json', config.tenant, {
                formName: config.fromName,
                isMock: config?.isMock,
                environmentId: queryParams.environmentId,
                configId: queryParams?.configId ? queryParams.configId : config.deployment,
                configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
                imageName: `${rows[i].image}.png`,
            }).then((data: { [key: string]: any }) => {
                cardImages[rows[i].image] = data
            })
        }
        setImageMap(cardImages)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    if (Object.keys(styles).length === 0) {
        return null
    }

    switch (purpose) {
        case 'OriginalOfferCard':
            return (
                <Card className="py-[16px] px-[30px] border-[#00F]">
                    <ConditionalRender condition={badge}>
                        <TitleText3
                            className={styles.twMerge(
                                styles?.ui?.OfferCard?.Badge?.mobile,
                                styles?.ui?.OfferCard?.Badge?.tablet,
                                styles?.ui?.OfferCard?.Badge?.desktop
                            )}
                        >
                            {badge}
                        </TitleText3>
                    </ConditionalRender>
                    <TitleText1 className="text-[#00F] mb-[12px]">{title}</TitleText1>
                    {rows.map((row: any) => {
                        // console.log('row', row)
                        // console.log('IS USER PLAN:', row.title === 'UserInternetPlan')
                        return (
                            <div key={row.title} className="border-b flex flex-row justify-between mb-[8px]">
                                <div>
                                    {row.title === 'UserInternetPlan' ? `${internetPlan} Internet` : row.title}
                                    {/* {row.title} */}
                                    {row?.internetSubtractAmount ? (
                                        <TitleText2 className="">
                                            $
                                            {parseInt(row.price.replace('.00', '').replace('$', ''), 10) -
                                                // eslint-disable-next-line no-unsafe-optional-chaining
                                                row?.internetSubtractAmount}
                                            /mo.
                                        </TitleText2>
                                    ) : (
                                        // {parseInt(totalBundlePrice.replace('.00', '').replace('$', ''), 10) - 10}
                                        <TitleText2 className="">{row.price}</TitleText2>
                                    )}
                                </div>

                                {row.image === 'Mobile-SPCbundle' ? (
                                    <div className="w-[30px] mr-[4px]">
                                        <img className="object-cover" src={imageMap[row.image]?.url} alt={row.image} />
                                    </div>
                                ) : (
                                    <div className="w-[40px]">
                                        <img className="object-cover" src={imageMap[row.image]?.url} alt={row.image} />
                                    </div>
                                )}
                            </div>
                        )
                    })}
                    <div className="flex flex-row justify-between">
                        <div>
                            <div className="flex flex-row">
                                <TitleText1 className="text-[#00F] text-2xl font-[var(--Font-Name-Title,_Fieldwork)] font-semibold">
                                    {totalBundlePrice.replace('.00', '')}
                                </TitleText1>
                                <p className="mt-[4px]">/mo.</p>
                            </div>
                            <p>for {numberYears} year</p>
                        </div>
                        <Button className="w-36" type="ghost" onClick={onClick}>
                            Continue
                        </Button>
                    </div>
                </Card>
            )
        default:
            return (
                <Card className="py-[16px] px-[30px] my-[12px]">
                    <ConditionalRender condition={badge}>
                        <TitleText3 className="border-2 border-green-600 bg-green-500 mb-4 text-white w-fit rounded-full pt-1 px-4">
                            {badge}
                        </TitleText3>
                    </ConditionalRender>
                    <TitleText1 className="mb-[12px]">{title}</TitleText1>
                    {rows.map((row: any) => {
                        let imageClassName = 'w-[40px]'
                        if (row.image === 'Mobile-SPCbundle') {
                            imageClassName = 'w-[30px] mr-[4px]'
                        } else if (row.image === 'Xumo-Logo') {
                            imageClassName = 'w-[40px] mt-[8px]'
                        }
                        return (
                            <div key={row.title} className="border-b flex flex-row justify-between mb-[8px]">
                                <div>
                                    {row.title === 'UserInternetPlan' ? `${internetPlan} Internet` : row.title}
                                    <TitleText2 className="flex flex-row">
                                        {row.strikedPrice && (
                                            <p className="text-red-500 line-through mr-[3px]">{row.strikedPrice}</p>
                                        )}
                                        {row.price}
                                    </TitleText2>
                                </div>
                                <div className={imageClassName}>
                                    <img className="object-cover" src={imageMap[row.image]?.url} alt={row.image} />
                                </div>
                            </div>
                        )
                    })}
                    <div className="flex flex-row justify-between">
                        <div>
                            <div className="flex flex-row">
                                <TitleText1 className="text-[#00F] text-2xl font-[var(--Font-Name-Title,_Fieldwork)] font-semibold">
                                    {totalBundlePrice.replace('.00', '')}
                                </TitleText1>
                                <p className="mt-[4px]">/mo.</p>
                            </div>
                            <p>for {numberYears} years</p>
                        </div>
                        <Button className="w-36" type="fill" onClick={onClick}>
                            Select Bundle
                        </Button>
                    </div>
                </Card>
            )
    }
}

export default BundleOfferCard
