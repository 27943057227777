// Helpers
import getS3Bucket from '../supportData/getS3Bucket'

const getConfigData = async (
    url: string,
    tenant: string,
    {
        isMock,
        formName,
        environmentId,
        configEnvironment,
        configId,
        testUserId,
        instanceId,
        imageName,
    }: { [key: string]: any }
): Promise<Object> => {
    if (isMock) {
        const getMockData = await import(`./getMockConfig`)
        return getMockData.default(url, tenant, { formName, environmentId, testUserId, instanceId, imageName })
    }

    switch (url) {
        case 'config.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/config.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'paths.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/paths.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'steps.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/${formName}/steps.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'environment.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/${formName}/environment/${environmentId || 'default'}.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'copy.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/${formName}/copy.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'versions.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-latest/${tenant}/versions.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'styles.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/${formName}/styles.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'globalStyles.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/globalStyles.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'testUsers.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/testUsers/${testUserId}.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'instance.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/instances/${instanceId}.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'initialValues.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/${formName}/initialValues.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()

            return { ...data }
        }
        case 'favicon-apple': {
            return `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/assets/favicon/apple-touch-icon.png`
        }
        case 'favicon-16px': {
            return `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/assets/favicon/favicon-16x16.png`
        }
        case 'favicon-32px': {
            return `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/assets/favicon/favicon-32x32.png`
        }
        case 'images.json': {
            const response = await fetch(
                `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/assets/images/images.json`,
                {
                    method: 'GET',
                }
            )
            const data = await response.json()
            const imageKey = imageName?.split('.')?.[0]

            return {
                ...data?.[imageKey],
                url: `https://${getS3Bucket(configEnvironment)}.s3.us-east-1.amazonaws.com/${configEnvironment || 'prod'}-${configId || 'latest'}/${tenant}/assets/images/${data?.[imageKey]?.url}`,
            }
        }
        default:
            return {}
    }
}

export default getConfigData
