/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { ConfigContext } from '../../contexts/Config'

function SetTenant({ setIsLoaded }: { setIsLoaded: Function }) {
    // Contexts
    const [config, configDispatch]: any = useContext(ConfigContext)

    useEffect(() => {
        if (config?.loaded?.tenant) {
            setIsLoaded(true)
        }
    }, [config?.loaded?.tenant])

    useEffect(() => {
        const subDomainRegex = /([a-z0-9|-]+\.)*[a-z0-9|-]+\.[a-z]+/
        const domainsMatch = window.location.href.match(subDomainRegex)
        const domains = domainsMatch?.[0].split('.')
        const isLocalhost = window.location.href.match(/localhost:9000/)
        let localhostOffset = 0

        if (!isLocalhost) {
            localhostOffset = 1
        }

        if (domains?.length === 3 + localhostOffset) {
            if (domains?.[1] === 'dev' || domains?.[1] === 'test') {
                configDispatch({ type: 'SET_TENANT', payload: domains[0] })
            } else {
                configDispatch({ type: 'SET_TENANT', payload: domains[1] })
            }
        } else if (domains?.length === 4 + localhostOffset) {
            configDispatch({ type: 'SET_TENANT', payload: domains?.[1] })
        } else {
            configDispatch({ type: 'SET_TENANT', payload: domains?.[0] })
        }
    }, [])

    return null
}

export default SetTenant
