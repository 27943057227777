/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-console */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useEffect, useContext } from 'react'

// Contexts
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { PopupContext } from '../../contexts/Popup'
import { StylesContext } from '../../contexts/Styles'
// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import TitleText2 from '../../ui/TitleText2'
import Button from '../../ui/Button'
import BundleOfferCard from '../../ui/BundleOfferCard'
import Footer from '../../ui/Footer'
import Header from '../../ui/Header'
import BodyText4 from '../../ui/BodyText4'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'

// eslint-disable-next-line no-unused-vars
function BundleOffersBasic({ step, onNext, onPrev, buttons, progressBar }: any) {
    // Contexts
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [, popupDispatch]: any = useContext(PopupContext)
    const [styles]: any = useContext(StylesContext)

    useEffect(() => {
        window.scrollTo(0, 0)
        formDataDispatch({
            type: 'SET_FIELD',
            payload: { name: 'seenBundlePage', value: true },
        })
    }, [])

    const handleBundleSelection = (bundle: any) => {
        if (bundle) {
            formDataDispatch({
                type: 'SET_FIELD',
                payload: { name: 'selectedBundle', value: bundle },
            })
        } else {
            formDataDispatch({
                type: 'SET_FIELD',
                payload: { name: 'selectedBundle', value: false },
            })
        }
        onNext()
    }

    return (
        <Page>
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="bundlesBasicHeader">
                            <StyleCopy>{copy?.steps?.bundleOffersBasic?.pageTitle}</StyleCopy>
                        </Heading>
                        <SubHeading name="bundlesBasicSubHeader">
                            <StyleCopy>{copy?.steps?.bundleOffersBasic?.pageSubHeader}</StyleCopy>
                        </SubHeading>
                        <BundleOfferCard
                            title="Your selected offer"
                            purpose="OriginalOfferCard"
                            totalBundlePrice={formData?.selectedPlanStrikePrice}
                            numberYears={1}
                            onClick={() => handleBundleSelection(false)}
                            rows={[
                                {
                                    title: `${formData.selectedPlan} Internet`,
                                    price: `${formData?.selectedPlanStrikePrice}/mo`,
                                    image: 'Internet-SPCbundle',
                                    internetSubtractAmount: 10,
                                },
                                { title: '1 Unlimited Line', price: 'Included', image: 'Mobile-SPCbundle' },
                                { title: 'Advanced Wifi + Modem', price: '$10/mo', image: 'Wifi-SPCbundle' },
                            ]}
                        />
                        <hr className="mt-[16px] border-0 bg-zinc-500 h-px" />
                        <Heading name="bundlesBasicHeader">
                            <StyleCopy>{copy?.steps?.bundleOffersBasic?.moreSavingsTitle}</StyleCopy>
                        </Heading>
                        <SubHeading name="bundlesBasicSubHeader">
                            <StyleCopy>{copy?.steps?.bundleOffersBasic?.moreSavingsSubHeader}</StyleCopy>
                        </SubHeading>
                        {/* Map over the copy for the BundleOfferCards. For now use the Temp State */}
                        {copy?.steps?.bundleOffersBasic?.bundles[formData?.selectedPlan].items.map((offer: any) => {
                            // console.log('OFFER ROW DATA', offer.rowData)
                            return (
                                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                                <BundleOfferCard
                                    key={offer.key}
                                    title={offer.title}
                                    badge={offer.badge}
                                    totalBundlePrice={offer.totalBundlePrice}
                                    numberYears={offer.numberYears}
                                    rows={offer.rowData}
                                    imageString={offer.image}
                                    onClick={() => handleBundleSelection(offer)}
                                    internetPlan={formData?.selectedPlan}
                                />
                            )
                        })}
                        <Button
                            type="link"
                            className={styles.twMerge(
                                styles?.steps?.offersBasic?.disclaimer?.mobile,
                                styles?.steps?.offersBasic?.disclaimer?.tablet,
                                styles?.steps?.offersBasic?.disclaimer?.desktop
                            )}
                            name={`${formData?.topServiceableCompany}OffersBasicDisclaimer`}
                            onClick={() => {
                                popupDispatch({
                                    type: 'ADD_POPUP',
                                    payload: {
                                        name: 'spectrumBundlesBasicDisclaimer',
                                        componentToRender: 'spectrumBundlesBasicDisclaimer',
                                    },
                                })
                            }}
                        >
                            <TitleText2>
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                            ?.disclaimerText,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.notAcpTransferable?.offersBasic,
                                        }
                                    )}
                                </StyleCopy>
                            </TitleText2>
                        </Button>
                        <TitleText2
                            name="offersBasicNeedHelper"
                            className={styles.twMerge(
                                styles?.steps?.offersBasicNote?.needHelp?.mobile,
                                styles?.steps?.offersBasicNote?.needHelp?.tablet,
                                styles?.steps?.offersBasicNote?.needHelp?.desktop
                            )}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.bundleOffersBasic?.needHelp, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.notAcpTransferable?.offersBasic,
                                })}
                            </StyleCopy>
                        </TitleText2>
                        <BodyText4
                            name="offersBasicNote"
                            className={styles.twMerge(
                                styles?.steps?.offersBasicNote?.note?.mobile,
                                styles?.steps?.offersBasicNote?.note?.tablet,
                                styles?.steps?.offersBasicNote?.note?.desktop
                            )}
                        >
                            <StyleCopy>
                                {parseCopy(
                                    copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                        ?.note,
                                    {
                                        config,
                                        formData,
                                        copy: copy?.steps?.notAcpTransferable?.offersBasic,
                                    }
                                )}
                            </StyleCopy>
                        </BodyText4>
                    </Main>
                </div>
                <Footer />
            </Container>
        </Page>
    )
}

export default BundleOffersBasic
