/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState, useRef } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
// import Button from '../../ui/Button'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { StylesContext } from '../../contexts/Styles'
import { QueryParamsContext } from '../../contexts/QueryParams'
import { StepsContext } from '../../contexts/Steps'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import getConfigData from '../../helpers/config/getConfigData'
import makeBackendRequest from '../../helpers/backend'

function EndFallbackIbex() {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const useEffectBlocker = useRef(false)
    // States
    const [image, setImage]: any = useState({})

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'FcFreedaOnComputerWithGreenBackground.png',
        }).then((data: { [key: string]: any }) => {
            setImage(data)
        })
    }, [])

    useEffect(() => {
        if (useEffectBlocker.current) return

        makeBackendRequest(
            { stack: 'ses', endpoint: 'sendToSqs' },
            {
                config,
                formData,
                body: {
                    use_case: 'generic_app_received',
                    recipients_email: formData.email,
                    cnxId: formData.connexID,
                },
            }
        )
        useEffectBlocker.current = true

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Page>
            <Container
                className={styles.twMerge(
                    styles?.steps?.endFallbackIbex?.Container?.mobile,
                    styles?.steps?.endFallbackIbex?.Container?.tablet,
                    styles?.steps?.endFallbackIbex?.Container?.desktop
                )}
            >
                <img
                    className={styles.twMerge(
                        styles?.steps?.endFallbackIbex?.img?.mobile,
                        styles?.steps?.endFallbackIbex?.img?.tablet,
                        styles?.steps?.endFallbackIbex?.img?.desktop
                    )}
                    src={image.url}
                    alt={image.alt}
                />
                <Heading
                    className={styles.twMerge(
                        styles?.steps?.orderCompleteBasic?.Heading?.mobile,
                        styles?.steps?.orderCompleteBasic?.Heading?.tablet,
                        styles?.steps?.orderCompleteBasic?.Heading?.desktop
                    )}
                    name="endFallbackBasicHeader"
                >
                    <StyleCopy>{parseCopy(copy?.steps?.endFallbackIbex?.title, { config, formData })}</StyleCopy>
                </Heading>
                <SubHeading
                    className={styles.twMerge(
                        styles?.steps?.orderCompleteBasic?.SubHeading?.mobile,
                        styles?.steps?.orderCompleteBasic?.SubHeading?.tablet,
                        styles?.steps?.orderCompleteBasic?.SubHeading?.desktop
                    )}
                    name="endFallbackBasicSubHeader"
                >
                    <StyleCopy>
                        {parseCopy(copy?.steps?.endFallbackIbex?.subHeader, {
                            config,
                            formData,
                        })}
                    </StyleCopy>
                </SubHeading>
                {/* <Button
                    className={
                        config?.advertisingPlatform === 'google'
                            ? styles.twMerge(
                                  `${styles?.steps?.endFallbackIbex?.Button?.mobile} unserviceable-lead-creation`,
                                  `${styles?.steps?.endFallbackIbex?.Button?.tablet} unserviceable-lead-creation`,
                                  `${styles?.steps?.endFallbackIbex?.Button?.desktop} unserviceable-lead-creation`
                              )
                            : styles.twMerge(
                                  `${styles?.steps?.endFallbackIbex?.Button?.mobile}`,
                                  `${styles?.steps?.endFallbackIbex?.Button?.tablet}`,
                                  `${styles?.steps?.endFallbackIbex?.Button?.desktop}`
                              )
                    }
                    type="ghostWhite"
                    name="endFallbackBasicCallButton"
                    onClick={() => {
                        window.open(
                            `${parseCopy(copy?.steps?.endFallbackIbex?.callNowButton?.url, { config, formData })}`
                        )
                    }}
                >
                    <StyleCopy>
                        {parseCopy(copy?.steps?.endFallbackIbex?.callNowButton?.text, {
                            config,
                            formData,
                        })}
                    </StyleCopy>
                </Button> */}
            </Container>
        </Page>
    )
}

export default EndFallbackIbex
