/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Helpers
import makeBackendRequest from '../helpers/backend'
// Contexts
import { QueryParamsContext } from '../contexts/QueryParams'
import { FormDataContext } from '../contexts/FormData'
import { ConfigContext } from '../contexts/Config'

function DemoFormComplete() {
    // Contexts
    const [queryParams]: any = useContext(QueryParamsContext)
    const [formData]: any = useContext(FormDataContext)
    const [config]: any = useContext(ConfigContext)

    useEffect(() => {
        if (formData?.isDemoformComplete && formData?.topServiceableCompany !== 'optimum') {
            makeBackendRequest({ stack: 'manychat', endpoint: 'submit' }, { formData, config, queryParams })
        }
    }, [formData?.isDemoformComplete])

    return null
}

export default DemoFormComplete
