/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import BodyText5 from '../../ui/BodyText5'
import Header from '../../ui/Header'
import Footer from '../../ui/Footer'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { QueryParamsContext } from '../../contexts/QueryParams'
import { StylesContext } from '../../contexts/Styles'
import { StepsContext } from '../../contexts/Steps'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import getConfigData from '../../helpers/config/getConfigData'

function AcpTransferable({ progressBar }: any) {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData]: any = useContext(FormDataContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [styles]: any = useContext(StylesContext)
    // States
    const [logo, setLogo]: any = useState({})

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'OptimumSuccess.png',
        }).then((data: { [key: string]: any }) => {
            setLogo(data)
        })
    }, [])
    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    return (
        <Page>
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="successOptimumAssetAcpHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.successOptimumAsset?.acpTransferable?.title, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.successOptimumAsset?.acpTransferable,
                                })}
                            </StyleCopy>
                        </Heading>
                        <SubHeading name="successOptimumAssetAcpSubHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.successOptimumAsset?.acpTransferable?.subHeader, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.successOptimumAsset?.acpTransferable,
                                })}
                            </StyleCopy>
                        </SubHeading>
                        <img
                            className={styles.twMerge(
                                styles?.steps?.successOptimumAssetAcp?.img?.mobile,
                                styles?.steps?.successOptimumAssetAcp?.img?.tablet,
                                styles?.steps?.successOptimumAssetAcp?.img?.desktop
                            )}
                            src={logo.url}
                            alt={logo.alt}
                        />
                        <BodyText5
                            className={styles.twMerge(
                                styles?.steps?.successOptimumAssetAcp?.footerTitle?.mobile,
                                styles?.steps?.successOptimumAssetAcp?.footerTitle?.tablet,
                                styles?.steps?.successOptimumAssetAcp?.footerTitle?.desktop
                            )}
                            name="successOptimumAssetAcpFooterTitle"
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.successOptimumAsset?.acpTransferable?.footerText?.title, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.successOptimumAsset?.acpTransferable,
                                })}
                            </StyleCopy>
                        </BodyText5>
                        <div
                            className={styles.twMerge(
                                styles?.steps?.successOptimumAssetAcp?.disclaimerContainer?.mobile,
                                styles?.steps?.successOptimumAssetAcp?.disclaimerContainer?.tablet,
                                styles?.steps?.successOptimumAssetAcp?.disclaimerContainer?.desktop
                            )}
                        >
                            <BodyText5 name="successOptimumAssetAcpFooterOne">
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.successOptimumAsset?.acpTransferable?.footerText?.disclaimerOne,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.successOptimumAsset?.acpTransferable,
                                        }
                                    )}
                                </StyleCopy>
                            </BodyText5>
                            <BodyText5 name="successOptimumAssetAcpFooterTwo">
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.successOptimumAsset?.acpTransferable?.footerText?.disclaimerTwo,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.successOptimumAsset?.acpTransferable,
                                        }
                                    )}
                                </StyleCopy>
                            </BodyText5>
                        </div>
                    </Main>
                </div>
                <Footer />
            </Container>
        </Page>
    )
}

export default AcpTransferable
