/* eslint-disable no-unused-vars */
// Helpers
import makeBackendRequest from '../backend'
import { log } from '../logging'

export const onNext = async (params: any) => {
    log({
        config: params.config,
        title: 'Default Step Action',
        message: 'Ran onNext',
    })

    const copyObject = params?.copy?.steps
    makeBackendRequest(
        { stack: 'orderSubmission', endpoint: 'main' },
        { formData: params.formData, config: params.config, queryParams: params.queryParams, body: copyObject }
    )
    if (params.formData?.phone || params.formData?.email) {
        const response = await makeBackendRequest(
            { stack: 'connexOne', endpoint: 'processDemoform' },
            {
                formData: params.formData,
                config: params.config,
                queryParams: params.queryParams,
                cnxFifoQueue: params.cnxFifoQueue,
            }
        )
    }
    const manychatResponse = await makeBackendRequest(
        { stack: 'manychat', endpoint: 'set' },
        { formData: params.formData, config: params.config, queryParams: params.queryParams }
    )
}
export const onPrev = async (params: any) => {
    log({
        config: params.config,
        title: 'Default Step Action',
        message: 'Ran onPrev',
    })
}
export const onSubmit = async (params: any) => {
    log({
        config: params.config,
        title: 'Default Step Action',
        message: 'Ran onSubmit',
    })
    onNext(params)
}

const onAction = async (action: string, params: any) => {
    if (action === 'next') {
        await onNext(params)
    } else if (action === 'prev') {
        await onPrev(params)
    } else if (action === 'submit') {
        await onSubmit(params)
    }
}

export default onAction
