const getS3Bucket = (environment: string) => {
    switch (environment) {
        case 'dev':
            return 'demoform-config-407696052621'
        case 'test':
            return 'demoform-config-362761060802'
        default:
            return 'demoform-config-429372903372'
    }
}

export default getS3Bucket
