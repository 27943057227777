// Packages
import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
// Core
import GenerateRoutes from './GenerateRoutes'
import SetUpQueryParameters from './SetUpQueryParameters'
// Components
import ConditionalRender from '../components/ConditionalRender'
// Helpers
import isInitialLoadingDone from '../helpers/loading'
// Contexts
import { InitialLoadBlockersContext } from '../contexts/InitialLoadBlockers'
// UI
import LoadingBlocker from '../ui/LoadingBlocker'

function Routers() {
    // Contexts
    const [initialLoadBlockers]: any = useContext(InitialLoadBlockersContext)
    // States
    const [isDoneLoading, setIsDoneLoading] = useState(false)

    useEffect(() => {
        setIsDoneLoading(isInitialLoadingDone(initialLoadBlockers))
    }, [initialLoadBlockers])

    return (
        <Router>
            <SetUpQueryParameters />
            <ConditionalRender falseReturn={<LoadingBlocker />} condition={isDoneLoading}>
                <GenerateRoutes />
            </ConditionalRender>
        </Router>
    )
}

export default Routers
