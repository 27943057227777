/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
// Packages
import React, { useContext, useEffect, useState } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
// Helpers
import getConfigData from '../helpers/config/getConfigData'

function Header({ className = '', imgClassName = '' }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [config]: any = useContext(ConfigContext)
    // States
    const [logo, setLogo]: any = useState({})

    useEffect(() => {
        getConfigData('images.json', config.tenant, {
            formName: config.fromName,
            isMock: config?.isMock,
            environmentId: queryParams.environmentId,
            configId: queryParams?.configId ? queryParams.configId : config.deployment,
            configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            imageName: 'WhiteFreeConnectLogo.svg',
        }).then((data: { [key: string]: any }) => {
            setLogo(data)
        })
    }, [])

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <header
            className={styles.twMerge(
                styles?.ui?.Header?.header?.mobile,
                styles?.ui?.Header?.header?.tablet,
                styles?.ui?.Header?.header?.desktop,
                className
            )}
        >
            <img
                className={styles.twMerge(
                    styles?.ui?.Header?.img?.mobile,
                    styles?.ui?.Header?.img?.tablet,
                    styles?.ui?.Header?.img?.desktop,
                    imgClassName
                )}
                src={logo.url}
                alt={logo.alt}
            />
        </header>
    )
}

export default Header
