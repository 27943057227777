// Packages
import React, { useState } from 'react'
// Forms
import BasicForm from '../BasicForm'

function InterimFormP3({ config }: any) {
    const [formName] = useState(config || 'interimformp3')

    return <BasicForm config={formName} />
}

export default InterimFormP3
