/* eslint-disable prefer-template */
/* eslint-disable jsx-a11y/label-has-associated-control */
// Packages
import React, { useState } from 'react'

interface Properties {
    className?: string
    onCheck?: any
    initialValue?: boolean
    bgCheck?: any
}

// eslint-disable-next-line no-unused-vars
function Switch({ className = '', onCheck = () => {}, initialValue = false, bgCheck = () => {} }: Properties) {
    const [checked, setChecked] = useState(initialValue)

    return (
        <div>
            <input
                type="checkbox"
                className={
                    'toggle border-grey bg-white [--tglbg:grey] hover:bg-white checked:border-blue-700 checked:bg-white  checked:[--tglbg:blue]  ' +
                    className
                }
                checked={checked}
                onClick={() => {
                    onCheck(!checked)
                    setChecked((prev: any) => !prev)
                    bgCheck(!checked)
                }}
            />
        </div>
    )
}

Switch.defaultProps = {
    className: '',
    onCheck: () => {},
    initialValue: false,
    bgCheck: () => {},
}

export default Switch
