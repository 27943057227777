// Packages
import React, { useState } from 'react'
// Forms
import BasicForm from '../BasicForm'

function SpecNo50offer({ config }: any) {
    const [formName] = useState(config || 'SpecNo50offer')

    return <BasicForm config={formName} />
}

export default SpecNo50offer
