/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect, useState, useRef } from 'react'
import moment from 'moment'
// Contexts
import { StepsContext } from '../contexts/Steps'
import { ValidationContext } from '../contexts/Validation'
import { FormDataContext } from '../contexts/FormData'
import { CopyContext } from '../contexts/Copy'
import { ConfigContext } from '../contexts/Config'
import { LoadingBlockersContext } from '../contexts/LoadingBlockers'
import { DataFunnelContext } from '../contexts/DataFunnel'
import { QueryParamsContext } from '../contexts/QueryParams'
import { CnxFifoQueueContext } from '../contexts/CnxFifoQueue'
// Components
import ConditionalRender from './ConditionalRender'
// UI
import Button from '../ui/Button'
import ProgressBar from '../ui/ProgressBar'
import StepperButtonContainer from '../ui/StepperButtonContainer'
// Helpers
import validate from '../helpers/validation/main'
import { log } from '../helpers/logging'
import makeBackendRequest from '../helpers/backend'

function Stepper() {
    // Contexts
    const [steps, stepsDispatch]: any = useContext(StepsContext)
    const [validation, validationDispatch]: any = useContext(ValidationContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [, loadingBlockersDispatch]: any = useContext(LoadingBlockersContext)
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [, cnxFifoQueue]: any = useContext(CnxFifoQueueContext)
    // States
    const [runningOnNext, setRunningOnNext]: any = useState({})
    const [runOnNext, setRunOnNext]: any = useState(0)
    // Refs
    const topOfStep: any = useRef(null)

    useEffect(() => {
        const asyncWrapper = async () => {
            if (formData?.isDemoformComplete) {
                loadingBlockersDispatch({ type: 'ADD_BLOCKER', payload: 'demoformCompleteCall' })
                setTimeout(() => {
                    makeBackendRequest(
                        { stack: 'connexOne', endpoint: 'processDemoform' },
                        { formData, config, queryParams, cnxFifoQueue }
                    ).then(() => {
                        loadingBlockersDispatch({ type: 'REMOVE_BLOCKER', payload: 'demoformCompleteCall' })
                    })
                }, 500)
            }
        }

        asyncWrapper()
    }, [formData?.isDemoformComplete])

    useEffect(() => {
        const asyncWrapper = async () => {
            if (
                steps?.main?.[runOnNext?.i]?.waitFor &&
                formData?.[steps?.main?.[runOnNext?.i]?.waitFor] !== undefined &&
                runOnNext !== null
            ) {
                setRunOnNext(null)
                if (runOnNext.i === steps.main.length - 1) {
                    await runOnNext.onAction('submit', {
                        formData,
                        formDataDispatch,
                        steps,
                        stepsDispatch,
                        config,
                        cnxFifoQueue,
                    })
                } else {
                    stepsDispatch({ type: 'INCREMENT_CURRENT_STEP', payload: runOnNext.x })
                    await runOnNext.onAction('next', {
                        formData,
                        formDataDispatch,
                        steps,
                        stepsDispatch,
                        config,
                        copy,
                        cnxFifoQueue,
                    })
                }
                dataFunnelDispatch({
                    type: 'SET_PAGE_SUBMITTED',
                    payload: { key: runOnNext.name, value: true },
                })
                loadingBlockersDispatch({ type: 'REMOVE_BLOCKER', payload: runOnNext.name })
            }
        }

        asyncWrapper()
    }, [formData, runOnNext])

    useEffect(() => {
        log({
            config,
            title: 'Steps Update',
            message: [`Current Step: ${steps.currentStep}`, `Steps: ${JSON.stringify(steps.main, null, 2)}`],
        })
        if (steps?.main?.[steps?.currentStep]?.isEnd) {
            dataFunnelDispatch({
                type: 'FORM_COMPLETE',
                payload: moment(Date.now())
                    .tz('America/Los_Angeles')
                    .format()
                    .replace('T', ' ')
                    .replace('-08:00', '.000')
                    .replace('-07:00', '.000'),
            })
        }

        if (
            steps?.main?.[steps?.currentStep]?.isCompleted &&
            formData?.isDemoformComplete === undefined &&
            formData?.phoneNumber !== undefined
        ) {
            formDataDispatch({
                type: 'SET_FIELD',
                payload: {
                    name: 'isDemoformComplete',
                    value: true,
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [steps.currentStep])

    return steps.main.map(
        (
            {
                name,
                component,
                disableButtons,
                disableNextButton,
                disablePreviousButton,
                onAction,
                progressBarPercent,
                disableProgressBar,
            }: {
                name: string
                component: React.ReactElement
                disableButtons: boolean
                disableNextButton: boolean
                disablePreviousButton: boolean
                onAction: Function
                progressBarPercent: Number
                disableProgressBar: boolean
            },
            i: number
        ) => {
            const onNext = async (x = 1) => {
                if (!runningOnNext[name]) {
                    loadingBlockersDispatch({ type: 'ADD_BLOCKER', payload: name })
                    setRunningOnNext((prev: { [key: string]: boolean }) => ({ ...prev, [name]: true }))
                    const { isValid, errorMessages, metaDatas } = await validate(
                        i,
                        formData,
                        validation?.steps?.[i.toString()],
                        formDataDispatch
                    )
                    validationDispatch({ type: 'ADD_ERROR', payload: { errorMessages, metaDatas } })

                    if (isValid) {
                        if (steps?.main?.[i]?.waitFor) {
                            setRunOnNext({ name, onAction, x, i })
                        } else {
                            if (i === steps.main.length - 1) {
                                await onAction('submit', {
                                    formData,
                                    formDataDispatch,
                                    steps,
                                    stepsDispatch,
                                    config,
                                    queryParams,
                                    cnxFifoQueue,
                                })
                            } else {
                                stepsDispatch({ type: 'INCREMENT_CURRENT_STEP', payload: x })
                                await onAction('next', {
                                    formData,
                                    formDataDispatch,
                                    steps,
                                    stepsDispatch,
                                    config,
                                    copy,
                                    queryParams,
                                    cnxFifoQueue,
                                })
                            }
                            dataFunnelDispatch({
                                type: 'SET_PAGE_SUBMITTED',
                                payload: { key: name, value: true },
                            })
                        }
                    }

                    setRunningOnNext((prev: { [key: string]: boolean }) => ({ ...prev, [name]: false }))
                    if (!steps?.main?.[i]?.waitFor || !isValid) {
                        loadingBlockersDispatch({ type: 'REMOVE_BLOCKER', payload: name })
                    }
                }
            }
            const onPrev = async (x = 1) => {
                stepsDispatch({ type: 'DECREMENT_CURRENT_STEP', payload: x })
                await onAction('prev', {
                    formData,
                    formDataDispatch,
                    steps,
                    stepsDispatch,
                    config,
                    copy,
                    queryParams,
                    cnxFifoQueue,
                })
            }

            return (
                <ConditionalRender key={`${name}`} condition={steps.currentStep === i}>
                    <div ref={topOfStep} />
                    {React.cloneElement(component, {
                        step: i,
                        onNext,
                        onPrev,
                        buttons: (
                            <ConditionalRender condition={!disableButtons}>
                                <StepperButtonContainer>
                                    <ConditionalRender condition={!disableNextButton}>
                                        <Button
                                            type="fill"
                                            name={`${name}Next`}
                                            onClick={async () => {
                                                topOfStep.current.scrollIntoView({
                                                    behavior: 'smooth',
                                                })
                                                await onNext()
                                            }}
                                        >
                                            {steps.currentStep === steps.main.length - 1
                                                ? copy?.steps?.[name]?.submitButton
                                                : copy?.steps?.[name]?.nextButton}
                                        </Button>
                                    </ConditionalRender>
                                    <ConditionalRender condition={steps.currentStep !== 0 && !disablePreviousButton}>
                                        <Button
                                            type="ghost"
                                            name={`${name}Prev`}
                                            onClick={async () => {
                                                await onPrev()
                                            }}
                                        >
                                            {copy?.steps?.[name]?.previousButton}
                                        </Button>
                                    </ConditionalRender>
                                </StepperButtonContainer>
                            </ConditionalRender>
                        ),
                        progressBar: (
                            <ConditionalRender condition={!disableProgressBar}>
                                <ProgressBar percent={progressBarPercent} />
                            </ConditionalRender>
                        ),
                    })}
                </ConditionalRender>
            )
        }
    )
}

export default Stepper
