const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000'
        case 'dev':
            return 'https://lboyueuzli.execute-api.us-east-1.amazonaws.com/prod'
        case 'test':
            return 'https://g4kb6ds806.execute-api.us-east-1.amazonaws.com/prod'
        default:
            return 'https://fpqo866loe.execute-api.us-east-1.amazonaws.com/prod'
    }
}

const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        case 'dev':
            return process.env.REACT_APP_DEV_CNX1_KEY ? process.env.REACT_APP_DEV_CNX1_KEY : ''
        case 'test':
            return process.env.REACT_APP_TEST_CNX1_KEY ? process.env.REACT_APP_TEST_CNX1_KEY : ''
        default:
            return process.env.REACT_APP_PROD_CNX1_KEY ? process.env.REACT_APP_PROD_CNX1_KEY : ''
    }
}

const zipIncomeCheck = async (request: { config: any; formData: any }) => {
    return fetch(`${getUrl(request.config.environment.connexOne)}/check-zip-level`, {
        method: 'POST',
        body: JSON.stringify({
            address: {
                zip_code: request?.formData?.addressZip,
            },
        }),
        headers: {
            'x-api-key': getApiKey(request.config.environment.connexOne),
            company: request?.formData.topServiceableCompany,
            Authorization: request.config.tenant,
        },
    })
}

const endpoints: { [key: string]: Function } = {
    main: zipIncomeCheck,
}

const ZipIncomeChecker = async (endpoint: string, request: { config: any; formData: any }) => {
    return endpoints[endpoint](request)
}

export default ZipIncomeChecker
