/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
// Packages
import React, { useState, useEffect, useContext } from 'react'

// UI
import TextField from '../TextField'
import Dropdown from '../Dropdown'
import Button from '../Button'
import BodyText2 from '../BodyText2'
import BodyText4 from '../BodyText4'
// Contexts
import { ValidationContext } from '../../contexts/Validation'
import { ConfigContext } from '../../contexts/Config'
import { StylesContext } from '../../contexts/Styles'
// Helpers
import usStates from '../../helpers/supportData/usStates'
import { querySmartyAutoComplete } from '../../helpers/smarty'
import { capitalizeFirstLetter } from '../../helpers/string'

function AddressField({
    step,
    street,
    secondary,
    city,
    state,
    zip,
    name,
    validationFunctions,
}: {
    [key: string]: any
}) {
    // Contexts
    const [, validationDispatch]: any = useContext(ValidationContext)
    const [config]: any = useContext(ConfigContext)
    const [styles]: any = useContext(StylesContext)
    // States
    const [createdValidation, setCreatedValidation] = useState(false)
    const [streetState, setStreetState] = useState(street.initialValue)
    const [secondaryState, setSecondaryState] = useState(secondary.initialValue)
    const [selectedAddress, setSelectedAddress]: any = useState({})
    const [lineOneSuggestions, setLineOneSuggestions]: any = useState([])
    const [lineTwoSuggestions, setLineTwoSuggestions]: any = useState([])
    const [disableLineOneSuggestions, setDisableLineOneSuggestions]: any = useState(true)
    const [disableLineTwoSuggestions, setDisableLineTwoSuggestions]: any = useState(true)
    const [clickedSecondaryAddress, setClickedSecondaryAddress]: any = useState(false)
    const [secondaryDesignator, setSecondaryDesignator]: any = useState('')

    useEffect(() => {
        if (!config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}TextField`]) {
            if ((step || step === 0) && name && !createdValidation) {
                validationDispatch({
                    type: 'ADD_FIELD',
                    payload: {
                        step,
                        name,
                        formDataKeys: {
                            street: street.formDataKey,
                            secondary: secondary.formDataKey,
                            city: city.formDataKey,
                            state: state.formDataKey,
                            zip: zip.formDataKey,
                        },
                        functions: validationFunctions,
                    },
                })
                setCreatedValidation(true)
            }
        }
    }, [
        city.formDataKey,
        city.name,
        config?.tenantConfig?.featureFlags,
        createdValidation,
        name,
        secondary.formDataKey,
        secondary.name,
        state.formDataKey,
        state.name,
        step,
        street.formDataKey,
        street.name,
        validationFunctions,
        zip.formDataKey,
        zip.name,
    ])

    useEffect(() => {
        const asyncWrapper = async () => {
            if (streetState) {
                const suggestions = await querySmartyAutoComplete(streetState)
                setLineOneSuggestions(suggestions.slice(0, 5))
            } else {
                setLineOneSuggestions([])
                setDisableLineOneSuggestions(true)
            }
        }

        asyncWrapper()
    }, [streetState])

    useEffect(() => {
        const asyncWrapper = async () => {
            if (secondaryState?.secondary && secondaryState?.secondary !== -1) {
                const suggestions = await querySmartyAutoComplete({
                    ...selectedAddress,
                    ...secondaryState,
                })
                if (suggestions.length === 0) {
                    setDisableLineTwoSuggestions(true)
                } else if (
                    suggestions.some(
                        (suggestion) =>
                            suggestion.secondary !== '' &&
                            suggestion.streetLine.toLowerCase() === streetState.toLowerCase()
                    )
                ) {
                    setDisableLineTwoSuggestions(false)
                } else {
                    setDisableLineTwoSuggestions(true)
                }
                setLineTwoSuggestions(suggestions.slice(0, 5))
            } else {
                setLineTwoSuggestions([])
                setDisableLineTwoSuggestions(true)
            }
        }

        asyncWrapper()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [secondaryState])

    useEffect(() => {
        if (selectedAddress.street) {
            setDisableLineOneSuggestions(true)
        }
    }, [selectedAddress])
    useEffect(() => {
        if (clickedSecondaryAddress) {
            setDisableLineTwoSuggestions(true)
        }
    }, [clickedSecondaryAddress])

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}TextField`]) {
        return null
    }

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <>
            <TextField
                step={step}
                autoComplete="off"
                placeholder="123 Main St"
                disableOnChangeValidation
                validationFunctions={street.validation}
                formDataKey={street.formDataKey}
                name={street.name}
                subLabel={street.subLabelCopy}
                initialValue={street.initialValue}
                setValueTo={selectedAddress.street}
                onChange={(value: any, fromSetValueTo = false) => {
                    setStreetState(value)
                    street.onChange(value)
                    setDisableLineOneSuggestions(false)
                    // setDisableLineTwoSuggestions(false)
                    setClickedSecondaryAddress(false)
                    validationDispatch({
                        type: 'ADD_ERROR',
                        payload: {
                            errorMessages: {
                                [street.formDataKey]: '',
                                [secondary.formDataKey]: '',
                                [city.formDataKey]: '',
                                [state.formDataKey]: '',
                                [zip.formDataKey]: '',
                            },
                        },
                    })
                    if (!fromSetValueTo) {
                        setSelectedAddress({
                            city: -1,
                            zip: -1,
                            state: -1,
                        })
                        setSecondaryDesignator('')
                        setSecondaryState({ secondary: -1 })
                        setDisableLineTwoSuggestions(true)
                    }
                }}
                disableDropdown={disableLineOneSuggestions}
                dropdown={
                    <div
                        className={styles.twMerge(
                            styles?.ui?.AddressField?.streetDropdown?.mobile,
                            styles?.ui?.AddressField?.streetDropdown?.tablet,
                            styles?.ui?.AddressField?.streetDropdown?.desktop
                        )}
                    >
                        {lineOneSuggestions.map((suggestion: any) => {
                            const address = `${suggestion.streetLine}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}`
                            const id = `${suggestion.streetLine}${suggestion?.secondary ? `, ${suggestion.secondary}` : ''}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}${suggestion?.entries ? `, ${suggestion.entries}` : ''}`
                            return (
                                <Button
                                    type="textFieldDropdown"
                                    name={address}
                                    key={id}
                                    onClick={() => {
                                        setSelectedAddress({
                                            street: suggestion.streetLine,
                                            city: suggestion.city,
                                            state: suggestion.state,
                                            zip: suggestion.zipcode,
                                        })
                                        if (suggestion.secondary) {
                                            setSecondaryDesignator(`${suggestion.secondary} `)
                                            setSecondaryState({
                                                secondary: `${suggestion.secondary} `,
                                                entries: 5,
                                            })
                                            setDisableLineTwoSuggestions(false)
                                        } else {
                                            setDisableLineTwoSuggestions(true)
                                        }
                                    }}
                                >
                                    <svg
                                        className={styles.twMerge(
                                            styles?.ui?.AddressField?.streetDropdownSvg?.mobile,
                                            styles?.ui?.AddressField?.streetDropdownSvg?.tablet,
                                            styles?.ui?.AddressField?.streetDropdownSvg?.desktop,
                                            styles?.ui?.AddressField?.streetDropdownSvg?.hover
                                        )}
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx="13" cy="13" r="13" fill="#F4F4F5" />
                                        <path
                                            d="M12.9997 4C9.68607 4 7 6.7966 7 10.2466C7 10.8394 7.08126 11.4124 7.22936 11.9554C7.32041 12.2902 7.42069 12.622 7.57801 12.9226C8.80838 15.268 12.9997 21.9994 12.9997 21.9994C12.9997 21.9994 17.1916 15.2674 18.422 12.9226C18.5793 12.622 18.679 12.2902 18.7706 11.9554C18.9187 11.4118 19 10.8394 19 10.2466C19 6.7966 16.3134 4 12.9997 4ZM12.9997 12.9976C12.1629 12.9976 11.4161 12.5902 10.932 11.956C10.573 11.4862 10.358 10.8922 10.358 10.2472C10.358 8.7304 11.5434 7.4968 13.0003 7.4968C14.4571 7.4968 15.642 8.731 15.642 10.2472C15.642 10.8928 15.427 11.4868 15.068 11.956C14.5833 12.5902 13.8371 12.9976 13.0003 12.9976H12.9997Z"
                                            fill="#151515"
                                        />
                                    </svg>
                                    <div
                                        className={styles.twMerge(
                                            styles?.ui?.AddressField?.streetDropdownItem?.mobile,
                                            styles?.ui?.AddressField?.streetDropdownItem?.tablet,
                                            styles?.ui?.AddressField?.streetDropdownItem?.desktop,
                                            styles?.ui?.AddressField?.streetDropdownItem?.hover
                                        )}
                                    >
                                        <BodyText2>{suggestion.streetLine}</BodyText2>
                                        <BodyText4
                                            className={styles.twMerge(
                                                styles?.ui?.AddressField?.streetDropdownSmallItem?.mobile,
                                                styles?.ui?.AddressField?.streetDropdownSmallItem?.tablet,
                                                styles?.ui?.AddressField?.streetDropdownSmallItem?.desktop,
                                                styles?.ui?.AddressField?.streetDropdownSmallItem?.hover
                                            )}
                                        >{`${suggestion?.city ? `${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}`}</BodyText4>
                                    </div>
                                </Button>
                            )
                        })}
                    </div>
                }
            >
                {street.labelCopy}
            </TextField>
            <TextField
                step={step}
                autoComplete="off"
                placeholder="Apt 123"
                disableOnChangeValidation
                validationFunctions={secondary.validation}
                formDataKey={secondary.formDataKey}
                name={secondary.name}
                subLabel={secondary.subLabelCopy}
                initialValue={secondary.initialValue}
                onChange={(value: any) => {
                    secondary.onChange(value)
                    setSecondaryState((prev: any) => ({ ...prev, secondary: value }))
                    setClickedSecondaryAddress(false)
                    validationDispatch({
                        type: 'ADD_ERROR',
                        payload: {
                            errorMessages: {
                                [street.formDataKey]: '',
                                [secondary.formDataKey]: '',
                                [city.formDataKey]: '',
                                [state.formDataKey]: '',
                                [zip.formDataKey]: '',
                            },
                        },
                    })
                }}
                setValueTo={secondaryState?.secondary}
                staticValue={secondaryDesignator}
                disableDropdown={disableLineTwoSuggestions}
                dropdown={
                    <div
                        className={styles.twMerge(
                            styles?.ui?.AddressField?.streetDropdown?.mobile,
                            styles?.ui?.AddressField?.streetDropdown?.tablet,
                            styles?.ui?.AddressField?.streetDropdown?.desktop
                        )}
                    >
                        {lineTwoSuggestions.map((suggestion: any) => {
                            const id = `${suggestion.streetLine}${suggestion?.secondary ? `, ${suggestion.secondary}` : ''}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}${suggestion?.entries ? `, ${suggestion.entries}` : ''}`
                            const secondaryName = `${suggestion.streetLine}${suggestion?.secondary ? `, ${suggestion.secondary}` : ''}${suggestion?.city ? `, ${suggestion.city}` : ''}${suggestion?.state ? `, ${suggestion.state}` : ''}${suggestion?.zipcode ? `, ${suggestion.zipcode}` : ''}`
                            return (
                                <Button
                                    type="textFieldDropdown"
                                    name={secondaryName}
                                    key={id}
                                    onClick={() => {
                                        setClickedSecondaryAddress(true)
                                        setSelectedAddress({
                                            street: suggestion.streetLine,
                                            city: suggestion.city,
                                            state: suggestion.state,
                                            zip: suggestion.zipcode,
                                        })
                                        if (suggestion.secondary) {
                                            setSecondaryState({
                                                secondary: suggestion.secondary,
                                                entries: suggestion.entries,
                                            })
                                        }
                                    }}
                                >
                                    <svg
                                        className={styles.twMerge(
                                            styles?.ui?.AddressField?.streetDropdownSvg?.mobile,
                                            styles?.ui?.AddressField?.streetDropdownSvg?.tablet,
                                            styles?.ui?.AddressField?.streetDropdownSvg?.desktop,
                                            styles?.ui?.AddressField?.streetDropdownSvg?.hover
                                        )}
                                        width="26"
                                        height="26"
                                        viewBox="0 0 26 26"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <circle cx="13" cy="13" r="13" fill="#F4F4F5" />
                                        <path
                                            d="M12.9997 4C9.68607 4 7 6.7966 7 10.2466C7 10.8394 7.08126 11.4124 7.22936 11.9554C7.32041 12.2902 7.42069 12.622 7.57801 12.9226C8.80838 15.268 12.9997 21.9994 12.9997 21.9994C12.9997 21.9994 17.1916 15.2674 18.422 12.9226C18.5793 12.622 18.679 12.2902 18.7706 11.9554C18.9187 11.4118 19 10.8394 19 10.2466C19 6.7966 16.3134 4 12.9997 4ZM12.9997 12.9976C12.1629 12.9976 11.4161 12.5902 10.932 11.956C10.573 11.4862 10.358 10.8922 10.358 10.2472C10.358 8.7304 11.5434 7.4968 13.0003 7.4968C14.4571 7.4968 15.642 8.731 15.642 10.2472C15.642 10.8928 15.427 11.4868 15.068 11.956C14.5833 12.5902 13.8371 12.9976 13.0003 12.9976H12.9997Z"
                                            fill="#151515"
                                        />
                                    </svg>
                                    <div
                                        className={styles.twMerge(
                                            styles?.ui?.AddressField?.streetDropdownItem?.mobile,
                                            styles?.ui?.AddressField?.streetDropdownItem?.tablet,
                                            styles?.ui?.AddressField?.streetDropdownItem?.desktop,
                                            styles?.ui?.AddressField?.streetDropdownItem?.hover
                                        )}
                                    >
                                        <BodyText2>{suggestion.secondary}</BodyText2>
                                    </div>
                                </Button>
                            )
                        })}
                    </div>
                }
            >
                {secondary.labelCopy}
            </TextField>
            <TextField
                step={step}
                placeholder="Anytown"
                disableOnChangeValidation
                disableValidation={streetState !== '' && streetState !== null && streetState !== undefined}
                validationFunctions={city.validation}
                formDataKey={city.formDataKey}
                name={city.name}
                subLabel={city.subLabelCopy}
                initialValue={city.initialValue}
                setValueTo={selectedAddress.city}
                onChange={(value: any) => {
                    city.onChange(value)
                }}
            >
                {city.labelCopy}
            </TextField>
            <div
                className={styles.twMerge(
                    styles?.ui?.AddressField?.zipStateContainer?.mobile,
                    styles?.ui?.AddressField?.zipStateContainer?.tablet,
                    styles?.ui?.AddressField?.zipStateContainer?.desktop,
                    styles?.ui?.AddressField?.zipStateContainer?.hover
                )}
            >
                <Dropdown
                    step={step}
                    disableOnChangeValidation
                    disableValidation={streetState !== '' && streetState !== null && streetState !== undefined}
                    validationFunctions={state.validation}
                    formDataKey={state.formDataKey}
                    name={state.name}
                    subLabel={state.subLabelCopy}
                    initialValue={state.initialValue}
                    setValueTo={selectedAddress.state}
                    options={usStates}
                    onChange={(value: any) => {
                        state.onChange(value)
                    }}
                    className={styles.twMerge(
                        styles?.ui?.AddressField?.stateField?.mobile,
                        styles?.ui?.AddressField?.stateField?.tablet,
                        styles?.ui?.AddressField?.stateField?.desktop,
                        styles?.ui?.AddressField?.stateField?.hover
                    )}
                >
                    {state.labelCopy}
                </Dropdown>
                <TextField
                    step={step}
                    placeholder="12345"
                    disableOnChangeValidation
                    disableValidation={streetState !== '' && streetState !== null && streetState !== undefined}
                    validationFunctions={zip.validation}
                    formDataKey={zip.formDataKey}
                    name={zip.name}
                    subLabel={zip.subLabelCopy}
                    initialValue={zip.initialValue}
                    setValueTo={selectedAddress.zip}
                    onChange={(value: any) => {
                        zip.onChange(value)
                    }}
                    className={styles.twMerge(
                        styles?.ui?.AddressField?.zipField?.mobile,
                        styles?.ui?.AddressField?.zipField?.tablet,
                        styles?.ui?.AddressField?.zipField?.desktop,
                        styles?.ui?.AddressField?.zipField?.hover
                    )}
                >
                    {zip.labelCopy}
                </TextField>
            </div>
        </>
    )
}

export default AddressField
