/* eslint-disable no-unused-vars */
import { useEffect, useContext } from 'react'
import { CopyContext } from '../contexts/Copy'
import { FormDataContext } from '../contexts/FormData'

function SetUpDemoFormTestData() {
    // const [copy]: any = useContext(CopyContext)
    // const [formData, formDataDispatch]: any = useContext(FormDataContext)

    // useEffect(() => {
    //     if (formData?.topServiceableCompany) {
    //         if (!formData?.newOrderOfferSplit) {
    //             if (copy?.steps?.offersBasic?.notAcpTransferable?.[formData?.topServiceableCompany]?.topHeavyOffers) {
    //                 const offerOrderSplit = Math.random()
    //                 if (offerOrderSplit >= 0.5) {
    //                     formDataDispatch({
    //                         type: 'SET_FIELD',
    //                         payload: {
    //                             name: 'newOrderOfferSplit',
    //                             value: true,
    //                         },
    //                     })
    //                     return
    //                 }
    //             }
    //             formDataDispatch({
    //                 type: 'SET_FIELD',
    //                 payload: {
    //                     name: 'newOrderOfferSplit',
    //                     value: false,
    //                 },
    //             })
    //         }
    //     }
    // }, [
    //     copy?.steps?.offersBasic?.notAcpTransferable,
    //     formData?.newOrderOfferSplit,
    //     formData?.topServiceableCompany,
    //     formDataDispatch,
    // ])

    return null
}

export default SetUpDemoFormTestData
