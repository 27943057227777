/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
import { v4 as uuidv4 } from 'uuid'
// Contexts
import { ConfigContext } from '../../contexts/Config'

function SetAceId({ setIsLoaded }: { setIsLoaded: Function }) {
    // Contexts
    const [config, configDispatch]: any = useContext(ConfigContext)

    useEffect(() => {
        if (config?.loaded?.aceId) {
            setIsLoaded(true)
        }
    }, [config?.loaded?.aceId, config?.loaded.tenant])

    useEffect(() => {
        configDispatch({
            type: 'SET_ACE_ID',
            payload: uuidv4(),
        })
    }, [])

    return null
}

export default SetAceId
