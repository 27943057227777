/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { DataFunnelContext } from '../contexts/DataFunnel'
// Helpers
import makeBackendRequest from '../helpers/backend'

declare global {
    // eslint-disable-next-line no-unused-vars
    interface Window {
        hj: any
    }
}

function Conversion() {
    // Contexts
    const [dataFunnel]: any = useContext(DataFunnelContext)

    // DOB
    useEffect(() => {
        if (dataFunnel?.formCompleted) {
            makeBackendRequest(
                { stack: 'metaConversion', endpoint: 'inApp' },
                {
                    config: dataFunnel?.contexts?.config,
                    formData: dataFunnel?.contexts?.formData,
                }
            )
        }
    }, [dataFunnel?.formCompleted])

    return null
}

export default Conversion
