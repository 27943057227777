// Packages
import React from 'react'
// Core
import Routers from './core/Routers'
import RootContexts from './core/RootContexts'
import SetCoreConfigs from './core/SetCoreConfigs/index'
import SetUpInstance from './core/SetUpInstance'
// Contexts
import DataFunnelStore from './contexts/DataFunnel'
import InstanceStore from './contexts/Instance'

function App() {
    return (
        <DataFunnelStore>
            <InstanceStore>
                <SetUpInstance>
                    <RootContexts>
                        <SetCoreConfigs />
                        <Routers />
                    </RootContexts>
                </SetUpInstance>
            </InstanceStore>
        </DataFunnelStore>
    )
}

export default App
