const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000'
        case 'dev':
            return 'https://je1wa5txl6.execute-api.us-east-1.amazonaws.com/prod/manychat'
        case 'test':
            return 'https://wvziwa2pl7.execute-api.us-east-1.amazonaws.com/prod/manychat'
        default:
            return 'https://q54gfru4zd.execute-api.us-east-1.amazonaws.com/prod/manychat'
    }
}

const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        case 'dev':
            return process.env.REACT_APP_DEV_MANYCHAT_KEY ? process.env.REACT_APP_DEV_MANYCHAT_KEY : ''
        case 'test':
            return process.env.REACT_APP_TEST_MANYCHAT_KEY ? process.env.REACT_APP_TEST_MANYCHAT_KEY : ''
        default:
            return process.env.REACT_APP_PROD_MANYCHAT_KEY ? process.env.REACT_APP_PROD_MANYCHAT_KEY : ''
    }
}

const get = async (request: { config: any; formData: any; body?: any; queryParams?: any }) => {
    if (request?.config?.devMode === true) return {}

    const userId = request?.queryParams?.userId ? request.queryParams.userId : undefined

    return fetch(`${getUrl(request?.config?.environment?.manychat)}/get`, {
        method: 'POST',
        body: JSON.stringify({ userId }),
        headers: {
            'x-api-key': getApiKey(request?.config?.environment?.manychat),
            Authorization: request.config.manychatCompany,
        },
    })
}

const set = async (request: { config: any; formData: any; body?: any; queryParams?: any }) => {
    const mcUserId = request?.queryParams?.userId ? request.queryParams.userId : undefined
    const body: any = {
        userId: mcUserId,
        // contactDataID: request?.formData?.connexOneLeadId ? request.formData.connexOneLeadId : undefined,
        AceId: request?.config?.aceId ? request.config.aceId : '',
        mc_address: request?.formData?.addressStreet ? request?.formData?.addressStreet : -1,
        mc_addressLn2: request?.formData?.addressSecondary ? request?.formData?.addressSecondary : -1,
        mc_city: request?.formData?.addressCity ? request?.formData?.addressCity : -1,
        mc_state: request?.formData?.addressState ? request?.formData?.addressState : -1,
        mc_zipCode: request?.formData?.addressZip ? request?.formData?.addressZip : -1,
        mc_shippingAddress: request?.formData?.shippingAddressStreet ? request?.formData?.shippingAddressStreet : -1,
        mc_shippingAddressLn2: request?.formData?.shippingAddressSecondary
            ? request?.formData?.shippingAddressSecondary
            : -1,
        mc_shippingCity: request?.formData?.shippingAddressCity ? request?.formData?.shippingAddressCity : -1,
        mc_shippingState: request?.formData?.shippingAddressState ? request?.formData?.shippingAddressState : -1,
        mc_shippingZipCode: request?.formData?.shippingAddressZip ? request?.formData?.shippingAddressZip : -1,
        is_existing_customer: request?.formData?.existingCustomer,
        mc_firstName: request?.formData?.firstName,
        mc_middleName: request?.formData?.middleName,
        mc_lastName: request?.formData?.lastName,
        mc_dob: request?.formData?.birthDate,
        mc_phone: request?.formData?.phoneNumber,
        mc_email: request?.formData?.email,
        selectedOffer: request?.formData?.selectedPlan ? request.formData.selectedPlan : -1,
        fc_serviceability_company: request?.formData?.topServiceableCompany,
    }

    return fetch(`${getUrl(request.config.environment.manychat)}/set`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'x-api-key': getApiKey(request.config.environment.manychat),
            Authorization: request.config.manychatCompany,
        },
    })
}

const submit = async (request: { config: any; formData: any; body?: any; queryParams?: any }) => {
    if (request?.config?.devMode === true) return {}

    const mcUserId = request?.queryParams?.userId ? request.queryParams.userId : undefined
    const body: any = {
        userId: mcUserId,
        flowIdBotFieldName: 'completed_form_flowid',
        refLinkBotFieldName: 'demo_3_redirect_url',
    }

    return fetch(`${getUrl(request.config.environment.manychat)}/submit`, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
            'x-api-key': getApiKey(request.config.environment.manychat),
            Authorization: request.config.manychatCompany,
        },
    })
}

const endpoints: { [key: string]: Function } = {
    get,
    set,
    submit,
}

const manychat = async (endpoint: string, request: { config: any; formData: any; queryParams?: any }) => {
    return endpoints[endpoint](request)
}

export default manychat
