const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000/'
        case 'dev':
            return 'https://0q53ur8f8k.execute-api.us-east-1.amazonaws.com/prod'
        case 'test':
            return 'https://81wa7gkdu2.execute-api.us-east-1.amazonaws.com/prod/'
        default:
            return 'https://r7d5uykoy2.execute-api.us-east-1.amazonaws.com/prod/'
    }
}
const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        case 'dev':
            return process.env.REACT_APP_DEV_UTILITY_KEY ? process.env.REACT_APP_DEV_UTILITY_KEY : ''
        case 'test':
            return process.env.REACT_APP_TEST_UTILITY_KEY ? process.env.REACT_APP_TEST_UTILITY_KEY : ''
        default:
            return process.env.REACT_APP_PROD_UTILITY_KEY ? process.env.REACT_APP_PROD_UTILITY_KEY : ''
    }
}

const clientType = async (request: { config: any; formData: any }) => {
    return fetch(`${getUrl(request.config.environment.utilities)}/client-type`, {
        method: 'POST',
        body: JSON.stringify({ company: request.formData.serviceabilityArray }),
        headers: {
            'x-api-key': getApiKey(request.config.environment.utilities),
            Authorization: request.config.tenant,
        },
    })
}
const currentDate = async (request: { config: any; formData: any }) => {
    return fetch(`${getUrl(request.config.environment.utilities)}/current-date`, {
        method: 'POST',
        body: JSON.stringify({ company: request.formData.serviceabilityArray }),
        headers: {
            'x-api-key': getApiKey(request.config.environment.utilities),
            Authorization: request.config.tenant,
        },
    })
}
const currentMonth = async (request: { config: any; formData: any }) => {
    return fetch(`${getUrl(request.config.environment.utilities)}/current-month`, {
        method: 'POST',
        body: JSON.stringify({ company: request.formData.serviceabilityArray }),
        headers: {
            'x-api-key': getApiKey(request.config.environment.utilities),
            Authorization: request.config.tenant,
        },
    })
}

const getParameterFromStore = async (request: { config: any; formData: any }) => {
    return fetch(`${getUrl(request.config.environment.utilities)}/get-parameter-from-store`, {
        method: 'POST',
        body: JSON.stringify({ parameterName: request.formData.parameterStorePath }),
        headers: {
            'x-api-key': getApiKey(request.config.environment.utilities),
            Authorization: request.config.tenant,
        },
    })
}

const endpoints: { [key: string]: Function } = {
    clientType,
    currentDate,
    currentMonth,
    getParameterFromStore,
}

const utilitiesMain = async (endpoint: string, request: { config: any; formData: any }) => {
    return endpoints[endpoint](request)
}

export default utilitiesMain
