// Packages
import React, { useContext } from 'react'
// Components
import ConditionalRender from './ConditionalRender'
import StyleCopy from './StyleCopy'
// UI
import Popup from '../ui/Popup'
import Heading from '../ui/Heading'
import SubHeading from '../ui/SubHeading'
import Button from '../ui/Button'
import Scroll from '../ui/Scroll'
import BodyText5 from '../ui/BodyText5'
import ToggleCard from '../ui/ToggleCard'
// Contexts
import { PopupContext } from '../contexts/Popup'
import { CopyContext } from '../contexts/Copy'
import { FormDataContext } from '../contexts/FormData'
import { ConfigContext } from '../contexts/Config'
import { StylesContext } from '../contexts/Styles'
import { CnxFifoQueueContext } from '../contexts/CnxFifoQueue'
// Helpers
import { parseCopy } from '../helpers/parseCopy'
import makeBackendRequest from '../helpers/backend'
import BodyText2 from '../ui/BodyText2'

function DisplayPopupBlocker() {
    // Contexts
    const [popup, popupDispatch]: any = useContext(PopupContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [styles]: any = useContext(StylesContext)
    const [, cnxFifoQueue]: any = useContext(CnxFifoQueueContext)

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <ConditionalRender condition={popup?.componentToRender}>
            <ConditionalRender condition={typeof popup?.componentToRender !== 'string'}>
                <Popup>{popup?.componentToRender}</Popup>
            </ConditionalRender>
            <ConditionalRender condition={typeof popup?.componentToRender === 'string'}>
                <ConditionalRender condition={copy?.popups?.[popup.componentToRender]?.type === 'basic'}>
                    <Popup>
                        <Heading
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.heading?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.heading?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.heading?.desktop
                            )}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.title}</StyleCopy>
                        </Heading>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.divider?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.divider?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.divider?.desktop
                            )}
                        />
                        <Scroll>
                            <SubHeading
                                className={styles.twMerge(
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.mobile,
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.tablet,
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.desktop
                                )}
                            >
                                <StyleCopy>
                                    {parseCopy(copy?.popups?.[popup.componentToRender]?.components?.body, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            </SubHeading>
                        </Scroll>
                        <Button
                            type="ghost"
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.button?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.button?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.button?.desktop
                            )}
                            onClick={() => {
                                popupDispatch({ type: 'REMOVE_POPUP' })
                            }}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.closeButton}</StyleCopy>
                        </Button>
                    </Popup>
                </ConditionalRender>
                <ConditionalRender condition={copy?.popups?.[popup.componentToRender]?.type === 'legal'}>
                    <Popup>
                        <Heading
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.heading?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.heading?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.heading?.desktop
                            )}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.title}</StyleCopy>
                        </Heading>
                        <div
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.divider?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.divider?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.divider?.desktop
                            )}
                        />
                        <Scroll>
                            <SubHeading
                                className={styles.twMerge(
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.mobile,
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.tablet,
                                    styles?.ui?.DisplayPopupBlocker?.subheading?.desktop
                                )}
                            >
                                <BodyText5>
                                    <StyleCopy>
                                        {parseCopy(copy?.popups?.[popup.componentToRender]?.components?.body, {
                                            config,
                                            formData,
                                        })}
                                    </StyleCopy>
                                </BodyText5>
                            </SubHeading>
                        </Scroll>
                        <Button
                            type="ghost"
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.button?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.button?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.button?.desktop
                            )}
                            onClick={() => {
                                popupDispatch({ type: 'REMOVE_POPUP' })
                            }}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.closeButton}</StyleCopy>
                        </Button>
                    </Popup>
                </ConditionalRender>
                <ConditionalRender condition={copy?.popups?.[popup.componentToRender]?.type === 'fundleBundle'}>
                    <Popup>
                        <Heading
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.heading?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.heading?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.heading?.desktop
                            )}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.title}</StyleCopy>
                        </Heading>
                        <SubHeading
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.subheading?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.subheading?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.subheading?.desktop
                            )}
                        >
                            <BodyText2>
                                <StyleCopy>
                                    {parseCopy(copy?.popups?.[popup.componentToRender]?.components?.body, {
                                        config,
                                        formData,
                                    })}
                                </StyleCopy>
                            </BodyText2>
                        </SubHeading>
                        <div>
                            {copy?.popups?.[popup.componentToRender]?.components?.bundles?.map((bundle: any) => {
                                return (
                                    <ToggleCard
                                        key={bundle.name}
                                        description={bundle.description}
                                        toggleLeft
                                        imageString={bundle.image}
                                        onCheck={() => {
                                            // Save the fundle bundle selected in formData
                                            formDataDispatch({
                                                type: 'SET_FIELD',
                                                payload: {
                                                    name: `fundleBundle${bundle.name}`,
                                                    value: !formData[`fundleBundle${bundle.name}`],
                                                },
                                            })
                                        }}
                                    />
                                )
                            })}
                        </div>
                        <Button
                            type="fill"
                            className={styles.twMerge(
                                styles?.ui?.DisplayPopupBlocker?.button?.mobile,
                                styles?.ui?.DisplayPopupBlocker?.button?.tablet,
                                styles?.ui?.DisplayPopupBlocker?.button?.desktop
                            )}
                            onClick={() => {
                                // Make an API call to cnx
                                makeBackendRequest(
                                    { stack: 'connexOne', endpoint: 'processDemoform' },
                                    { formData, config, cnxFifoQueue }
                                )
                                popupDispatch({ type: 'REMOVE_POPUP' })
                            }}
                        >
                            <StyleCopy>{copy?.popups?.[popup.componentToRender]?.components?.closeButton}</StyleCopy>
                        </Button>
                    </Popup>
                </ConditionalRender>
            </ConditionalRender>
        </ConditionalRender>
    )
}

export default DisplayPopupBlocker
