/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import React, { useContext, useEffect } from 'react'

// Components
import StyleCopy from '../../components/StyleCopy'
// UI
import Page from '../../ui/Page'
import Main from '../../ui/Main'
import Container from '../../ui/Container'
import Header from '../../ui/Header'
import Footer from '../../ui/Footer'
import Heading from '../../ui/Heading'
import SubHeading from '../../ui/SubHeading'
import Button from '../../ui/Button'
import InfoCard from '../../ui/InfoCard'
import Checkbox from '../../ui/Checkbox'
import BodyText5 from '../../ui/BodyText5'
import AmountDue from '../../ui/AmountDue'
// Contexts
import { DataFunnelContext } from '../../contexts/DataFunnel'
import { CopyContext } from '../../contexts/Copy'
import { ConfigContext } from '../../contexts/Config'
import { FormDataContext } from '../../contexts/FormData'
import { StylesContext } from '../../contexts/Styles'
import { StepsContext } from '../../contexts/Steps'
// Helpers
import { parseCopy } from '../../helpers/parseCopy'
import { addDashesToPhone } from '../../helpers/parsePhoneNumber'
import ConditionalRender from '../../components/ConditionalRender'

function AcpTransferable({ onNext, step, progressBar }: any) {
    // Contexts
    const [, dataFunnelDispatch]: any = useContext(DataFunnelContext)
    const [steps]: any = useContext(StepsContext)
    const [copy]: any = useContext(CopyContext)
    const [config]: any = useContext(ConfigContext)
    const [formData, formDataDispatch]: any = useContext(FormDataContext)
    const [styles]: any = useContext(StylesContext)

    useEffect(() => {
        if (formData?.isOrderSubmitted) {
            onNext()
        }
    }, [formData?.isOrderSubmitted])

    useEffect(() => {
        if (copy?.steps && steps?.currentStep && steps?.main) {
            // NOTE: Keeping in step components for now since adding custom items might be necessary in the future
            const currentStepName = steps?.main[steps?.currentStep].name
            dataFunnelDispatch({
                type: 'SET_COPY_CONTENT',
                payload: {
                    key: currentStepName,
                    value: copy?.steps?.[currentStepName],
                },
            })
        }
    }, [])

    return (
        <Page>
            <Container>
                <div>
                    <Header />
                    <Main>
                        {progressBar}
                        <Heading name="submitOrderBasicAcpHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.title, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                })}
                            </StyleCopy>
                        </Heading>
                        <SubHeading name="submitOrderBasicAcpSubHeader">
                            <StyleCopy>
                                {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.subHeader, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                })}
                            </StyleCopy>
                        </SubHeading>
                        <InfoCard
                            topServiceableCompany={formData?.topServiceableCompany}
                            type="branded"
                            plan={formData.selectedPlan}
                            image={{}}
                            fullName={`${formData.firstName} ${formData.lastName}`}
                            phoneNumber={addDashesToPhone(formData.phoneNumber)}
                            address={formData.fullAddress}
                            planTypeCopy={
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.infoCard?.planSelected, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                    })}
                                </StyleCopy>
                            }
                            titleCopy={
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.infoCard?.title, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                    })}
                                </StyleCopy>
                            }
                            nameCopy={
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.infoCard?.name, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                    })}
                                </StyleCopy>
                            }
                            phoneCopy={
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.infoCard?.phone, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                    })}
                                </StyleCopy>
                            }
                            addressCopy={
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.infoCard?.address, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                    })}
                                </StyleCopy>
                            }
                        />
                        <ConditionalRender
                            condition={
                                copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                    ?.amountDue?.dueAmount
                            }
                        >
                            <AmountDue
                                name="submitOrderBasicAcp"
                                dueCopy={
                                    <StyleCopy>
                                        {parseCopy(
                                            copy?.steps?.submitOrderBasic?.acpTransferable?.amountDue?.dueAmount,
                                            {
                                                config,
                                                formData,
                                                copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                            }
                                        )}
                                    </StyleCopy>
                                }
                                disclosureCopy={
                                    <StyleCopy>
                                        {parseCopy(
                                            copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                formData?.topServiceableCompany
                                            ]?.amountDue?.disclosure,
                                            {
                                                config,
                                                formData,
                                                copy: copy?.steps?.submitOrderBasic?.notAcpTransferable?.[
                                                    formData?.topServiceableCompany
                                                ],
                                            }
                                        )}
                                    </StyleCopy>
                                }
                            >
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.amountDue?.amount, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                    })}
                                </StyleCopy>
                            </AmountDue>
                        </ConditionalRender>
                        <div
                            className={styles.twMerge(
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.requestBroadbandLabel?.mobile,
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.requestBroadbandLabel?.tablet,
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.requestBroadbandLabel?.desktop
                            )}
                        >
                            <Checkbox
                                step={step}
                                name="requestBroadbandLabel"
                                initialValue={formData.isBroadbandLabelRequested}
                                popup={
                                    copy?.steps?.submitOrderBasic?.notAcpTransferable?.[formData?.topServiceableCompany]
                                        ?.broadBandLabelPopup
                                }
                                onChange={(value: boolean) => {
                                    formDataDispatch({
                                        type: 'SET_FIELD',
                                        payload: { name: 'isBroadbandLabelRequested', value },
                                    })
                                }}
                            >
                                <StyleCopy>
                                    {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.requestBroadbandLabel, {
                                        config,
                                        formData,
                                        copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                    })}
                                </StyleCopy>
                            </Checkbox>
                        </div>
                        <Button
                            className={styles.twMerge(
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.button?.mobile,
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.button?.tablet,
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.button?.desktop
                            )}
                            type="fill"
                            name="submitOrderBasicAcpSubmit"
                            onClick={() => {
                                formDataDispatch({
                                    type: 'SET_FIELD',
                                    payload: { name: 'isOrderSubmitted', value: true },
                                })
                            }}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.submitButton, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                })}
                            </StyleCopy>
                        </Button>
                        <BodyText5
                            name="submitOrderBasicAcpFooterTitle"
                            className={styles.twMerge(
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.disclaimerTitle?.mobile,
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.disclaimerTitle?.tablet,
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.disclaimerTitle?.desktop
                            )}
                        >
                            <StyleCopy>
                                {parseCopy(copy?.steps?.submitOrderBasic?.acpTransferable?.footerText?.title, {
                                    config,
                                    formData,
                                    copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                })}
                            </StyleCopy>
                        </BodyText5>
                        <div
                            className={styles.twMerge(
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.disclaimerContainer?.mobile,
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.disclaimerContainer?.tablet,
                                styles?.steps?.submitOrderBasic?.AcpTransferable?.disclaimerContainer?.desktop
                            )}
                        >
                            <BodyText5 name="submitOrderBasicAcpFooterOne">
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.acpTransferable?.footerText?.disclaimerOne,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                        }
                                    )}
                                </StyleCopy>
                            </BodyText5>
                            <BodyText5 name="submitOrderBasicAcpFooterTwo">
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.acpTransferable?.footerText?.disclaimerTwo,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                        }
                                    )}
                                </StyleCopy>
                            </BodyText5>
                            <BodyText5 name="submitOrderBasicAcpFooterThree">
                                <StyleCopy>
                                    {parseCopy(
                                        copy?.steps?.submitOrderBasic?.acpTransferable?.footerText?.disclaimerThree,
                                        {
                                            config,
                                            formData,
                                            copy: copy?.steps?.submitOrderBasic?.acpTransferable,
                                        }
                                    )}
                                </StyleCopy>
                            </BodyText5>
                        </div>
                    </Main>
                </div>
                <Footer />
            </Container>
        </Page>
    )
}

export default AcpTransferable
