const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000'
        case 'dev':
            return 'https://qzyjr68nhk.execute-api.us-east-1.amazonaws.com/prod'
        case 'test':
            return 'https://jxjaw30uv5.execute-api.us-east-1.amazonaws.com/prod'
        default:
            return 'https://19wmms03u9.execute-api.us-east-1.amazonaws.com/prod'
    }
}

const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        case 'dev':
            return process.env.REACT_APP_DEV_ACE_USERS_DB_KEY ? process.env.REACT_APP_DEV_ACE_USERS_DB_KEY : ''
        case 'test':
            return process.env.REACT_APP_TESTING_ACE_USERS_DB_KEY ? process.env.REACT_APP_TESTING_ACE_USERS_DB_KEY : ''
        default:
            return process.env.REACT_APP_PROD_ACE_USERS_DB_KEY ? process.env.REACT_APP_PROD_ACE_USERS_DB_KEY : ''
    }
}

const putUser = async (request: { config: any; formData: any; body?: any }) => {
    if (request?.config?.devMode === true) return {}

    return fetch(`${getUrl(request.config.environment.aceUserTables)}/put_user`, {
        method: 'PUT',
        body: JSON.stringify(request?.body),
        headers: {
            'x-api-key': getApiKey(request.config.environment.aceUserTables),
            Authorization: 'combo',
        },
    })
}

const getUserPK = async (request: { config: any; formData: any; body?: any; queryParams?: any }) => {
    if (request?.config?.devMode === true) return {}

    return fetch(
        `${getUrl(request.config.environment.aceUserTables)}/get_user?tenant_id=optimum&tables=${request.body.table}&PK=${request.body.pk}`,
        {
            method: 'GET',
            headers: {
                'x-api-key': getApiKey(request.config.environment.aceUserTables),
                Authorization: 'optimum',
            },
        }
    )
}

const endpoints: { [key: string]: Function } = {
    putUser,
    getUserPK,
}

const aceUserTables = async (endpoint: string, request: { config: any; formData: any }) => {
    return endpoints[endpoint](request)
}

export default aceUserTables
