// Packages
import React, { useState } from 'react'
// Stores
import FormDataStore from '../../contexts/FormData'
import LoadingBlockersStore from '../../contexts/LoadingBlockers'
import CopyStore from '../../contexts/Copy'
import StyleStore from '../../contexts/Styles'
import PopupStore from '../../contexts/Popup'
// Components
import SetUpConfig from '../../components/SetUpConfig'
import DisplayLoadingBlocker from '../../components/DisplayLoadingBlocker'
import DisplayLoadingPageBlocker from '../../components/DisplayLoadingPageBlocker'
import SetUpCopy from '../../components/SetUpCopy'
import SetUpStyles from '../../components/SetUpStyles'
import SetUpFormData from '../../components/SetUpFormData'
import DbTracking from '../../components/DbTracking'
import ComplianceTracking from '../../components/ComplianceTracking'
import DisplayPopup from '../../components/DisplayPopup'
import Conversion from '../../components/Conversion'
// UI
import Favicon from '../../ui/Favicon'
import AddBlockers from '../../components/AddBlockers'
import ConditionalRender from '../../components/ConditionalRender'
// Stepper
import Stepper from './Stepper'

function SesUnsubscribe({ config }: any) {
    const [started, setStarted] = useState(false)

    return (
        <FormDataStore>
            <LoadingBlockersStore>
                <CopyStore>
                    <StyleStore>
                        <PopupStore>
                            <DisplayLoadingBlocker />
                            <AddBlockers setStarted={setStarted} setUpStyles />
                            <SetUpCopy formName={config} />
                            <SetUpConfig formName={config} />
                            <SetUpStyles formName={config} />
                            <SetUpFormData formName={config} />
                            <Conversion />
                            <DbTracking />
                            <ComplianceTracking />
                            <DisplayLoadingPageBlocker>
                                <Favicon />
                                <DisplayPopup />
                                <ConditionalRender condition={started}>
                                    <Stepper />
                                </ConditionalRender>
                            </DisplayLoadingPageBlocker>
                        </PopupStore>
                    </StyleStore>
                </CopyStore>
            </LoadingBlockersStore>
        </FormDataStore>
    )
}

export default SesUnsubscribe
