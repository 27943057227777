const getUrl = (environment: string) => {
    switch (environment) {
        case 'localhost':
            return 'http://localhost:3000'
        case 'dev':
            return 'https://z1f9ejd2q5.execute-api.us-east-1.amazonaws.com/prod/ibex/results'
        case 'test':
            return 'https://74l3bgb037.execute-api.us-east-1.amazonaws.com/prod/ibex/results'
        default:
            return 'https://qlr94pr900.execute-api.us-east-1.amazonaws.com/prod/ibex/results'
    }
}
const getApiKey = (environment: string): string => {
    switch (environment) {
        case 'localhost':
            return ''
        case 'dev':
            return process.env.REACT_APP_DEV_IBEX_SERVICEABILITY_KEY
                ? process.env.REACT_APP_DEV_IBEX_SERVICEABILITY_KEY
                : ''
        case 'test':
            return process.env.REACT_APP_TEST_IBEX_SERVICEABILITY_KEY
                ? process.env.REACT_APP_TEST_IBEX_SERVICEABILITY_KEY
                : ''
        default:
            return process.env.REACT_APP_PROD_IBEX_SERVICEABILITY_KEY
                ? process.env.REACT_APP_PROD_IBEX_SERVICEABILITY_KEY
                : ''
    }
}

const main = async (request: { config: any; formData: any; body: any }) => {
    return fetch(`${getUrl(request.config.environment.ibexServiceabilityCheck)}/`, {
        method: 'POST',
        body: JSON.stringify({
            PK: request?.body?.ibexKey,
        }),
        headers: {
            'x-api-key': getApiKey(request.config.environment.ibexServiceabilityCheck),
            Authorization: request.config.tenant,
        },
    })
}

const endpoints: { [key: string]: Function } = {
    main,
}

const ibexServiceabilityCheck = async (endpoint: string, request: { config: any; formData: any }) => {
    return endpoints[endpoint](request)
}

export default ibexServiceabilityCheck
