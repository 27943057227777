const isInitialLoadingDone = (initialLoadingBlockers: { [index: string]: Boolean }) => {
    const initialLoadingBlockersArray = Object.keys(initialLoadingBlockers)
    let isLoaded = true

    initialLoadingBlockersArray.forEach((blocker: string) => {
        if (!initialLoadingBlockers[blocker]) {
            isLoaded = false
        }
    })

    return isLoaded
}

export default isInitialLoadingDone
