/* eslint-disable react-hooks/exhaustive-deps */
// Packages
import { useEffect, useContext } from 'react'
// Contexts
import { ConfigContext } from '../contexts/Config'
import { QueryParamsContext } from '../contexts/QueryParams'
import { LoadingBlockersContext } from '../contexts/LoadingBlockers'
import { CopyContext } from '../contexts/Copy'
// Helpers
import getConfigData from '../helpers/config/getConfigData'

function SetUpCopy({ formName }: { formName: string }) {
    // Contexts
    const [config]: any = useContext(ConfigContext)
    const [copy, copyDispatch]: any = useContext(CopyContext)
    const [queryParams]: any = useContext(QueryParamsContext)
    const [, loadingBlockersDispatch]: any = useContext(LoadingBlockersContext)

    useEffect(() => {
        if (Object.keys(copy).length > 0) {
            loadingBlockersDispatch({ type: 'REMOVE_PAGE_BLOCKER', payload: 'setUpCopy' })
        }
    }, [copy])

    useEffect(() => {
        const asyncWrapper = async () => {
            loadingBlockersDispatch({ type: 'ADD_PAGE_BLOCKER', payload: 'setUpCopy' })
            getConfigData('copy.json', config.tenant, {
                formName,
                isMock: config?.isMock,
                environmentId: queryParams.environmentId,
                configId: queryParams?.configId ? queryParams.configId : config.deployment,
                configEnvironment: queryParams?.configEnvironment ? queryParams.configEnvironment : config.account,
            }).then((data: { [key: string]: any }) => {
                const payload = data

                copyDispatch({
                    type: 'SET',
                    payload,
                })
            })
        }

        asyncWrapper()
    }, [
        config.account,
        config.deployment,
        config?.isMock,
        config.tenant,
        formName,
        queryParams.configEnvironment,
        queryParams.configId,
        queryParams.environmentId,
        queryParams.isMock,
    ])

    return null
}

export default SetUpCopy
