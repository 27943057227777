/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'

function Card({ className = '', children }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)

    if (Object.keys(styles).length === 0) {
        return null
    }

    return (
        <div
            className={styles.twMerge(
                styles?.ui?.Card?.div?.mobile,
                styles?.ui?.Card?.div?.tablet,
                styles?.ui?.Card?.div?.desktop,
                className
            )}
        >
            {children}
        </div>
    )
}

export default Card
