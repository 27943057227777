// Packages
import React from 'react'
// Steps
import AddressBasic from '../../steps/AddressBasic'
import PiiBasic from '../../steps/PiiBasic'
import WelcomeBasic from '../../steps/WelcomeBasic'
import ServiceabilityCheckBasic from '../../steps/ServiceabilityCheckBasic'
import ServiceabilityCheckBlockerBasic from '../../steps/ServiceabilityCheckBlockerBasic'
import AcpQuestionBasic from '../../steps/AcpQuestionBasic'
import BranchingInterimForm from '../../steps/BranchingInterimForm'
import SuccessOptimumAsset from '../../steps/SuccessOptimumAsset'
import EndFallbackBasic from '../../steps/EndFallbackBasic'
import ExistingCustomerQuestionBasic from '../../steps/ExistingCustomerQuestionBasic'
import SuccessAllProvider from '../../steps/SuccessAllProvider'
import OffersBasic from '../../steps/OffersBasic'
import SubmitOrderBasic from '../../steps/SubmitOrderBasic'
import OrderCompleteBasic from '../../steps/OrderCompleteBasic'
import AddressBasicNoTempAddress from '../../steps/AddressBasicNoTempAddress'
import EndFallbackIbex from '../../steps/EndFallbackIbex'
import BundleOffersBasic from '../../steps/BundleOffersBasic'
import CurrentCustomerOptions from '../../steps/CurrentCustomerOptions'
// Step Actions
import getStepActions from '../stepActionFunctions/stepActions'
// Types
import { Step } from '../types/steps'

const stepNameMapObject: { [index: string]: Step } = {
    welcomeBasic: {
        component: <WelcomeBasic />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    addressBasic: {
        component: <AddressBasic />,
        disableButtons: false,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'addressBasic' }),
    },
    addressBasicNoTempAddress: {
        component: <AddressBasicNoTempAddress />,
        disableButtons: false,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'addressBasic' }),
        waitFor: 'addressStreetSmartyResponse',
    },
    serviceabilityCheckBasic: {
        component: <ServiceabilityCheckBasic />,
        disableButtons: false,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    serviceabilityCheckBlockerBasic: {
        component: <ServiceabilityCheckBlockerBasic />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    piiBasic: {
        component: <PiiBasic />,
        disableButtons: false,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'addLeadToConnex' }),
    },
    acpQuestionBasic: {
        component: <AcpQuestionBasic />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'addLeadToConnex' }),
    },
    branchingInterimForm: {
        component: <BranchingInterimForm />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'interimFormP2Branching' }),
    },
    successOptimumAsset: {
        component: <SuccessOptimumAsset />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    endFallbackBasic: {
        component: <EndFallbackBasic />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    endFallbackIbex: {
        component: <EndFallbackIbex />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: true,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    existingCustomerQuestionBasic: {
        component: <ExistingCustomerQuestionBasic />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'addLeadToConnex' }),
    },
    successAllProvider: {
        component: <SuccessAllProvider />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    offersBasic: {
        component: <OffersBasic />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'addLeadToConnex' }),
    },
    bundleOffersBasic: {
        component: <BundleOffersBasic />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'addLeadToConnex' }),
    },
    submitOrderBasic: {
        component: <SubmitOrderBasic />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'addLeadToConnex' }),
    },
    orderCompleteBasic: {
        component: <OrderCompleteBasic />,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    currentCustomerOptions: {
        component: <CurrentCustomerOptions />,
        disableButtons: false,
        disableNextButton: false,
        disablePreviousButton: true,
        disableProgressBar: false,
        onAction: getStepActions({ key: 'defaultAction' }),
    },
    default: {
        component: null,
        disableButtons: true,
        disableNextButton: false,
        disablePreviousButton: false,
        disableProgressBar: false,
        onAction: () => {},
    },
}

const stepNameMap = ({ key }: { key: string }) =>
    stepNameMapObject?.[key] ? stepNameMapObject[key] : stepNameMapObject.default

export default stepNameMap
