// Helpers
import initialState from './initialState'

const LoadingBlockersReducer = (
    state: any,
    action: { type: String; payload: any },
    // eslint-disable-next-line no-unused-vars
    dataFunnelContext: { state: { [key: string]: any }; dispatch: Function }
) => {
    switch (action.type) {
        case 'SET':
            dataFunnelContext.dispatch({
                type: 'SET_LOADING_BLOCKERS',
                payload: action.payload,
            })
            return action.payload
        case 'SET_DEFAULT':
            dataFunnelContext.dispatch({
                type: 'SET_LOADING_BLOCKERS',
                payload: initialState,
            })
            return initialState
        case 'ADD_BLOCKER':
            // dataFunnelContext.dispatch({
            //     type: 'START_MERGED_TIMER',
            //     payload: { name: `${action.payload}InputTimer` },
            // })
            // dataFunnelContext.dispatch({
            //     type: 'SET_INPUT_LOADING_BLOCKERS_FIELD',
            //     payload: { key: action.payload, value: true },
            // })
            return { ...state, blockers: { ...state.blockers, [action.payload]: true } }
        case 'REMOVE_BLOCKER':
            // dataFunnelContext.dispatch({
            //     type: 'END_MERGED_TIMER',
            //     payload: { name: `${action.payload}InputTimer` },
            // })
            // dataFunnelContext.dispatch({
            //     type: 'SET_INPUT_LOADING_BLOCKERS_FIELD',
            //     payload: { key: action.payload, value: false },
            // })
            return { ...state, blockers: { ...state.blockers, [action.payload]: false } }
        case 'ADD_PAGE_BLOCKER':
            dataFunnelContext.dispatch({
                type: 'SET_PAGE_LOADING_BLOCKERS_FIELD',
                payload: { key: action.payload, value: true },
            })
            // dataFunnelContext.dispatch({
            //     type: 'START_MERGED_TIMER',
            //     payload: { name: `${action.payload}PageTimer` },
            // })
            return { ...state, pageBlockers: { ...state.pageBlockers, [action.payload]: true } }
        case 'REMOVE_PAGE_BLOCKER':
            dataFunnelContext.dispatch({
                type: 'SET_PAGE_LOADING_BLOCKERS_FIELD',
                payload: { key: action.payload, value: false },
            })
            // dataFunnelContext.dispatch({
            //     type: 'END_MERGED_TIMER',
            //     payload: { name: `${action.payload}PageTimer` },
            // })
            return { ...state, pageBlockers: { ...state.pageBlockers, [action.payload]: false } }
        default:
            return state
    }
}

export default LoadingBlockersReducer
