/* eslint-disable react/prop-types */
// Packages
import React, { useContext } from 'react'

// Contexts
import { StylesContext } from '../contexts/Styles'
import { ConfigContext } from '../contexts/Config'
// Helpers
import { capitalizeFirstLetter } from '../helpers/string'
// UI
import HeadingText4 from './HeadingText4'

function AmountDue({ className = '', children, name, dueCopy, type }: { [key: string]: any }) {
    // Contexts
    const [styles]: any = useContext(StylesContext)
    const [config]: any = useContext(ConfigContext)

    if (config?.tenantConfig?.featureFlags?.[`disable${capitalizeFirstLetter(name)}AmountDue`]) {
        return null
    }

    if (Object.keys(styles).length === 0) {
        return null
    }

    switch (type) {
        case 'Highlight': {
            return (
                <div
                    className={styles.twMerge(
                        styles?.ui?.AmountDue?.highlightCardContainer?.mobile,
                        styles?.ui?.AmountDue?.highlightCardContainer?.tablet,
                        styles?.ui?.AmountDue?.highlightCardContainer?.desktop,
                        className
                    )}
                >
                    <div
                        className={styles.twMerge(
                            styles?.ui?.AmountDue?.highlightCard?.mobile,
                            styles?.ui?.AmountDue?.highlightCard?.tablet,
                            styles?.ui?.AmountDue?.highlightCard?.desktop,
                            className
                        )}
                    >
                        <div
                            className={styles.twMerge(
                                styles?.ui?.AmountDue?.div?.mobile,
                                styles?.ui?.AmountDue?.div?.tablet,
                                styles?.ui?.AmountDue?.div?.desktop,
                                className
                            )}
                        >
                            <HeadingText4
                                className={styles.twMerge(
                                    styles?.ui?.AmountDue?.title?.mobile,
                                    styles?.ui?.AmountDue?.title?.tablet,
                                    styles?.ui?.AmountDue?.title?.desktop,
                                    className
                                )}
                            >
                                {dueCopy}
                            </HeadingText4>
                            &nbsp;
                            <HeadingText4
                                className={styles.twMerge(
                                    styles?.ui?.AmountDue?.amount?.mobile,
                                    styles?.ui?.AmountDue?.amount?.tablet,
                                    styles?.ui?.AmountDue?.amount?.desktop,
                                    className
                                )}
                            >
                                {children}
                            </HeadingText4>
                        </div>
                    </div>
                </div>
            )
        }
        default: {
            return (
                <div
                    className={styles.twMerge(
                        styles?.ui?.AmountDue?.container?.mobile,
                        styles?.ui?.AmountDue?.container?.tablet,
                        styles?.ui?.AmountDue?.container?.desktop,
                        className
                    )}
                >
                    <div
                        className={styles.twMerge(
                            styles?.ui?.AmountDue?.div?.mobile,
                            styles?.ui?.AmountDue?.div?.tablet,
                            styles?.ui?.AmountDue?.div?.desktop,
                            className
                        )}
                    >
                        <HeadingText4
                            className={styles.twMerge(
                                styles?.ui?.AmountDue?.title?.mobile,
                                styles?.ui?.AmountDue?.title?.tablet,
                                styles?.ui?.AmountDue?.title?.desktop,
                                className
                            )}
                        >
                            {dueCopy}
                        </HeadingText4>
                        &nbsp;
                        <HeadingText4
                            className={styles.twMerge(
                                styles?.ui?.AmountDue?.amount?.mobile,
                                styles?.ui?.AmountDue?.amount?.tablet,
                                styles?.ui?.AmountDue?.amount?.desktop,
                                className
                            )}
                        >
                            {children}
                        </HeadingText4>
                    </div>
                </div>
            )
        }
    }
}

export default AmountDue
